import Modal from "./Modal";

type VideoModalType = {
  show: boolean;
  handleClose: any;
  videoSource: string;
  autoplay: boolean | undefined;
};
export default function VideoModal({
  show,
  handleClose,
  videoSource,
  autoplay,
}: VideoModalType) {
  return (
    <Modal show={show} handleClose={handleClose}>
      <div className="bg-white ">
        <iframe
          width={Math.min(window.innerWidth - 60, 854)}
          height={Math.min((window.innerWidth - 60) * 0.54, 480)}
          src={`${videoSource}?autoplay=${autoplay ? 1 : 0}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  );
}
