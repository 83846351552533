import { useState, useRef } from "react";

import TextField from "../fields/TextField";
import SelectField from "../fields/SelectField";

export default function RetirementGoalsForm({ value, setFormValues, errors }) {
  const [indexActiveFields, setIndexActiveFields] = useState(null);
  const fieldsRef = useRef([]);

  // initialize retirementGoals object if it doesn't exist
  if (!value.retirementGoals || Object.keys(value.retirementGoals).length < 1) {
    value.retirementGoals = [];

    value.retirementGoals[0] = {
      retirementGoalsType: "",
      retirementGoalsOther: "",
      retirementGoalsTimeframe: "",
      retirementGoalsCost: "",
    };
  }

  if (!indexActiveFields) {
    let initialActiveFields = {};

    for (let i = 0; i < value.retirementGoals.length; i++) {
      initialActiveFields[i] =
        value.retirementGoals[i]["retirementGoalsType"] == "Other";
    }

    setIndexActiveFields(initialActiveFields);

    // return empty div until indexActiveFields is updated (is there a better way?)
    return <div></div>;
  }

  function setValue(name, newValue, index) {
    value.retirementGoals[index][name] = newValue;
    setFormValues({ ...value });

    const goals = value.retirementGoals;
    goals[index] = {
      ...value.retirementGoals[index],
      [name]: newValue,
    };
    setFormValues({ ...value, retirementGoals: [...goals] });
  }

  function handleChange(event) {
    const target = event.target;
    const index = target.getAttribute("index");

    if (target.name == "retirementGoalsType") {
      if (target.value == "Other") {
        fieldsRef.current[index].focus();
        indexActiveFields[index] = true;
      } else {
        indexActiveFields[index] = false;
      }

      setIndexActiveFields({ ...indexActiveFields });
    }

    const goals = value.retirementGoals;
    goals[index] = {
      ...value.retirementGoals[index],
      [target.name]: target.value,
    };
    setFormValues({ ...value, retirementGoals: [...goals] });
  }

  function isLastRow(i) {
    return i == Object.keys(value.retirementGoals).length - 1;
  }

  function isFirstRow(i) {
    return i == 0;
  }

  function addRow() {
    value.retirementGoals[Object.keys(value.retirementGoals).length] = {
      pensionPlanName: "",
      pensionPlanOwner: "",
      pensionPlanDistrubutionType: "",
      pensionPlanDistributionAmount: "",
      pensionPlanDistributionYear: "",
    };

    value.retirementGoals = value.retirementGoals.filter((val) => val);

    setFormValues({ ...value });
  }

  function removeRow(i) {
    delete value.retirementGoals[i];

    // reindex array
    value.retirementGoals = value.retirementGoals.filter((val) => val);

    setFormValues({ ...value });
  }

  function clearRow() {
    // create shallow copy so react knows state has changed needs rerender
    let retirementGoals = [...value.retirementGoals];

    retirementGoals[0] = {
      pensionPlanName: "",
      pensionPlanOwner: "",
      pensionPlanDistrubutionType: "",
      pensionPlanDistributionAmount: "",
      pensionPlanDistributionYear: "",
    };
    value.retirementGoals = retirementGoals;

    setFormValues({ ...value });
  }

  return (
    <div>
      {value.retirementGoals.map((goal, i) => {
        return (
          <div key={i} className="mr-6 mt-2 grid grid-cols-9 gap-2 lg:gap-5">
            <div className="col-span-9 lg:col-span-3">
              <SelectField
                name="Goal"
                slug="retirementGoalsType"
                index={i}
                value={goal}
                handleChange={handleChange}
                errors={errors}
                options={[
                  {
                    name: "Going back to school",
                    label: "Going back to school",
                  },
                  { name: "Build a Cabin", label: "Build a Cabin" },
                  { name: "Buy a RV", label: "Buy a RV" },
                  { name: "Buy an Ice House", label: "Buy an Ice House" },
                  {
                    name: "Fund child's education",
                    label: "Fund child's education",
                  },
                  { name: "Other", label: "Other", step: "COND" },
                ]}
              />
            </div>

            <div className="col-span-9 lg:col-span-2">
              <TextField
                name="Other"
                slug="retirementGoalsOther"
                index={i}
                value={goal}
                setValue={setValue}
                errors={errors}
                readOnly={!indexActiveFields[i]}
                ref={(el) => (fieldsRef.current[i] = el)}
              />
            </div>

            <div className="col-span-9 lg:col-span-2">
              <TextField
                name="Timeframe (years from now)"
                slug="retirementGoalsTimeframe"
                index={i}
                value={goal}
                setValue={setValue}
                errors={errors}
                validation="numeric"
              />
            </div>

            <div className="relative col-span-9 h-12 lg:col-span-2">
              <TextField
                name="Anticipated Cost"
                slug="retirementGoalsCost"
                index={i}
                value={goal}
                handleChange={handleChange}
                errors={errors}
                format="dollars"
              />

              <button
                type="button"
                onClick={() => (isFirstRow(i) ? clearRow() : removeRow(i))}
                className={`absolute inset-y-6 right-0 -mr-9 mt-1 h-8 w-7 justify-center rounded-md bg-primary-light pl-1 text-white`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2.5}
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>
            </div>

            {isLastRow(i) && (
              <div className="col-span-12 lg:col-span-3">
                <button
                  type="button"
                  onClick={addRow}
                  className="inline-flex justify-center rounded-md border border-transparent bg-green px-4 py-2 text-sm font-medium text-white shadow-sm"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="ml-2">Add another plan</span>
                </button>
              </div>
            )}

            {!isLastRow(i) && (
              <div className="col-span-12 mb-6 mt-12 block border-t border-gray-200 lg:hidden"></div>
            )}
          </div>
        );
      })}
    </div>
  );
}
