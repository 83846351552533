import AdminContext from "../context/admin";
import { useEffect, useState } from "react";

const AdminProvider = ({ children }: any): JSX.Element => {
    const [emulatingUser, setEmulatingUser] = useState({});

    useEffect(() => {
        const localEmulatingUser = localStorage.getItem('emulatingUser') || "";

        if (localEmulatingUser) {
            const emulationTimeout = 60;
            setEmulatingUser(JSON.parse(localEmulatingUser));

            // TODO: clear emulating user if emulation passed emulationTimeout
            // if (moment().diff(emulatingUser.emulatedAt, 'minutes') > emulationTimeout) {
            //     setEmulatingUser(null);
            // } else {
            //     setEmulatingUser(emulatingUser);
            // }
        }
    }, []);

    useEffect(() => {
        // TODO: check if emulatingUser changed before writing to localStorage
        if (JSON.stringify(emulatingUser) !== '{}') {
            localStorage.setItem('emulatingUser', JSON.stringify(emulatingUser));
        }
    }, [emulatingUser]);
  
    return (
        <AdminContext.Provider value={{emulatingUser: emulatingUser, setEmulatingUser: setEmulatingUser}}>
            {children}
        </AdminContext.Provider>
    );
  };
  
  export default AdminProvider;