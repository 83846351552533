import { useState } from "react";

type SelectFieldProps = {
  width?: string;
  errors: any;
  slug: string;
  name: string;
  index?: number;
  onEnter?: any;
  step?: any;
  value: any;
  handleChange: any;
  autoFocus?: any;
  options: { name: string; label: string }[];
};

function SelectField({
  width = "full",
  errors,
  slug,
  name,
  index,
  onEnter,
  step,
  value,
  handleChange,
  autoFocus,
  options,
}: SelectFieldProps) {
  function onKeyDown(event: any) {
    if (onEnter && event.key === "Enter") {
      onEnter(slug);
    }
  }

  return (
    <>
      <label htmlFor={slug} className="block text-sm font-medium text-gray-700">
        {name}
      </label>
      <select
        id={slug}
        name={slug}
        // @ts-ignore
        step={step}
        index={index}
        value={value[slug]}
        onKeyDown={onKeyDown}
        onChange={handleChange}
        autoFocus={autoFocus}
        className={`w-${width} mt-1 block border px-3 py-2 ${
          errors && errors[slug] ? "border-red-500" : ""
        } rounded-md bg-white shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm`}
      >
        <option />
        {(options ?? []).map((option) => (
          <option key={option.name} value={option.name}>
            {option.label}
          </option>
        ))}
      </select>
      {errors && errors[slug] && (
        <p className="text-xs text-red-500">{errors[slug]?.message}</p>
      )}
    </>
  );
}

export default SelectField;
