import React from "react";

export type AdminContextType = {
    emulatingUser?: {[key: string]: any};
    setEmulatingUser?: Function;
};
  
const AdminContext: React.Context<AdminContextType> = React.createContext({});

export default AdminContext;
