import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from "@auth0/auth0-react";

import "./app.css";
import "./style.css";

import { BrowserRouter, useNavigate } from "react-router-dom";
import TenantProvider from "./provider/tenant";
import AdminProvider from "./provider/admin";
import * as Sentry from "@sentry/react";
import GenericErrorPage from "./components/pages/ErrorPage";

function determineEnvironment(href: string): string {
  if (href.includes("localhost")) {
    return "local";
  } else if (href.includes("development")) {
    return "development";
  }
  return "production";
}

Sentry.init({
  dsn: "https://d83e167d3f4197f52bd9d17399420dd5@o4506480845783040.ingest.sentry.io/4506480866885632",
  environment: determineEnvironment(window.location.href),
  enabled: determineEnvironment(window.location.href) !== "local",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost:5173",
        "https://api.development.qoladvisor.com",
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      useCompression: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider {...props} onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

const genericErrorPage = <GenericErrorPage />;

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={genericErrorPage}>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain={import.meta.env.VITE_AUTH0_DOMAIN}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
          audience={import.meta.env.VITE_AUTH0_AUDIENCE}
          scope="openid profile email read:profile-data write:profile-data administer:profile-data"
        >
          <TenantProvider>
            <AdminProvider>
              <App />
            </AdminProvider>
          </TenantProvider>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
