import Modal from "./widgets/Modal";
import PdfModal from "./widgets/PdfModal";
import Learn from "./pages/Learn";

import DebtsCreditCardForm from "./forms/DebtsCreditCardForm";
import DebtsAutoLoanForm from "./forms/DebtsAutoLoanForm";
import DebtsStudentLoanForm from "./forms/DebtsStudentLoanForm";
import DebtsPrivateStudentLoanForm from "./forms/DebtsPrivateStudentLoanForm";
import DebtsFederalStudentLoanForm from "./forms/DebtsFederalStudentLoanForm";
import DebtsOtherLoanForm from "./forms/DebtsOtherLoanForm";
import InvestmentAccountForm from "./forms/InvestmentAccountForm";
import SpouseInvestmentAccountForm from "./forms/SpouseInvestmentAccountForm";
import PensionPlanForm from "./forms/PensionPlanForm";
import MajorExpensesForm from "./forms/MajorExpensesForm";
import RetirementGoalsForm from "./forms/RetirementGoalsForm";
import AdditionalHousingForm from "./forms/AdditionalHousingForm";
import { useState } from "react";
import useFormData from "../lib/use-form-data";
import formRequirements from "./validations/formrequirements";
import formValidations from "./validations/formvalidations";
import { formSpecificValidate, validate } from "./modules/validate";

function ChatModals({
  showChatModals,
  hideModals,
  currentVideo,
  currentPdf,
  errors,
  setErrors,
}: any) {
  const [validForms, setValidForms] = useState<any[]>([]);

  const {
    formData: formValues,
    mutateFormData: setFormValues,
    trigger,
  } = useFormData();

  async function update(values: any) {
    await setFormValues(values, {
      optimisticData: values,
      revalidate: false,
    });
  }

  function handleFormSubmit(form: string) {
    let fieldRequirements = formRequirements[form] || {};
    let fieldValidations = formValidations[form] || {};
    // @ts-ignore
    let currentFormValues = formValues[form];

    let firstFormRowValues = {};
    if (currentFormValues) {
      firstFormRowValues = currentFormValues[0];
    }

    let isValid = true;

    // only require first row of chat subforms (only 1 entry required)
    if (
      isValid &&
      !validate(fieldRequirements, firstFormRowValues, setErrors)
    ) {
      isValid = false;
    }

    // apply validations to all rows of chat subforms
    currentFormValues.forEach((formRowValues: any, index: number) => {
      if (
        isValid &&
        !validate(fieldValidations, formRowValues, setErrors, index)
      ) {
        isValid = false;
      }

      // apply form specific validations
      if (
        isValid &&
        !formSpecificValidate(form, formRowValues, setErrors, index)
      ) {
        isValid = false;
      }
    });

    if (!isValid) {
      return;
    }

    // TODO: show save confirmation?
    setValidForms([...validForms, form]);
    trigger(formValues);
    hideModals();
  }

  return (
    <div className="modals">
      {showChatModals["pdfModal"] && (
        <PdfModal
          pdf={currentPdf}
          showModal={showChatModals["pdfModal"]}
          handleClose={hideModals}
        />
      )}
      {showChatModals["helpModal"] && (
        <Modal show={showChatModals["helpModal"]} handleClose={hideModals}>
          <div
            className="max-h-screen overflow-x-auto overflow-y-auto bg-white pt-6 sm:overflow-x-hidden"
            style={{
              width: Math.min(window.innerWidth - 200, 1200),
              height: Math.min(screen.height - 400, 600),
            }}
          >
            <Learn isModal={true} />
          </div>
        </Modal>
      )}
      {showChatModals["mediaModal"] && (
        <Modal show={showChatModals["mediaModal"]} handleClose={hideModals}>
          <div className="bg-white">
            {currentVideo && (
              <iframe
                width={Math.min(window.innerWidth - 60, 854)}
                height={Math.min((window.innerWidth - 60) * 0.54, 480)}
                src={`${currentVideo}?autoplay=1`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </Modal>
      )}
      {showChatModals["debtsCreditCard"] && (
        <Modal
          show={showChatModals["debtsCreditCard"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("debtsCreditCard")}
        >
          <div className="max-h-screen max-w-screen-xl overflow-auto bg-white p-6 pb-12">
            <DebtsCreditCardForm
              value={formValues}
              setFormValues={(values: any) => {
                setFormValues(values, { revalidate: false });
              }}
              errors={errors}
            />
          </div>
        </Modal>
      )}
      {showChatModals["debtsAutoLoans"] && (
        <Modal
          show={showChatModals["debtsAutoLoans"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("debtsAutoLoans")}
        >
          <div className="max-h-screen max-w-screen-xl overflow-auto bg-white p-6 pb-12">
            <DebtsAutoLoanForm
              value={formValues}
              setFormValues={update}
              errors={errors}
            />
          </div>
        </Modal>
      )}
      {showChatModals["debtsStudentLoan"] && (
        <Modal
          show={showChatModals["debtsStudentLoan"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("debtsStudentLoan")}
        >
          <div className="max-h-screen max-w-screen-xl overflow-auto bg-white p-6 pb-12">
            <DebtsStudentLoanForm
              value={formValues}
              setFormValues={update}
              errors={errors}
            />
          </div>
        </Modal>
      )}
      {showChatModals["debtsPrivateStudentLoan"] && (
        <Modal
          show={showChatModals["debtsPrivateStudentLoan"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("debtsPrivateStudentLoan")}
        >
          <div className="max-h-screen max-w-screen-xl overflow-auto bg-white p-6 pb-12">
            <DebtsPrivateStudentLoanForm
              value={formValues}
              setFormValues={update}
              errors={errors}
            />
          </div>
        </Modal>
      )}
      {showChatModals["debtsFederalStudentLoan"] && (
        <Modal
          show={showChatModals["debtsFederalStudentLoan"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("debtsFederalStudentLoan")}
        >
          <div className="max-h-screen max-w-screen-xl overflow-auto bg-white p-6 pb-12">
            <DebtsFederalStudentLoanForm
              value={formValues}
              setFormValues={(values: any) => {
                setFormValues(values, { revalidate: false });
              }}
              errors={errors}
            />
          </div>
        </Modal>
      )}
      {showChatModals["debtsOtherLoan"] && (
        <Modal
          show={showChatModals["debtsOtherLoan"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("debtsOtherLoan")}
        >
          <div className="max-h-screen max-w-screen-xl overflow-auto bg-white p-6 pb-12">
            <DebtsOtherLoanForm
              value={formValues}
              setFormValues={update}
              errors={errors}
            />
          </div>
        </Modal>
      )}
      {showChatModals["investmentAccounts"] && (
        <Modal
          show={showChatModals["investmentAccounts"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("investmentAccounts")}
        >
          <div className="max-h-screen max-w-screen-xl overflow-auto bg-white p-6 pb-12">
            <InvestmentAccountForm
              value={formValues}
              setFormValues={(values: any) => {
                setFormValues(values, { revalidate: false });
              }}
              errors={errors}
            />
          </div>
        </Modal>
      )}
      {showChatModals["spouseInvestmentAccounts"] && (
        <Modal
          show={showChatModals["spouseInvestmentAccounts"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("spouseInvestmentAccounts")}
        >
          <div className="max-h-screen max-w-screen-xl overflow-auto bg-white p-6 pb-12">
            <SpouseInvestmentAccountForm
              value={formValues}
              setFormValues={update}
              errors={errors}
            />
          </div>
        </Modal>
      )}
      {showChatModals["pensionPlans"] && (
        <Modal
          show={showChatModals["pensionPlans"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("pensionPlans")}
        >
          <div
            className="max-h-screen overflow-auto bg-white p-6 pb-12"
            style={{
              minWidth: Math.min(window.innerWidth - 200, 1200),
              maxHeight: "90vh",
            }}
          >
            <PensionPlanForm
              value={formValues}
              setFormValues={update}
              errors={errors}
            />
          </div>
        </Modal>
      )}
      {showChatModals["majorExpenses"] && (
        <Modal
          show={showChatModals["majorExpenses"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("majorExpenses")}
        >
          <div className="max-h-screen max-w-screen-xl overflow-auto bg-white p-6 pb-12">
            <MajorExpensesForm
              value={formValues}
              setFormValues={update}
              errors={errors}
            />
          </div>
        </Modal>
      )}
      {showChatModals["retirementGoals"] && (
        <Modal
          show={showChatModals["retirementGoals"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("retirementGoals")}
        >
          <div className="max-h-screen max-w-screen-xl overflow-auto bg-white p-6 pb-12">
            <RetirementGoalsForm
              value={formValues}
              setFormValues={update}
              errors={errors}
            />
          </div>
        </Modal>
      )}
      {showChatModals["additionalProperties"] && (
        <Modal
          show={showChatModals["additionalProperties"]}
          handleClose={hideModals}
          showSubmit={true}
          handleSubmit={() => handleFormSubmit("additionalProperties")}
        >
          <div className="max-h-screen max-w-screen-xl overflow-auto bg-white p-6 pb-12">
            <AdditionalHousingForm
              value={formValues}
              setFormValues={update}
              errors={errors}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ChatModals;
