import React from "react";

const TextField = React.forwardRef(
  (props: any, ref: React.ForwardedRef<HTMLInputElement>) => {
    let type = props.type || "text";
    let width = props.width || "full";
    let margin = props.margin || "";
    let inputSymbolClass = "";

    if (props.format && props.value[props.slug]) {
      if (props.format == "dollars") {
        inputSymbolClass = "input-symbol-dollars";
      } else if (props.format == "percentage") {
        inputSymbolClass = "input-symbol-percentage";
      }
    }

    function onChange(event: any) {
      const target = event.target;
      let value = target.value;

      if (props.format) {
        // only allow numeric characters
        if (
          (props.format == "dollars" || props.format == "percentage") &&
          value !== "" &&
          !/^[0-9.,\b]+$/.test(value)
        ) {
          return;
        }

        // only allow plain text characters
        if (
          props.format == "text" &&
          value !== "" &&
          !/^[0-9a-zA-Z\s\.,]*$/.test(value)
        ) {
          return;
        }
      }

      if (props.format && props.format == "phone") {
        let cleaned = ("" + value).replace(/\D/g, "");
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        // require (xxx) xxx-xxxx phone number format
        if (match) {
          value = "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
      }

      if (props.setValue) {
        props.setValue(target.name, value, props.index);
      } else {
        props.handleChange(event);
      }
    }

    function onKeyDown(event: any) {
      if (props.onEnter && event.key === "Enter") {
        props.onEnter(props.slug);
      }
    }

    return (
      <div className={margin}>
        <label
          htmlFor={props.slug}
          className={
            "block text-sm font-medium " +
            (props.readOnly ? "text-gray-400" : "text-gray-700")
          }
        >
          {props.name}
        </label>
        <span className={inputSymbolClass}>
          <input
            type={type}
            id={props.slug}
            name={props.slug}
            value={props.value[props.slug] ?? ""}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            ref={ref}
            autoFocus={props.autoFocus}
            // @ts-ignore
            index={props.index}
            className={
              `w-${width} mt-1 block shadow-sm sm:text-sm ${
                props.errors && props.errors[props.slug] ? "border-red-500" : ""
              } rounded-md` +
              (props.readOnly
                ? " focus:border-gray-300 focus:ring-0 focus:ring-offset-0"
                : " focus:border-primary focus:ring-1 focus:ring-primary")
            }
          />
        </span>
        {props.errors && props.errors[props.slug] && (
          <p className="text-xs text-red-500">
            {props.errors[props.slug]?.message}
          </p>
        )}
        {props.caption && <p className="ml-1 mt-1 text-xs">{props.caption}</p>}
      </div>
    );
  },
);

export default TextField;
