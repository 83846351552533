export function getRoles(user: {[key: string]: any}) {
    if (user && user['https://qolplanning.com/roles']) {
        return user['https://qolplanning.com/roles'];
    }

    return [];
}

export function isAdmin(user: any) {
    return (getRoles(user)).includes('Admin');
}