import { useContext, useState } from "react";
import VennDiagram from "./VennDiagram";
import CircleDiagram from "./CircleDiagram";
import AccountsTable from "./AccountsTable";
import VideoModal from "./widgets/VideoModal";
import useFormData from "../lib/use-form-data";
import TenantContext from "../context/tenant";

function Overview() {
  const { formData: formValues } = useFormData();
  const { id } = useContext(TenantContext);
  const [currentMedia, setCurrentMedia] = useState<string | null>(null);
  const [showMediaModal, setShowMediaModal] = useState(false);

  function openMediaModal(video: string) {
    setCurrentMedia(video);
    setShowMediaModal(true);
  }

  function closeModals() {
    setShowMediaModal(false);
    setCurrentMedia(null);
  }

  return (
    <div>
      {formValues && (
        <div>
          <div className="mx-4 pt-1 text-gray-900 sm:mx-16">
            <h3 className="text-lg font-medium leading-6">
              Overview of Finances
            </h3>
            <p className="mt-1 pb-4  text-sm font-semibold text-gray-700">
              The circles below summarize your current financial picture.
            </p>
                <div className="float-right -mt-16">
                    <div onClick={() => openMediaModal('https://www.youtube.com/embed/SjjuW5MfUrE?autoplay=1')}
                      className="flex flex-row cursor-pointer items-center text-gray-500 hover:text-gray-800">
                      <span className="inline-flex items-center justify-center h-8 w-8 text-lg text-gray-400">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                        </svg>
                      </span>
                      <span className="text-gray-900 font-medium text-md leading-4">Dashboard Overview</span>
                    </div>
                </div>
            <div className="border-t border-gray-200"></div>
          </div>

          <div className="modals">
            {currentMedia && (
              <VideoModal
                show={showMediaModal}
                handleClose={closeModals}
                videoSource={currentMedia}
                autoplay={true}
              />
            )}
          </div>

          {formValues["enterSpouse"] == "Yes" ? (
            <VennDiagram />
          ) : (
            <CircleDiagram />
          )}

          <div className="children my-12 text-center">
            {formValues["children"] &&
              Object.values(formValues["children"]).map(
                (child: any, i: any) => {
                  return (
                    <div key={i} className="child mx-16 inline-block uppercase">
                      <h3 className="text-3xl font-bold text-primary">
                        {child.childName || "Child"}
                      </h3>
                      <span className='uppercase'>Dependent</span>
                    </div>
                  );
                },
              )}
          </div>

          <AccountsTable />
        </div>
      )}
    </div>
  );
}

export default Overview;
