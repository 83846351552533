import TextField from "../fields/TextField";
import DateField from "../fields/DateField";

// TODO component can't update if number of dependents changes.
function ChildrenForm(props: any) {
  // extract data needed for form logic from formValues
  let data: any = {};
  if (props.data) {
    props.data.forEach((row: any) => {
      data[row] = props.value[row];
    });
  }

  // initialize children object if it doesn't exist
  if (!props.value.children) {
    props.value.children = {};
  }

  //TODO: should we start the index at 1 to be able to test if props.index?
  for (
    let i = Object.keys(props.value.children).length;
    i < (data.childrenDependentNumber || 0);
    i++
  ) {
    props.value.children[i] = {};
  }

  function setValue(name: string, value: string | Date, index: number) {
    const children = props.value.children;
    children[index] = {
      ...props.value.children[index],
      [name]: value,
    };
    props.setFormValues(
      { ...props.value, children: { ...children } },
      { revalidate: false },
    );
  }

  function handleChange(event: any) {
    const target = event.target;
    const index = target.getAttribute("index");

    const children = props.value.children;
    children[index] = {
      ...props.value.children[index],
      [target.name]: target.value,
    };
    props.setFormValues(
      {
        ...props.value,
        children: { ...children },
      },
      { revalidate: false },
    );
  }

  return (
    <div className="mb-4">
      <div className="float-right">
        {Object.values(props.value.children).map((dependent: any, i) => {
          return (
            <div key={i} className="mt-2 grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <TextField
                  name="Name"
                  slug="childName"
                  index={i}
                  value={dependent}
                  handleChange={handleChange}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <DateField
                  name="Birthdate"
                  slug="childBirthdate"
                  index={i}
                  value={dependent["childBirthdate"]}
                  setValue={setValue}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ChildrenForm;
