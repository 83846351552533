import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() => loginWithRedirect()}
      className={`rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`}
    >
      Log In
    </button>
  );
};

export default LoginButton;
