import { BounceLoader } from "react-spinners";

import TextField from "../fields/TextField";
import SelectField from "../fields/SelectField";

export default function FinanceForm(props) {
  // TODO: change values to formValues for consistency
  let fieldValidations = {
    greensheetAmount: "optional|dollars",
    greensheetService: "optional|between:0-60|numeric",
    greensheetPensionCredit: "optional|dollars",
    socialSecurityAmountPartial: "optional|dollars",
    socialSecurityAmountRetirementAge: "optional|dollars",
    socialSecurityAmountFull: "optional|dollars",
    retirementContribution: "optional|between:0-100|numeric",
    retirementContributionStockAllocation: "optional|between:0-100|numeric",
    retirementContributionBondAllocation: "optional|between:0-100|numeric",
    retirementBalance: "optional|dollars",
    checkingAccountBalance: "optional|dollars",
    savingAccountBalance: "optional|dollars",
    checkingAndSavingCleared: "optional|dollars",
  };

  function handleSubmit(event) {
    event.preventDefault();
    props.handleSubmit(fieldValidations);
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="form-div overflow-hidden shadow sm:rounded-md"
    >
      <div className="grid grid-cols-8 gap-6 bg-white p-6 pb-12">
        <div className="col-span-8">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Home</h3>
          <div className="mt-2 border-t border-gray-200"></div>
        </div>

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="Mortgage Balance"
            slug="mortgageAmount"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            format="dollars"
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="Mortgage Payment"
            slug="mortgagePayment"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            format="dollars"
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="Mortgage Escrow"
            slug="mortgageEscrow"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            format="dollars"
          />
        </div>

        <div className="col-span-8 sm:col-span-1">
          <TextField
            name="Interest"
            slug="mortgageInterestRate"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            format="percentage"
          />
        </div>

        <div className="col-span-8 sm:col-span-1">
          <SelectField
            name="Fixed / Variable"
            slug="mortgageFixedOrVariable"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            options={[
              { name: "Fixed", label: "Fixed" },
              { name: "Variable", label: "Variable" },
            ]}
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="Mortgage Term"
            slug="mortgageTermLength"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="Extra Payments"
            slug="mortgageExtraPaymentAmount"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            format="dollars"
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="Home Value"
            slug="homeEstimatedValue"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            format="dollars"
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="HOA Fees"
            slug="mortgageHoaFee"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            format="dollars"
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <SelectField
            name="Plan to Stay"
            slug="homePlanningToStay"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            options={[
              { name: "Not sure", label: "Not sure" },
              { name: "Long term", label: "Long term" },
              {
                name: "For the foreseeable future",
                label: "For the foreseeable future",
              },
              { name: "Shorter term", label: "Shorter term" },
            ]}
          />
        </div>

        <div className="col-span-0 sm:col-span-6"></div>
      </div>

      <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
        <button
          type="submit"
          disabled={props.loading}
          className={
            (props.loading ? "bg-gray-400" : "bg-primary") +
            " inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm"
          }
        >
          <BounceLoader loading={props.loading} size={20} color="#ffffff" />
          <span className={props.loading ? "ml-2" : ""}>Save</span>
        </button>
      </div>
    </form>
  );
}
