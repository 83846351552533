import { useContext, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import StyledBounceLoader from "./StyledBounceLoader";

import ContributionRecommendations from "./ContributionRecommendations";
import useFormData from "../lib/use-form-data";
import useQueryData from "../lib/use-query-data";
import {
  FundMessagesType,
  FundRecommendationsType,
  FundType,
} from "../lib/types";
import TenantContext from "../context/tenant";

export default function FundRecommendations() {
  const { scheduleMeetingLink } = useContext(TenantContext);
  const [selectedAllocation, setSelectedAllocation] = useState<any | undefined>(
    undefined,
  );
  const [selectedComponent, setSelectedComponent] = useState<any | undefined>(
    undefined,
  );

  const { formData: formValues } = useFormData();
  const {
    queryData: { results: fundRecommendations, messages: fundMessages },
  } = useQueryData<FundRecommendationsType, FundMessagesType>(
    "dc_fund_recommendations",
  );

  function getStockAllocation() {
    let stockAllocation = 0;

    fundRecommendations?.allocation.forEach((allocation: FundType) => {
      if (allocation.title === "Stocks") {
        stockAllocation = allocation.value || 0;
      }
    });

    return stockAllocation;
  }

  function getBondAllocation() {
    let bondAllocation = 0;

    fundRecommendations?.allocation.forEach((allocation: FundType) => {
      if (allocation.title == "Bonds") {
        bondAllocation = allocation.value || 0;
      }
    });

    return bondAllocation;
  }

  function filterAllocation(allocation: FundType[]): FundType[] {
    let filteredAllocation: FundType[] = [];

    allocation.forEach((fund) => {
      if (fund?.value || 0 > 0) {
        filteredAllocation.push(fund);
      }
    });

    return filteredAllocation;
  }

  if (!fundRecommendations) {
    return (
      <div className="mx-auto my-16 max-w-5xl">
        <div className="flex items-center justify-center">
          <StyledBounceLoader />
        </div>
      </div>
    );
  }

  if (!fundRecommendations.allocation) {
    return (
      <div className="mx-auto my-16 max-w-5xl">
        <div className="flex items-center justify-center">
          ERROR: no fund recommendations found
        </div>
      </div>
    );
  }

  // @ts-ignore
  return (
    <div className="mx-4 sm:mx-16">
      <div className="pt-1 text-gray-900">
        <h3 className="text-lg font-medium leading-6">Fund Recommendations</h3>
        <p className="mt-1 pb-4  text-sm font-semibold text-gray-700">
          Your interview responses are combined with valuation factors we
          consider in providing the following recommendations for your employer
        </p>
        <div className="border-t border-gray-200"></div>
      </div>

      <div className="hover:shodow-lg mt-8 flex items-center justify-between rounded-2xl bg-gray-100 px-8 py-4 shadow-md">
        <div className="flex items-center">
          <div className="w-16">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 rounded-2xl border border-blue-100 bg-blue-50 p-3 text-blue-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <div className="ml-4 flex flex-col">
            <div className="font-medium leading-none">Custom Portfolio</div>
            <p className="mt-2 text-sm leading-none text-gray-600">
              You could consider using a simple target date portolio approach,
              but we believe a customized portfolio recommendation is more
              appropriate for your situation.
            </p>
          </div>
        </div>
      </div>

      <div className="ml-4 mt-12">
        <table className="w-1/2">
          <thead>
            <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
              <th className="px-4 py-3">Your Investment Phase</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            <tr className="text-gray-700">
              <td className="border px-4 py-3 text-sm">
                {fundMessages?.investingPhase}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="ml-4 mt-12 pt-1 text-gray-900">
        <h3 className="text-lg font-medium leading-6">
          Our AI powered portfolio recommendation engine begins with determining
          an appropriate mix of stocks and bonds for you.
        </h3>
        <p className="mt-1 pb-4  text-sm font-semibold text-gray-700">
          Our recommendation process suggests a {getStockAllocation()}% stocks
          (equities) and {getBondAllocation()}% bonds (fixed income) portfolio
          for your retirement plan.
        </p>
      </div>

      <div className="m-8 ml-4 mt-0 flex flex-wrap">
        <div className="w-full sm:w-1/3">
          <PieChart
            style={{
              fontFamily:
                '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
              fontSize: "6px",
            }}
            //@ts-ignore
            data={filterAllocation(fundRecommendations.allocation)}
            radius={44}
            segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
            segmentsShift={(index) => (index === selectedAllocation ? 6 : 1)}
            label={({ dataEntry }) => Math.round(dataEntry.percentage) + "%"}
            // labelPosition={100 - lineWidth / 2}
            labelStyle={{
              fill: "#fff",
              opacity: 0.75,
              pointerEvents: "none",
            }}
            onClick={(_, index) => {
              setSelectedAllocation(
                index === selectedAllocation ? undefined : index,
              );
            }}
          />
        </div>
        <div className="ml-8 mt-4 sm:ml-24">
          <div className="mt-0 pt-1 text-gray-900 sm:mt-6">
            {fundRecommendations.allocation.map(
              (allocation: FundType, index: number) => {
                return (
                  <div
                    key={`${index}_allocation`}
                    onClick={(event) =>
                      setSelectedAllocation(
                        index === selectedAllocation ? undefined : index,
                      )
                    }
                    className={
                      (selectedAllocation == index
                        ? "ml-2 font-bold"
                        : "font-medium") +
                      " mt-2 flex cursor-pointer items-center"
                    }
                  >
                    <div
                      className="h-8 w-8"
                      style={{ backgroundColor: allocation.color }}
                    ></div>
                    <div className="ml-2 text-sm leading-6">
                      {allocation.title} {allocation.value}.0%
                    </div>
                  </div>
                );
              },
            )}
          </div>
        </div>
      </div>

      <div className="ml-4 mt-16 pt-1 text-gray-900">
        <p className="mt-1 pb-4  text-sm font-semibold text-gray-700">
          The next step is breaking down the components into appropriate asset
          classes so you are well diversified!
        </p>
      </div>

      <div className="m-8 ml-4 mt-0 flex flex-wrap">
        <div className="w-full sm:w-1/3">
          <PieChart
            style={{
              fontFamily:
                '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
              fontSize: "5px",
            }}
            //@ts-ignore
            data={filterAllocation(fundRecommendations.componentAllocation)}
            radius={44}
            lineWidth={60}
            segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
            segmentsShift={(index) => (index === selectedComponent ? 6 : 1)}
            label={({ dataEntry }) => Math.round(dataEntry.percentage) + "%"}
            labelPosition={70}
            labelStyle={{
              fill: "#fff",
              opacity: 0.75,
              pointerEvents: "none",
            }}
            onClick={(_, index) => {
              setSelectedComponent(
                index === selectedComponent ? undefined : index,
              );
            }}
          />
        </div>
        <div className="ml-8 mt-4 sm:ml-24">
          <div className="mt-0 pt-1 text-gray-900 sm:mt-6">
            {fundRecommendations.componentAllocation.map(
              (allocation: FundType, index: number) => {
                return (
                  <div
                    key={`${index}_componentAllocation`}
                    onClick={(event) =>
                      setSelectedComponent(
                        index === selectedComponent ? undefined : index,
                      )
                    }
                    className={
                      (selectedComponent == index
                        ? "ml-2 font-bold"
                        : "font-medium") +
                      " mt-2 flex cursor-pointer items-center"
                    }
                  >
                    <div
                      className="h-8 w-8"
                      style={{ backgroundColor: allocation.color }}
                    ></div>
                    <div className="ml-2 text-sm leading-6">
                      {allocation.title} {allocation.value}.0%
                    </div>
                  </div>
                );
              },
            )}
          </div>
        </div>
      </div>

      <div className="ml-4 mt-20 pt-1 text-gray-900">
        <p className="mt-1 pb-4  text-sm font-semibold text-gray-700">
          Which leads us to your specific asset allocation recommendation for
          your {formValues && formValues["currentEmployment"]} retirement plan:
        </p>
      </div>

      <div className="ml-4 mt-4">
        <table className="w-full sm:w-1/2">
          <thead>
            <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
              <th className="px-4 py-3">Stock Funds (Equities)</th>
              <th className="px-4 py-3">% Allocation</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {fundRecommendations.stockFunds.map(
              (fund: FundType, index: number) => {
                return (
                  <tr key={`${index}_stockFund`} className="text-gray-700">
                    <td className="text-ms border px-4 py-3 font-semibold">
                      {fund.title}
                    </td>
                    <td className="border px-4 py-3 text-sm">
                      {fund.value}.0%
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>

      <div className="ml-4 mt-8">
        <table className="w-full sm:w-1/2">
          <thead>
            <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
              <th className="px-4 py-3">Bond Funds (Fixed Income)</th>
              <th className="px-4 py-3">% Allocation</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {fundRecommendations.bondFunds.map((fund: any, index: number) => {
              return (
                <tr key={`${index}_bondFund`} className="text-gray-700">
                  <td className="text-ms border px-4 py-3 font-semibold">
                    {fund.title}
                  </td>
                  <td className="border px-4 py-3 text-sm">{fund.value}.0%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="hover:shodow-lg mt-16 flex items-center justify-between rounded-2xl bg-gray-100 px-8 py-4 shadow-md">
        <div className="flex items-center">
          <div className="w-16">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 rounded-2xl border border-blue-100 bg-blue-50 p-3 text-blue-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="ml-4 flex flex-col">
            <div className="font-medium leading-none">
              Modify Your Portfolio
            </div>
            <p className="mt-2 text-sm leading-none text-gray-600">
              You can either go online or contact {fundMessages?.fundCustodian}{" "}
              to make these recommended asset allocation changes to your
              portfolio.
            </p>
          </div>
        </div>
      </div>

      <div className="hover:shodow-lg mb-16 mt-8 flex items-center justify-between rounded-2xl bg-gray-100 px-8 py-4 shadow-md">
        <div className="flex items-center">
          <div className="w-16">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 rounded-2xl border border-green-100 bg-green-50 p-3 text-green-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="ml-4 flex flex-col">
            <div className="font-medium leading-none">
              Your plan portfolio is now allocated in a diversified group of
              funds that are appropriate based on your time horizon to
              retirement!
            </div>
            <p className="mt-2 text-sm leading-none text-gray-600">
              We suggest you check back at least annually as your portfolio
              recommendations will change over time based mainly on your
              retirement goals...
            </p>
          </div>
        </div>
      </div>

      <ContributionRecommendations />

      <div className="hover:shodow-lg mb-12 mt-16 flex items-center justify-between rounded-2xl bg-gray-100 px-8 py-4 shadow-md">
        <div className="flex items-center">
          <div className="w-16">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 rounded-2xl border border-blue-100 bg-blue-50 p-3 text-blue-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="ml-4 flex flex-col">
            <div className="font-medium leading-none">Schedule a Meeting</div>
            <p className="mt-2 text-sm leading-none text-gray-600">
              You can{" "}
              <a
                href={scheduleMeetingLink}
                target="_blank"
                className="underline hover:no-underline"
              >
                click here
              </a>{" "}
              to schedule a meeting to speak with one of our Certified Financial
              Planners™.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
