import React from "react";

function TextArea(props) {
  let rows = props.rows || 5;
  let borderColor = "border-gray-300";
  let errorMessage = "";

  if (props.errors) {
    let error = props.errors[props.slug];

    // only apply errors to field from current index
    if (error && (!props.index || error.index === props.index)) {
      errorMessage = error.message;
      borderColor = "border-red-500";
    }
  }
  return (
    <div className="ml-4">
      <label
        htmlFor={props.slug}
        className="block text-sm font-medium text-gray-700"
      >
        {props.name}
      </label>
      <textarea
        id={props.slug}
        name={props.slug}
        value={props.value[props.slug] ?? ""}
        onChange={props.handleChange}
        rows={rows}
        maxLength={500}
        className={`mt-1 block shadow-sm focus:border-green focus:ring-1 focus:ring-green sm:text-sm ${
          props.errors && props.errors[props.slug] ? "border-red-500" : ""
        } rounded-md`}
      />
      {props.errors && props.errors[props.slug] && (
        <p className="text-xs text-red-500">
          {props.errors[props.slug]?.message}
        </p>
      )}
    </div>
  );
}

export default TextArea;
