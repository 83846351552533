import { memo, useContext } from "react";
import TenantContext, {TenantContextType, MediaListItem} from "../context/tenant";

function ChatMediaList({ sectionName, showModal }: any) {
  const { learning, infoItems } = useContext<TenantContextType>(TenantContext);
  return (
    <div className="mb-8 mt-8 sm:mt-0">
      <h3 className="mt-4 text-lg font-medium leading-6 text-gray-900">
        Learning Content
      </h3>
      <ul className="pt-2">
        {sectionName &&
          learning &&
          learning[sectionName] &&
          learning[sectionName]
            .filter((m: MediaListItem) => m.showInChat)
            .map((media: MediaListItem, i: number) => {
              return (
                <li key={`${i}_medialist_item`} className="mb-3">
                  <div
                    onClick={(event) => showModal(event, media)}
                    // @ts-ignore
                    name={media.name}
                    modal="mediaModal"
                    className="flex cursor-pointer flex-row items-center text-gray-500 hover:text-gray-800"
                  >
                    <span className="inline-flex h-8 w-8 items-center justify-center text-lg text-gray-400">
                      {media.video && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                        </svg>
                      )}
                      {(media.pdf || media.link) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </span>
                    <span className="ml-2 text-sm font-medium leading-4 text-gray-900">
                      {media.title}
                    </span>
                  </div>
                </li>
              );
            })}
      </ul>

      {sectionName &&
        infoItems &&
        infoItems[sectionName] &&
        infoItems[sectionName].map((info, i) => {
          return (
            <div key={`${i}_infolist_item`}>
              <div>
                <h3 className="mt-8 text-lg font-medium leading-6 text-gray-900">
                  {info.title}
                </h3>
                {info.description && (
                  <span className="ml-2 text-sm font-medium leading-6 text-gray-900">
                    {info.description}
                  </span>
                )}
              </div>

              {info && info.link && (
                <a
                  href={info.link.href}
                  target="_blank"
                  className="ml-2 text-sm font-medium leading-6 text-gray-900 hover:underline"
                >
                  {info.link.text}
                </a>
              )}

              {info && info.list && (
                <ul className="ml-4 list-disc pb-4">
                  {info.list.map((item, j) => {
                    return (
                      <li
                        key={j}
                        className="ml-2 text-sm font-medium leading-5 text-gray-900"
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}

      <div>
        <h3 className="mt-8 text-lg font-medium leading-6 text-gray-900">
          Need Help?
        </h3>
        <a
          href="mailto:QoLPlanning@dorvalchorne.com"
          className="ml-2 text-sm font-medium leading-6 text-gray-900 hover:underline"
        >
          Ask us here
        </a>
      </div>
    </div>
  );
}

export default memo(ChatMediaList);
