import { useState, useRef, useEffect, useContext } from "react";
import { BounceLoader } from "react-spinners";
import ReactTooltip from "react-tooltip";
import MultiSelectField from "../fields/MultiSelectField";

import Modal from "../widgets/Modal";
import TextField from "../fields/TextField";
import DateField from "../fields/DateField";
import SelectField from "../fields/SelectField";
import TenantContext from "../../context/tenant";

function ProfileForm(props: any) {
  const { id, name, type } = useContext(TenantContext);
  // TODO: change values to formValues for consistency
  const [showLifeChangesModal, setShowLifeChangesModal] =
    useState<boolean>(false);
  const [selectedLifeChanges, setSelectedLifeChanges] = useState<any | null>(
    null,
  );
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [valueToSet, setValueToSet] = useState<any | null>(null);
  const [sectionsToRestart, setSectionsToRestart] = useState<string[] | null>(
    null,
  );

  const lifeChangesRef = useRef(null);

  useEffect(() => {
    setSelectedLifeChanges(props.values.lifeChanges);
  }, [props.values]);

  let fieldValidations = {
    nameFirst: "text",
    nameMiddleInitial: "optional|text",
    nameLast: "text",
    // gender: "",
    cellPhone: "optional|phone",
    childrenNumber: "optional|numeric|between:0-20",
    childrenDependentNumber: "optional|numeric|between:0-20",
    currentEmployment: "optional|text",
    currentLocation: "optional|text",
    contractHours: "optional|numeric|between:0-1",
    pickupHours: "optional|text",
    income: "optional|dollars",
    previousEmployment: "optional|text",
    previousLocation: "optional|text",
    spouseNameFirst: "optional|text",
    spouseNameMiddleInitial: "optional|text",
    spouseNameLast: "optional|text",
    // spouseGender: "",
    spouseEmployment: "optional|text",
    spouseLocation: "optional|text",
    spouseContractHours: "optional|numeric|between:0-1",
    spousePickupHours: "optional|text",
    spouseIncome: "optional|dollars",
  };

  let subformValidations = {
    children: {
      childName: "optional|text",
      childBirthdate: "optional",
    },
  };

  let lifeChangeOptions = [
    { name: "Retirement", label: "Retirement" },
    { name: "Job Change", label: "Job Change" },
    { name: "Baby", label: "Baby" },
    { name: "Married", label: "Married" },
    { name: "Divorced", label: "Divorced" },
    { name: "Widowed", label: "Widowed" },
    { name: "Housing Change", label: "Housing Change" },
    { name: "Significant Income Change", label: "Significant Income Change" },
    { name: "Inheritance", label: "Inheritance" },
    { name: "Kid in College", label: "Kid in College" },
    { name: "Death of a Loved One", label: "Death of a Loved One" },
    { name: "Disability", label: "Disability" },
  ];

  function setLifeChangesValues(name: string, value: any) {
    let currentLifeChanges = props.values.lifeChanges;
    let removed = currentLifeChanges.filter(
      (x: any) => value.indexOf(x) === -1,
    );
    let added = value.filter((x: any) => currentLifeChanges.indexOf(x) === -1);
    let changed = added.concat(removed);

    if (!currentLifeChanges) {
      props.setFormValues({ ...props.values, [name]: value });
    } else {
      if (
        (changed.filter((change: any) =>
          ["Divorced", "Death of a Loved One"].includes(change.name),
        ).length > 0 &&
          props.values.enterSpouse == "Yes") ||
        changed.filter((change: any) =>
          ["Retirement", "Widowed"].includes(change.name),
        ).length > 0
      ) {
        setValueToSet({ name: name, value: value });
        setConfirmMessage(
          "Changing your marriage or retirement status requires restarting the Introduction and Investment sections.   Please confirm.",
        );
        setShowLifeChangesModal(true);
      } else if (
        added.filter((change: { name: string }) =>
          ["Married"].includes(change.name),
        ).length > 0
      ) {
        setValueToSet({ name: name, value: value });
        setConfirmMessage(
          "Will your new spouse be included in your plan?  Adding them requires restarting the Introduction and Investment sections.   Please click confirm if you are adding them.",
        );
        // TODO: show confirm spouse modal, if the don't confirm, still add Married to list of life changes
        setShowLifeChangesModal(true);
      } else if (
        added.filter((change: { name: string }) =>
          ["Job Change"].includes(change.name),
        ).length > 0
      ) {
        setValueToSet({ name: name, value: value });
        setConfirmMessage(
          "Are you still employed with MNA?  Leaving requires restarting the Introduction and Investment sections.   Please confirm.",
        );
        // TODO: show confirm leaving mna, if the don't confirm, still add Job Change to list of life changes
        setShowLifeChangesModal(true);
      } else {
        props.setFormValues({ ...props.values, [name]: value });
      }
    }
  }

  function handleCloseLifeChangesModal() {
    setShowLifeChangesModal(false);

    // TODO: this isn't working
    // console.log([...props.values.lifeChanges]);
    // setSelectedLifeChanges({ ...props.values.lifeChanges })
    // setSelectedLifeChanges([...props.values.lifeChanges])

    setSelectedLifeChanges([...props.values.lifeChanges, {}]);
    props.setFormValues({
      ...props.values,
      lifeChanges: [...props.values.lifeChanges],
    });
  }

  function handleConfirmLifeChangesModal() {
    setShowLifeChangesModal(false);

    let newValues = { ...props.values, [valueToSet.name]: valueToSet.value };
    let restartSections = ["introduction", "mna-pension", "investments"];

    props.handleSubmit(
      fieldValidations,
      subformValidations,
      newValues,
      restartSections,
    );
  }

  function handlePlanToStayChange(event: any) {
    const name = event.target.name;
    const value = event.target.value;

    setValueToSet({ name: name, value: value });
    setConfirmMessage(
      "Changing your plan to stay employeed requires restarting the Introduction and Investment sections.   Please confirm.",
    );
    setSectionsToRestart(["introduction", "investments"]);
    setShowConfirmModal(true);
  }

  function handleCloseModal() {
    setShowConfirmModal(false);
  }

  function handleConfirmModal() {
    setShowConfirmModal(false);
    // props.setValue(valueToSet.name, valueToSet.value)

    let newValues = { ...props.values, [valueToSet.name]: valueToSet.value };
    props.setFormValues(newValues);
    props.handleSubmit(
      fieldValidations,
      subformValidations,
      newValues,
      sectionsToRestart,
    );
  }

  function handleSubmit(event: any) {
    event.preventDefault();
    props.handleSubmit(fieldValidations, subformValidations);
  }

  function handleChildrenChange(event: any) {
    const target = event.target;
    const index = target.getAttribute("index");

    const children = props.values.children;
    children[index] = {
      ...props.values.children[index],
      [target.name]: target.value,
    };
    props.setFormValues({
      ...props.values,
      children: { ...children },
    });
  }

  function setChildrenValue(name: string, value: any, index: number) {
    const children = props.values.children;
    children[index] = {
      ...props.values.children[index],
      [name]: value,
    };
    props.setFormValues({ ...props.values, children: { ...children } });
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="overflow-hidden shadow sm:rounded-md"
    >
      <Modal
        show={showLifeChangesModal}
        handleClose={handleCloseLifeChangesModal}
        showConfirm={true}
        handleConfirm={handleConfirmLifeChangesModal}
      >
        <div className="bg-white p-6 pb-12">
          <p className="px-12 pt-4 font-medium text-gray-700">
            {confirmMessage}
          </p>
        </div>
      </Modal>

      <Modal
        show={showConfirmModal}
        handleClose={handleCloseModal}
        showConfirm={true}
        handleConfirm={handleConfirmModal}
      >
        <div className="bg-white p-6 pb-12">
          <p className="px-12 pt-4 font-medium text-gray-700">
            {confirmMessage}
          </p>
        </div>
      </Modal>

      <div className="grid grid-cols-8 gap-6 bg-white p-6 pb-12">
        <div className="col-span-8">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Edit Profile
          </h3>
          <div className="mt-2 border-t border-gray-200"></div>
        </div>

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="First Name"
            slug="nameFirst"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
          />
        </div>

        <div className="col-span-8 sm:col-span-1">
          <TextField
            name="Middle Initial"
            slug="nameMiddleInitial"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="Last Name"
            slug="nameLast"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
          />
        </div>

        <div className="col-span-8 sm:col-span-1">
          <SelectField
            name="Gender"
            slug="gender"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            options={[
              { name: "Female", label: "Female" },
              { name: "Male", label: "Male" },
              { name: "Other", label: "Other" },
            ]}
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <DateField
            name="Birthdate"
            slug="birthdate"
            value={props.values["birthdate"]}
            setValue={props.setValue}
            errors={props.errors}
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="Cell Phone"
            slug="cellPhone"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <SelectField
            name="Relationship Status"
            slug="relationshipStatus"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            options={[
              { name: "Single", label: "Single" },
              { name: "Married", label: "Married" },
              { name: "Divorced", label: "Divorced" },
              { name: "Domestic Partner", label: "Domestic Partner" },
              { name: "Engaged", label: "Engaged" },
            ]}
          />
        </div>

        <div className="col-span-8 sm:col-span-1">
          <TextField
            name="Children"
            slug="childrenNumber"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
          />
        </div>

        <div className="col-span-8 sm:col-span-1">
          <TextField
            name="Dependent"
            slug="childrenDependentNumber"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
          />
        </div>

        <div className="col-span-0 sm:col-span-2"></div>

        <div className="col-span-8 sm:col-span-6">
          <MultiSelectField
            name="Life Changes"
            slug="lifeChanges"
            value={props.values}
            selectedValues={selectedLifeChanges}
            setValue={setLifeChangesValues}
            errors={props}
            options={lifeChangeOptions}
            ref={lifeChangesRef}
          />
        </div>

        <div className="col-span-8 pt-4">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Employment
          </h3>
          <div className="mt-2 border-t border-gray-200"></div>
        </div>

        <div
          className="col-span-8 sm:col-span-3"
          data-tip
          data-for="employerChangeTip"
        >
          <TextField
            name="Employer"
            slug="currentEmployment"
            value={
              type === "association"
                ? props.values
                : { currentEmployment: name }
            }
            handleChange={props.handleChange}
            errors={props.errors}
            readOnly
          />
        </div>
        {type === "association" && (
          <ReactTooltip id="employerChangeTip" place="top" effect="solid">
            To change hospital systems, you need to redo the Introduction,
            Pension, and Investments sections
          </ReactTooltip>
        )}

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="Location"
            slug="currentLocation"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
            readOnly
          />
        </div>

        {id === "1" && (
          <div className="col-span-8 sm:col-span-1">
            <TextField
              name="Contract Hours"
              slug="contractHours"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
            />
          </div>
        )}

        {id === "1" && (
          <div className="col-span-8 sm:col-span-1">
            <TextField
              name="Pickup Hours"
              slug="pickupHours"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
            />
          </div>
        )}

        <div className="col-span-8 sm:col-span-1">
          <TextField
            name="Income"
            slug="income"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
          />
        </div>

        <div className="col-span-8 sm:col-span-3">
          <TextField
            name="Previous Employer"
            slug="previousEmployment"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
          />
        </div>

        <div className="col-span-8 sm:col-span-2">
          <TextField
            name="Previous Location"
            slug="previousLocation"
            value={props.values}
            handleChange={props.handleChange}
            errors={props.errors}
          />
        </div>

        <div className="col-span-8 sm:col-span-1">
          <SelectField
            name="Plan To Stay"
            slug="employmentPlanToStay"
            value={props.values}
            handleChange={handlePlanToStayChange}
            errors={props.errors}
            options={[
              { name: "Yes", label: "Yes" },
              { name: "No", label: "No" },
            ]}
          />
        </div>

        <div className="col-span-0 sm:col-span-1"></div>

        {props.values.enterSpouse == "Yes" && (
          <div className="col-span-8 pt-4">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Spouse
            </h3>
            <div className="mt-2 border-t border-gray-200"></div>
          </div>
        )}
        {props.values.enterSpouse == "Yes" && (
          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Spouse First Name"
              slug="spouseNameFirst"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
            />
          </div>
        )}

        {props.values.enterSpouse == "Yes" && (
          <div className="col-span-8 sm:col-span-1">
            <TextField
              name="Middle Initial"
              slug="spouseNameMiddleInitial"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
            />
          </div>
        )}

        {props.values.enterSpouse == "Yes" && (
          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Spouse Last Name"
              slug="spouseNameLast"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
            />
          </div>
        )}

        {props.values.enterSpouse == "Yes" && (
          <div className="col-span-8 sm:col-span-1">
            <SelectField
              name="Spouse Gender"
              slug="spouseGender"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              options={[
                { name: "Female", label: "Female" },
                { name: "Male", label: "Male" },
                { name: "Other", label: "Other" },
              ]}
            />
          </div>
        )}

        {props.values.enterSpouse == "Yes" && (
          <div className="col-span-8 sm:col-span-2">
            <DateField
              name="Spouse Birthdate"
              slug="spouseBirthdate"
              value={props.values["spouseBirthdate"]}
              setValue={props.setValue}
              errors={props.errors}
            />
          </div>
        )}

        {props.values.enterSpouse == "Yes" &&
          props.values.spouseHasCurrentEmployment == "Yes" && (
            <div className="col-span-8 sm:col-span-3">
              <TextField
                name="Spouse Employer"
                slug="spouseEmployment"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                readOnly
              />
            </div>
          )}

        {props.values.enterSpouse == "Yes" &&
          props.values.spouseHasCurrentEmployment != "Yes" && (
            <div
              className="col-span-8 sm:col-span-3"
              data-tip
              data-for="spouseEmployerChangeTip"
            >
              <TextField
                name="Spouse Employer"
                slug="spouseNonMnaEmployer"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                readOnly
              />
              <ReactTooltip
                id="spouseEmployerChangeTip"
                place="top"
                effect="solid"
              >
                To change spouse employer, you need to redo the Introduction,
                Pension, and Investments sections
              </ReactTooltip>
            </div>
          )}

        {props.values.enterSpouse == "Yes" && (
          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Spouse Location"
              slug="spouseLocation"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              readOnly
            />
          </div>
        )}

        {props.values.enterSpouse == "Yes" && (
          <div className="col-span-8 sm:col-span-1">
            <TextField
              name="Contract Hours"
              slug="spouseContractHours"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
            />
          </div>
        )}

        {props.values.enterSpouse == "Yes" && (
          <div className="col-span-8 sm:col-span-1">
            <SelectField
              name="Plan To Stay"
              slug="spouseEmploymentPlanToStay"
              value={props.values}
              handleChange={handlePlanToStayChange}
              errors={props.errors}
              options={[
                { name: "Yes", label: "Yes" },
                { name: "No", label: "No" },
              ]}
            />
          </div>
        )}

        {props.values.enterSpouse == "Yes" && (
          <div className="col-span-8 sm:col-span-1">
            <TextField
              name="Spouse Income"
              slug="spouseIncome"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
            />
          </div>
        )}

        {props.values.children && (
          <div className="col-span-8 pt-4">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Children
            </h3>
            <div className="mt-2 border-t border-gray-200"></div>
          </div>
        )}

        {props.values.children &&
          Object.values(props.values.children).map((child: any, i) => {
            return (
              <div
                key={`child_${i}`}
                className="col-span-8 grid grid-cols-8 gap-6"
              >
                <div className="col-span-8 sm:col-span-2">
                  <TextField
                    name="Name"
                    slug="childName"
                    index={i}
                    value={child}
                    handleChange={handleChildrenChange}
                    errors={props.errors}
                  />
                </div>
                <div className="col-span-8 sm:col-span-2">
                  <DateField
                    name="Birthdate"
                    slug="childBirthdate"
                    index={i}
                    value={child["childBirthdate"]}
                    setValue={setChildrenValue}
                    errors={props.errors}
                  />
                </div>
                <div className="col-span-0 sm:col-span-4"></div>
              </div>
            );
          })}
      </div>

      <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
        <button
          type="submit"
          disabled={props.loading}
          className={`${
            props.loading ? "bg-gray-400" : "bg-primary"
          } inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm`}
        >
          <BounceLoader loading={props.loading} size={20} color="#ffffff" />
          <span className={props.loading ? "ml-2" : ""}>Save</span>
        </button>
      </div>
    </form>
  );
}

export default ProfileForm;
