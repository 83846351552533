import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function DashboardLayout(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="mx-auto flex max-w-7xl flex-wrap">
      <div className="w-full">
        <ul
          className="mx-1 mb-0 flex list-none flex-row flex-wrap pb-4 pt-3"
          role="tablist"
        >
          <li className="-mb-px mr-2 mt-2 flex-auto text-center last:mr-0 cursor-pointer">
            <a
              className={
                "block rounded px-5 py-3 text-xs font-bold uppercase leading-normal shadow-lg " +
                (location.pathname.endsWith("overview")
                  ? "bg-primary text-white"
                  : "bg-white text-primary")
              }
              onClick={(e) => {
                navigate("/dashboard/overview");
              }}
              data-toggle="tab"
              role="tablist"
            >
              Overview
            </a>
          </li>
          <li className="-mb-px mr-2 mt-2 flex-auto text-center last:mr-0 cursor-pointer">
            <a
              className={
                "block rounded px-5 py-3 text-xs font-bold uppercase leading-normal shadow-lg " +
                (location.pathname.endsWith("wealth-index")
                  ? "bg-primary text-white"
                  : "bg-white text-primary")
              }
              onClick={(e) => {
                navigate("/dashboard/wealth-index");
              }}
              data-toggle="tab"
              role="tablist"
            >
              Wealth Index
            </a>
          </li>
          <li className="-mb-px mr-2 mt-2 flex-auto text-center last:mr-0 cursor-pointer">
            <a
              className={
                "block rounded px-5 py-3 text-xs font-bold uppercase leading-normal shadow-lg " +
                (location.pathname.endsWith("cash-flow")
                  ? "bg-primary text-white"
                  : "bg-white text-primary")
              }
              onClick={(e) => {
                navigate("/dashboard/cash-flow");
              }}
              data-toggle="tab"
              role="tablist"
            >
              Cash Flow
            </a>
          </li>
          <li className="-mb-px mr-2 mt-2 flex-auto text-center last:mr-0 cursor-pointer">
            <a
              className={
                "block rounded px-5 py-3 text-xs font-bold uppercase leading-normal shadow-lg " +
                (location.pathname.endsWith("recommendations")
                  ? "bg-primary text-white"
                  : "bg-white text-primary")
              }
              onClick={(e) => {
                navigate("/dashboard/recommendations");
              }}
              data-toggle="tab"
              role="tablist"
            >
              Recommendations
            </a>
          </li>
        </ul>
        <div className="relative mb-6 flex w-full min-w-0 flex-col break-normal rounded bg-white shadow-lg">
          <div className="flex-auto px-4 py-5">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
