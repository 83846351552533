import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { format } from "date-fns"
import Modal from "../../widgets/Modal";
import StyledBounceLoader from "../../StyledBounceLoader";
import TextField from "../../fields/TextField";
import SelectField from "../../fields/SelectField";
import AdminContext from "../../../context/admin";
import TenantContext from "../../../context/tenant";
import { isAdmin } from "../../../lib/helpers";

const BASE_URL = import.meta.env.VITE_API_DOMAIN
const PAGE_SIZE = 50;

type User = {
    user_id: string;
    email: string;
  };

export default function Users() {
    const { id } = useContext(TenantContext);
    const { setEmulatingUser } = useContext(AdminContext);

    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState({ query: '' });
    const [errors, setErrors] = useState({});
    const [userList, setUserList] = useState<User[]>([]);
    const [userToDelete, setUserToDelete] = useState<User | null>(null);
    const [confirmText, setConfirmText] = useState({ text: '' });
    const [deleteLogin, setDeleteLogin] = useState({ login: 'No' });
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const numPages = Math.round(userList.length / PAGE_SIZE);

    function prevPage() {
        setPageNumber(Math.max(1, pageNumber - 1))
    }

    function nextPage() {
        setPageNumber(Math.min(numPages, pageNumber + 1))
    }

    const navigate = useNavigate();

    async function fetchUserList(query: string) {
        const accessToken = await getAccessTokenSilently();

        let url = BASE_URL + "/api/v2/users?q=" + query
        const response = await fetch(url, {
            "headers": {
                Authorization: `Bearer ${accessToken}`,
                "X-Tenant-Id": id,
            } as any
        });

        if (response.status != 200) {
            return
        }

        const userList = await response.json();

        setUserList(userList);
        setLoading(false);
    }

    async function postDeleteUser() {
        setLoading(true);
        setConfirmText({ text: '' });

        const accessToken = await getAccessTokenSilently();

        let url = BASE_URL + "/api/v2/users?id=" + userToDelete?.user_id;
        url += "&login=" + deleteLogin.login;

        const response = await fetch(url, {
            "method": "DELETE",
            "headers": {
                Authorization: `Bearer ${accessToken}`,
                "X-Tenant-Id": id,
            } as any
        });

        if (response.status != 200) {
            setShowErrorModal(true);
        } else {
            if (deleteLogin.login == "Yes") {
                removeUserFromList(userToDelete);
            }
            setShowSuccessModal(true);
        }
        
        setUserToDelete(null);
        setLoading(false);
    }

    function emulateUser(user: any) {
        user.emulatedAt = new Date()
        
        if (setEmulatingUser) {
            setEmulatingUser(user);
            navigate('/');
        }
    }

    function deleteUser(user: any) {
        setUserToDelete(user);
        setShowConfirmModal(true);
    }

    function removeUserFromList(user: any) {
        setUserList(userList.filter(obj => obj.email !== user.email));
    }

    function handleConfirm(event: any) {
        if (confirmText.text !== 'delete') {
            setErrors({ text: { message: "Please enter the word 'delete'" } })
        } else {
            // TODO: remove user from list
            setErrors({})
            postDeleteUser();
            setShowConfirmModal(false);
        }
    }

    function handleChange(event: any) {
        const target = event.target;
        const value = target.value;
        const id = target.getAttribute('id');

        if (id == 'text') {
            setConfirmText({ text: value });
        } else if (id == 'login') {
            setDeleteLogin({ login: value });
        } else {
            setSearch({ query: value });

            // TODO: debounce
            fetchUserList(value);
        }

    }

    useEffect(() => {
        if (!isLoading) {
            fetchUserList("");
        }
    }, [isLoading]);

    if (!isLoading && !isAdmin(user)) {
        location.href = '/';
    }

    if (loading) {
        return (
            <div className="mt-24 flex items-center justify-center">
                <StyledBounceLoader />
            </div>
        )
    }


    const userListToRender = userList.slice((pageNumber - 1) * PAGE_SIZE, (pageNumber * PAGE_SIZE));

    return (
        <div className="-my-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <Modal show={showConfirmModal} handleClose={() => { setConfirmText({ text: '' }); setShowConfirmModal(false) }} showConfirm={true} handleConfirm={handleConfirm}>
                <div className="p-6 pb-12 bg-white">
                    <p className="font-medium text-gray-700 px-12 pt-4">
                        User {userToDelete?.email} data will be deleted permenantly,
                        please confirm by entering <i>delete</i> in the text area below.
                    </p>
                    <div className="mt-2 ml-12">
                        <TextField name="Confirm Delete" slug="text" value={confirmText} handleChange={handleChange} errors={errors} width="80" />
                    </div>
                    <div className="mt-4 ml-12 w-1/4">
                        <SelectField
                            name="Delete Auth0 Login"
                            slug="login"
                            value={deleteLogin}
                            handleChange={handleChange}
                            errors={errors}
                            options={[
                                { name: "Yes", label: "Yes" },
                                { name: "No", label: "No" },
                            ]}
                        />
                    </div>
                </div>
            </Modal>
            <Modal show={showSuccessModal} handleClose={() => setShowSuccessModal(false)}>
                <div className="p-6 pb-12 bg-white">
                    <p className="font-medium text-gray-700 px-12 pt-4">Users deleted sucessfully.</p>
                </div>
            </Modal>
            <Modal show={showErrorModal} handleClose={() => setShowErrorModal(false)}>
                <div className="p-6 pb-12 bg-white">
                    <p className="font-medium text-gray-700 px-12 pt-4">Error deleting user</p>
                </div>
            </Modal>

            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="relative h-20">
                    <div className="pt-8 ml-2">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 inline w-56">User List</h3>
                    </div>
                    <div className="absolute top-0 right-0">
                        <TextField name="Search" slug="query" value={search} handleChange={handleChange} width="80" />
                    </div>
                </div>
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg clear-right">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Delete</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {userListToRender.map((user: any, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center text-sm">
                                                <div className="relative w-8 h-8 mr-3 rounded-full">
                                                    <img className="object-cover w-full h-full rounded-full" src={user.picture} alt="user picture" loading="lazy" />
                                                    <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
                                                </div>
                                                <div>
                                                    <p className="font-semibold text-black">{user.nickname}</p>
                                                    <p className="text-xs text-gray-600">{user.name}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.user_id}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.created_at}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <label onClick={() => emulateUser(user)}
                                                className="text-green cursor-pointer">
                                                View
                                            </label>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <label onClick={() => deleteUser(user)}
                                                className="text-green cursor-pointer">
                                                Delete
                                            </label>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
                <div className="text-center my-2 bottom-1">
                    <div className="inline-block mx-auto">
                        <ul className="flex pl-0 list-none rounded my-2">
                            <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 ml-0 rounded-l hover:bg-gray-200">
                                <a className="page-link" onClick={() => prevPage()} href="#">Previous</a>
                            </li>
                            {(pageNumber > 1) && <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200">
                                <a className="page-link" onClick={() => setPageNumber(pageNumber - 1)} href="#">{pageNumber - 1}</a>
                            </li>}
                            <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200">
                                <a className="page-link" onClick={() => setPageNumber(pageNumber)} href="#">{pageNumber}</a>
                            </li>
                            {(pageNumber < numPages) && < li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200">
                                <a className="page-link" onClick={() => setPageNumber(pageNumber + 1)} href="#">{pageNumber + 1}</a>
                            </li>}
                            <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 rounded-r hover:bg-gray-200">
                                <a className="page-link" onClick={() => nextPage()} href="#">Next</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    )

}
