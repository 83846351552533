import React from "react";

export type TenantMachineDataType = {
  [key: string]: any;
};

type InitialMachineStateType = {
  [key: string]: any;
};

export type MediaListItem = {
  name: string;
  video?: string | undefined;
  title: string;
  description?: string | undefined;
  pdf?: any | undefined;
  link?: string | undefined;
  showInChat?: boolean | undefined;
  subsection?: string | undefined;
};

export type MediaList = { [key: string]: MediaListItem[] };

type InfoListItem = {
  title: string;
  description?: string;
  link?: { text: string; href: string };
  list?: string[];
};

type InfoList = { [key: string]: InfoListItem[] };

type LibraryListItem = {
  name: string;
  title: string;
  video?: string | undefined;
  pdf?: any | undefined;
};

export type TenantContextType = {
  id?: string;
  name?: string;
  type?: string;
  groupName?: string;
  employeeName?: string;
  scheduleMeetingLink?: string;
  logo?: { url: string; altText: string };
  welcomeVideoUrl?: string;
  telephoneNumber?: string;
  sections?: string[];
  machineData?: TenantMachineDataType;
  initialMachineStates?: InitialMachineStateType;
  requiredFields?: any[];
  infoItems?: InfoList;
  learning?: any;
};

const TenantContext: React.Context<TenantContextType> = React.createContext({});

export default TenantContext;
