import {
  listHasRecords,
  getEmergencyReserves,
  roundDollars,
} from "./modules/business";
import {
  FormDataAdditionalPropertyType,
  FormDataAutoLoanType,
  FormDataCreditCardType,
  FormDataDebtsStudentLoanType,
  FormDataInvestmentAccountsType,
  FormDataOtherLoanType,
  FormDataPensionPlansType,
  FormDataType,
} from "../lib/types";
import useFormData from "../lib/use-form-data";

export default function AccountsTable() {
  const { formData } = useFormData();

  return (
    <div className="mx-4 my-12 overflow-x-auto sm:mx-16">
      <div className="mt-16">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Assets</h3>
        <div className="mt-2 border-t border-gray-200"></div>
      </div>

      <table className="mt-8 w-full">
        <thead>
          <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
            <th className="w-1/2 px-4 py-3">Asset</th>
            <th className="px-4 py-3">Balance</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr className="text-gray-700">
            <td className="text-ms border px-4 py-3">Emergency Reserves</td>
            <td className="border px-4 py-3 text-sm">
              {getEmergencyReserves(
                formData["checkingAccountBalance"],
                formData["savingAccountBalance"],
                formData["otherAccountBalance"],
              )}
            </td>
          </tr>
          <tr className="text-gray-700">
            <td className="text-ms border px-4 py-3">
              {formData["currentEmployment"] || "" + " Retirement Plan"}
            </td>
            <td className="border px-4 py-3 text-sm">
              {roundDollars(formData["retirementBalance"])}
            </td>
          </tr>
          {formData["spouseRetirementBalance"] && (
            <tr className="text-gray-700">
              <td className="text-ms border px-4 py-3">
                {(formData["spouseHasCurrentEmployment"] == "Yes"
                  ? formData["spouseEmployment"] || ""
                  : formData["spouseNonMnaEmployer"]) + " Retirement Plan"}
              </td>
              <td className="border px-4 py-3 text-sm">
                {roundDollars(formData["spouseRetirementBalance"])}
              </td>
            </tr>
          )}
          {formData["homeEstimatedValue"] && (
            <tr className="text-gray-700">
              <td className="text-ms border px-4 py-3">Home</td>
              <td className="border px-4 py-3 text-sm">
                {roundDollars(formData["homeEstimatedValue"])}
              </td>
            </tr>
          )}
          {listHasRecords(formData.additionalProperties, [
            "additionalPropertyType",
          ]) &&
            formData.additionalProperties?.map(
              (property: FormDataAdditionalPropertyType, index: number) => {
                if (!property.additionalPropertyType) {
                  return;
                }

                return (
                  <tr
                    className="text-gray-700"
                    key={`${index}_additionalProperties`}
                  >
                    <td className="text-ms border px-4 py-3">
                      {property.additionalPropertyType + " Property"}
                    </td>
                    <td className="border px-4 py-3 text-sm">
                      {roundDollars(property.additionalPropertyValue)}
                    </td>
                  </tr>
                );
              },
            )}
        </tbody>
      </table>

      {formData.investmentAccounts &&
        listHasRecords(formData.investmentAccounts, [
          "investmentAccountName",
        ]) && (
          <table className="mt-8 w-full">
            <thead>
              <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
                <th className="w-1/4 px-4 py-3">Investment Accounts</th>
                <th className="w-1/6 px-4 py-3">Owner</th>
                <th className="px-4 py-3">Account Type</th>
                <th className="px-4 py-3">Balance</th>
                <th className="px-4 py-3">Monthly Contribution</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {formData.investmentAccounts.map(
                (account: FormDataInvestmentAccountsType, index: number) => {
                  if (!account.investmentAccountName) {
                    return;
                  }

                  return (
                    <tr
                      key={`${index}_investmentAccounts`}
                      className="text-gray-700"
                    >
                      <td className="text-ms border px-4 py-3">
                        {account.investmentAccountName}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {account.investmentAccountOwner}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {account.investmentAccountType}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.investmentBalance)}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.investmentContribution)}
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        )}

      {formData.pensionPlans &&
        listHasRecords(formData.pensionPlans, ["pensionPlanName"]) && (
          <table className="mt-8 w-full">
            <thead>
              <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
                <th className="w-1/4 px-4 py-3">Pension Plans</th>
                <th className="w-1/6 px-4 py-3">Owner</th>
                <th className="px-4 py-3">Distribution Type</th>
                <th className="px-4 py-3">Distribution Amount</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {formData.pensionPlans.map(
                (account: FormDataPensionPlansType, index: number) => {
                  if (!account.pensionPlanName) {
                    return;
                  }

                  return (
                    <tr key={`${index}_pensionPlans`} className="text-gray-700">
                      <td className="text-ms border px-4 py-3">
                        {account.pensionPlanName}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {account.pensionPlanOwner}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {account.pensionPlanDistrubutionType}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.pensionPlanDistributionAmount)}
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        )}

      {(formData["mortgageAmount"] ||
        listHasRecords(formData.debtsCreditCard, ["creditCardName"]) ||
        listHasRecords(formData.debtsAutoLoans, ["autoLoanName"]) ||
        listHasRecords(formData.debtsStudentLoan, ["studentLoanName"]) ||
        listHasRecords(formData.debtsFederalStudentLoan, ["studentLoanName"]) ||
        listHasRecords(formData.debtsPrivateStudentLoan, [
          "studentLoanName",
        ])) && (
        <div className="mt-20">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Liabilities
          </h3>
          <div className="mt-2 border-t border-gray-200"></div>
        </div>
      )}

      {(formData["mortgageAmount"] ||
        (formData.additionalProperties &&
          listHasRecords(formData.additionalProperties, [
            "additionalPropertyType",
          ]))) && (
        <table className="mt-8 w-full">
          <thead>
            <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
              <th className="w-1/2 px-4 py-3">Liability</th>
              <th className="px-4 py-3">Balance</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {!!formData["mortgageAmount"] && (
              <tr className="text-gray-700">
                <td className="text-ms border px-4 py-3">Mortgage</td>
                <td className="border px-4 py-3 text-sm">
                  {roundDollars(formData["mortgageAmount"])}
                </td>
              </tr>
            )}
            {formData.additionalProperties &&
              listHasRecords(formData.additionalProperties, [
                "additionalPropertyType",
              ]) &&
              formData.additionalProperties.map(
                (property: any, index: number) => {
                  if (!property.additionalPropertyType) {
                    return;
                  }

                  return (
                    <tr
                      className="text-gray-700"
                      key={`${index}_additionalPropertiesMortgage`}
                    >
                      <td className="text-ms border px-4 py-3">
                        {property.additionalPropertyType + " Property Mortgage"}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(
                          property.additionalPropertyMortgageBalance,
                        )}
                      </td>
                    </tr>
                  );
                },
              )}
          </tbody>
        </table>
      )}

      {formData.debtsCreditCard &&
        listHasRecords(formData.debtsCreditCard, ["creditCardName"]) && (
          <table className="mt-8 w-full">
            <thead>
              <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
                <th className="w-1/4 px-4 py-3">Credit Cards</th>
                <th className="px-4 py-3">Balance</th>
                <th className="px-4 py-3">Interest</th>
                <th className="px-4 py-3">Monthly Payment</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {formData.debtsCreditCard.map(
                (account: FormDataCreditCardType, index: number) => {
                  if (!account.creditCardName) {
                    return;
                  }

                  return (
                    <tr
                      key={`${index}_debtsCreditCard`}
                      className="text-gray-700"
                    >
                      <td className="text-ms border px-4 py-3">
                        {account.creditCardName}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.creditCardBalance)}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {parseFloat(
                          account.creditCardInterestRate || "0",
                        ).toFixed(2) + "%"}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.creditCardPayment)}
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        )}

      {formData.debtsAutoLoans &&
        listHasRecords(formData.debtsAutoLoans, ["autoLoanName"]) && (
          <table className="mt-8 w-full">
            <thead>
              <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
                <th className="w-1/4 px-4 py-3">Auto Loans</th>
                <th className="px-4 py-3">Balance</th>
                <th className="px-4 py-3">Interest</th>
                <th className="px-4 py-3">Monthly Payment</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {formData.debtsAutoLoans.map(
                (account: FormDataAutoLoanType, index: number) => {
                  if (!account.autoLoanName) {
                    return;
                  }

                  return (
                    <tr
                      key={`${index}_debtsAutoLoans`}
                      className="text-gray-700"
                    >
                      <td className="text-ms border px-4 py-3">
                        {account.autoLoanName}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.autoLoanBalance)}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {parseFloat(
                          account.autoLoanInterestRate || "0",
                        ).toFixed(2) + "%"}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.autoLoanPayment)}
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        )}

      {formData.debtsStudentLoan &&
        listHasRecords(formData.debtsStudentLoan, ["studentLoanName"]) && (
          <table className="mt-8 w-full">
            <thead>
              <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
                <th className="w-1/4 px-4 py-3">Student Loans</th>
                <th className="px-4 py-3">Balance</th>
                <th className="px-4 py-3">Interest</th>
                <th className="px-4 py-3">Monthly Payment</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {formData.debtsStudentLoan.map(
                (account: FormDataDebtsStudentLoanType, index: number) => {
                  if (!account.studentLoanName) {
                    return;
                  }

                  return (
                    <tr
                      key={`${index}_debtsStudentLoan`}
                      className="text-gray-700"
                    >
                      <td className="text-ms border px-4 py-3">
                        {account.studentLoanName}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.studentLoanBalance)}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {parseFloat(
                          account.studentLoanInterestRate || "0",
                        ).toFixed(2) + "%"}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.studentLoanPayment)}
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        )}

      {formData.debtsFederalStudentLoan &&
        listHasRecords(formData.debtsFederalStudentLoan, [
          "studentLoanName",
        ]) && (
          <table className="mt-8 w-full">
            <thead>
              <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
                <th className="w-1/4 px-4 py-3">Federal Student Loans</th>
                <th className="px-4 py-3">Balance</th>
                <th className="px-4 py-3">Interest</th>
                <th className="px-4 py-3">Monthly Payment</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {formData.debtsFederalStudentLoan.map(
                (account: FormDataDebtsStudentLoanType, index: number) => {
                  if (!account.studentLoanName) {
                    return;
                  }

                  return (
                    <tr
                      key={`${index}_debtsFederalStudentLoan`}
                      className="text-gray-700"
                    >
                      <td className="text-ms border px-4 py-3">
                        {account.studentLoanName}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.studentLoanBalance)}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {parseFloat(
                          account.studentLoanInterestRate || "0",
                        ).toFixed(2) + "%"}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.studentLoanPayment)}
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        )}

      {formData.debtsPrivateStudentLoan &&
        listHasRecords(formData.debtsPrivateStudentLoan, [
          "studentLoanName",
        ]) && (
          <table className="mt-8 w-full">
            <thead>
              <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
                <th className="w-1/4 px-4 py-3">Private Student Loans</th>
                <th className="px-4 py-3">Balance</th>
                <th className="px-4 py-3">Interest</th>
                <th className="px-4 py-3">Monthly Payment</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {formData.debtsPrivateStudentLoan.map(
                (account: FormDataDebtsStudentLoanType, index: number) => {
                  if (!account.studentLoanName) {
                    return;
                  }

                  return (
                    <tr
                      key={`${index}_debtsPrivateStudentLoan`}
                      className="text-gray-700"
                    >
                      <td className="text-ms border px-4 py-3">
                        {account.studentLoanName}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.studentLoanBalance)}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {parseFloat(
                          account.studentLoanInterestRate || "0",
                        ).toFixed(2) + "%"}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.studentLoanPayment)}
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        )}

      {formData.debtsOtherLoan &&
        listHasRecords(formData.debtsOtherLoan, ["otherLoanName"]) && (
          <table className="mt-8 w-full">
            <thead>
              <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
                <th className="w-1/4 px-4 py-3">Other Loans</th>
                <th className="px-4 py-3">Balance</th>
                <th className="px-4 py-3">Interest</th>
                <th className="px-4 py-3">Monthly Payment</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {formData.debtsOtherLoan.map(
                (account: FormDataOtherLoanType, index: number) => {
                  if (!account.otherLoanName) {
                    return;
                  }

                  return (
                    <tr
                      key={`${index}debtsOtherLoan`}
                      className="text-gray-700"
                    >
                      <td className="text-ms border px-4 py-3">
                        {account.otherLoanName}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.otherLoanBalance)}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {parseFloat(
                          account.otherLoanInterestRate || "0",
                        ).toFixed(2) + "%"}
                      </td>
                      <td className="border px-4 py-3 text-sm">
                        {roundDollars(account.otherLoanPayment)}
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        )}
    </div>
  );
}
