import axios, { AxiosInstance } from "axios";

export const instance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_DOMAIN,
});

export async function fetchQueryOperation(
  accessToken: String,
  operation: string,
  tenantId?: string,
  emulatingUser?: any | null,
): Promise<any> {
  return await instance.get(`/api/v2/query?operation=${operation}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "X-Tenant-Id": tenantId,
      "X-Emulating-User-Id": emulatingUser && "user_id" in emulatingUser ? emulatingUser.user_id : "",
    },
  });
}
