import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { parseJSON } from "date-fns";

type DateFieldProps = {
  name: string;
  slug: string;
  value?: Date | string;
  index?: number;
  onEnter?: any;
  errors?: any;
  setValue: any;
};

function DateField({
  name,
  slug,
  value,
  index,
  onEnter,
  errors,
  setValue,
}: DateFieldProps) {
  function onChange(date: any) {
    setValue(slug, date, index);
  }

  function onKeyDown(event: any) {
    if (onEnter && event.key === "Enter") {
      event.preventDefault();
      onEnter(slug);
    }
  }

  return (
    <>
      <label htmlFor={slug} className="block text-sm font-medium text-gray-700">
        {name}
      </label>
      <DatePicker
        id={slug}
        popperPlacement="top-start"
        name={slug}
        selected={value ? parseJSON(value) : undefined}
        onChange={onChange}
        onKeyDown={onKeyDown}
        minDate={new Date("01-01-1900")}
        maxDate={new Date("01-01-2057")}
        className={`mt-1 block w-full shadow-sm focus:border-green focus:ring-1 focus:ring-green sm:text-sm ${
          errors && errors[slug] ? "border-red-500" : ""
        } rounded-md`}
      />
      {errors && errors[slug] && (
        <p className="text-xs text-red-500">{errors[slug]?.message}</p>
      )}
    </>
  );
}

export default DateField;
