import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import UserMenu from "./widgets/UserMenu";
import LoginButton from "./buttons/LoginButton";
import { isReadyForAnalysis } from "./modules/business";
import TenantContext from "../context/tenant";
import useFormData from "../lib/use-form-data";
import { isAdmin } from "../lib/helpers";

const Header = () => {
  const { logo, requiredFields } = useContext(TenantContext);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [showDashboard, setShowDashboard] = useState(false);
  const userButton = isAuthenticated ? <UserMenu /> : <LoginButton />;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { formData: formValues, trigger } = useFormData();

  useEffect(() => {
    if (formValues?.showDashboard) {
      setShowDashboard(true);
    } else {
      if (isReadyForAnalysis(formValues, requiredFields)) {
        setShowDashboard(true);
        trigger(
          { ...formValues, showDashboard: true },
          { optimisticData: { ...formValues, showDashboard: true } },
        );
      }
    }
  }, [formValues]);

  return (
    <nav className={"bg-primary"}>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative flex h-20 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
            <button
              type="button"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={`inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-primary-light hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white`}
              aria-controls="mobile-menu"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <img
                src="/images/dorval-chrone-logo-vector-white.svg"
                alt="Dorval Chrone Logo"
                className="white mr-2 h-10"
              ></img>
              <p className="text-xl font-medium text-white">
                Quality of Life Planning®
              </p>
            </div>
            <div className="ml-12 hidden lg:block">
              <div className="flex space-x-4">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                      : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`
                  }
                >
                  {isAuthenticated ? "Interview" : "Home"}
                </NavLink>
                {isAuthenticated && showDashboard && (
                  <NavLink
                    to="/dashboard"
                    className={({ isActive }) =>
                      isActive
                        ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                        : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`
                    }
                  >
                    Dashboard
                  </NavLink>
                )}
                {isAuthenticated && (
                  <NavLink
                    to="/forms"
                    className={({ isActive }) =>
                      isActive
                        ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                        : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`
                    }
                  >
                    Profile
                  </NavLink>
                )}
                {isAuthenticated && (
                  <NavLink
                    to="/puzzle"
                    className={({ isActive }) =>
                      isActive
                        ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                        : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`
                    }
                  >
                    My Puzzle
                  </NavLink>
                )}
                {isAuthenticated && (
                  <NavLink
                    to="/help"
                    className={({ isActive }) =>
                      isActive
                        ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                        : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`
                    }
                  >
                    Learning
                  </NavLink>
                )}
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive
                      ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                      : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`
                  }
                >
                  Contact
                </NavLink>
                {isAuthenticated && isAdmin(user) &&
                  <NavLink
                    to="/admin"
                    className={({ isActive }) =>
                      isActive
                        ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                        : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`
                    }
                  >
                    Admin
                  </NavLink>
                }
              </div>
            </div>
          </div>
          <div>
            <img className="mx-auto mr-6 h-12 rounded-md" src={logo?.url}></img>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {!isLoading && userButton}
          </div>
        </div>
      </div>
      <div
        className="lg:hidden"
        id="mobile-menu"
        style={{ display: isMenuOpen ? "block" : "none" }}
      >
        <div className="space-y-1 px-2 pb-3 pt-2">
          <NavLink
            to="/"
            className={({ isActive }) =>
              (isActive
                ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`) +
              " block"
            }
          >
            {isAuthenticated ? "Interview" : "Home"}
          </NavLink>
          {isAuthenticated && showDashboard && (
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                (isActive
                  ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                  : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`) +
                " block"
              }
            >
              Dashboard
            </NavLink>
          )}
          {isAuthenticated && (
            <NavLink
              to="/forms"
              className={({ isActive }) =>
                (isActive
                  ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                  : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`) +
                " block"
              }
            >
              Profile
            </NavLink>
          )}
          {isAuthenticated && (
            <NavLink
              to="/puzzle"
              className={({ isActive }) =>
                (isActive
                  ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                  : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`) +
                " block"
              }
            >
              My Puzzle
            </NavLink>
          )}
          {isAuthenticated && (
            <NavLink
              to="/help"
              className={({ isActive }) =>
                (isActive
                  ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                  : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`) +
                " block"
              }
            >
              Learning
            </NavLink>
          )}
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              (isActive
                ? `rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white`
                : `rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-light hover:text-white`) +
              " block"
            }
          >
            Contact
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Header;
