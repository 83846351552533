import "./Modal.css";

function Modal(props: any) {
  return (
    <div className={`modal ${props.show ? "display-block" : "display-none"}`}>
      <section className="modal-main relative rounded-md shadow">
        {props.children}
        <div className="relative clear-both block bg-gray-100 px-4 py-3 text-right sm:px-6">
          {!props.hideClose && (
            <button
              type="button"
              onClick={props.handleClose}
              className={`hover:bg-primary-accent-100 inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm duration-150 ease-in-out`}
            >
              Close
            </button>
          )}
          {props.showSubmit && (
            <button
              type="button"
              onClick={props.handleSubmit}
              className={`ml-4 inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm`}
            >
              Submit
            </button>
          )}
          {props.showConfirm && (
            <button
              type="button"
              onClick={props.handleConfirm}
              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red px-4 py-2 text-sm font-medium text-white shadow-sm"
            >
              Confirm
            </button>
          )}
        </div>
      </section>
    </div>
  );
}

export default Modal;
