import { isPast, parseJSON } from "date-fns";

export function validate(fieldValidations, fieldValues, setErrors, index = 0) {
  let fieldErrors = {};

  Object.keys(fieldValidations).forEach((field) => {
    let value = fieldValues[field] || "";
    let validation = fieldValidations[field] || "";

    if (!validation.includes("optional") && !value) {
      fieldErrors[field] = { message: "Required field.", index: index };
    }

    if (
      validation.includes("email") &&
      value &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ) {
      fieldErrors[field] = { message: "Invalid email address.", index: index };
    }

    if (
      validation.includes("phone") &&
      value &&
      !/^\(([0-9]{3})\)[ ]?([0-9]{3})[-]?([0-9]{4})$/i.test(value)
    ) {
      fieldErrors[field] = {
        message: "Required (xxx) xxx-xxxx format.",
        index: index,
      };
    }

    if (
      validation.includes("numeric") &&
      value &&
      (!/^-?[\d,\.]+\.?\d*%?$/.test(value) || /,[,]+/.test(value))
    ) {
      fieldErrors[field] = { message: "Number required.", index: index };
    }

    if (
      validation.includes("text") &&
      value &&
      !/^[a-zA-Z\s\d\.,\-]*$/.test(value)
    ) {
      fieldErrors[field] = {
        message: "Invalid characters present.",
        index: index,
      };
    }

    if (validation.includes("birthday") && value && !isPast(parseJSON(value))) {
      fieldErrors[field] = {
        message: "Date must be in the past.",
        index: index,
      };
    }

    if (validation.includes("between") && value) {
      const range = validation.match(/between:([0-9]+)-([0-9]+)/);

      if (parseFloat(value) < parseFloat(range[1])) {
        fieldErrors[field] = { message: `Min value ${range[1]}`, index: index };
      }
      if (parseFloat(value) > parseFloat(range[2])) {
        fieldErrors[field] = { message: `Max value ${range[2]}`, index: index };
      }
    }
  });

  setErrors(fieldErrors);

  if (Object.keys(fieldErrors).length > 0) {
    return false;
  }

  // current fields are all valid
  return true;
}

export function formSpecificValidate(
  form,
  formRowValues,
  setErrors,
  index = 0,
) {
  if (form === "investmentAccounts") {
    let bondAllocation = parseFloat(formRowValues["investmentBondAllocation"]);
    let stockAllocation = parseFloat(
      formRowValues["investmentStockAllocation"],
    );

    if (
      bondAllocation &&
      stockAllocation &&
      bondAllocation + stockAllocation != 100
    ) {
      setErrors({
        investmentBondAllocation: {
          message: "Allocation should total 100%",
          index: index,
        },
        investmentStockAllocation: {
          message: "Allocation should total 100%",
          index: index,
        },
      });

      return false;
    }
  }

  // current fields are all valid
  return true;
}
