import { useEffect, useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import StyledBounceLoader from "../../StyledBounceLoader";
import TextField from "../../fields/TextField";
import { isAdmin } from "../../../lib/helpers";
import { BounceLoader } from "react-spinners";
import SelectField from "../../fields/SelectField";
import TenantContext from "../../../context/tenant";

const BASE_URL = import.meta.env.VITE_API_DOMAIN;

// TODO: Move types to types file
type LearningVideo = {
  name: string;
  video: string;
};

type LearningPdf = {
  name: string;
  pdf: { file: string };
};

type FileList = {
  [key: string]: (LearningVideo | LearningPdf)[];
};

export default function Files() {
  const { user, isLoading, getAccessTokenSilently } = useAuth0();
  const { id } = useContext(TenantContext);

  const [loading, setLoading] = useState(true);
  const [posting, setPosting] = useState(false);
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState({} as Blob);
  const [filesList, setFilesList] = useState({} as FileList);

  async function fetchFilesList() {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(BASE_URL + "/api/v2/files", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Tenant-Id": id || "1",
      },
    });

    if (response.status != 200) {
      return;
    }

    const data = await response.json();

    setFilesList(data);
    setLoading(false);
  }

  async function getPresignedUrl(file: any) {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(BASE_URL + "/api/v2/files/url?file=" + encodeURIComponent(file.name), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Tenant-Id": id || "1",
      },
    });

    if (response.status != 200) {
      return;
    }

    const data = await response.json();

    return data;
  }

  async function uploadFile(presignedUrl: any) {
    let formData = new FormData();
    Object.keys(presignedUrl.fields).forEach(key => {
      formData.append(key, presignedUrl.fields[key])
    })
    formData.append('file', file)

    const response = await fetch(presignedUrl.url, {
      method: "POST",
      body: formData,
      mode: 'no-cors'
    });

    postFile(); // update dynamodb record if s3 file upload succeeds
    setFile({} as Blob);
    setPosting(false);
  }

  async function postFile() {
    const accessToken = await getAccessTokenSilently();

    let formData = new FormData();
    formData.append('fileName', file.name);

    const response = await fetch(BASE_URL + "/api/v2/files?file=" + encodeURIComponent(file.name), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Tenant-Id": id || "1",
      },
      body: formData,
    });

    if (response.status == 200 || response.status == 201) {
      fetchFilesList();
      alert("PDF file uploaded sucessfully");  // TODO: replace with modal
    } else {
      alert("PDF file FAILED");  // TODO: replace with modal
    }
  }

  async function deleteFile(file: any) {
    // TODO: add confirmation modal
    
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(BASE_URL + "/api/v2/files?file=" + encodeURIComponent(file.name), {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Tenant-Id": id || "1",
      },
    })

    alert("PDF file deleted sucessfully");  // TODO: replace with modal
    fetchFilesList();
  }

  function handleChange(event: any) {
    setFile(event.target.files[0])
  } 

  async function handleSubmit(event: any) {
    event.preventDefault();

    let presignedUrl = await getPresignedUrl(file);

    if (!file || (file && file.type !== 'application/pdf')) {
      alert("Please choose PDF file to upload");  // TODO: replace with modal
      setFile({} as Blob);
    } else {
      setPosting(true);
      uploadFile(presignedUrl);
    }
  }

  useEffect(() => {
    if (!isLoading) {
      fetchFilesList();
    }
  }, [isLoading]);

  if (!isLoading && !isAdmin(user)) {
    location.href = "/";
  }

  if (loading) {
    return (
      <div className="mt-24 flex items-center justify-center">
        <StyledBounceLoader />
      </div>
    );
  }

  return (
    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div className="clear-right overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="grid grid-cols-8 gap-6 bg-white p-6 pb-12">
            <div className="col-span-8">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Upload PDF
              </h3>
              <div className="mt-2 border-t border-gray-200"></div>
            </div>

            <div className="col-span-8">
              <input className="w-full" type="file" name="pdf_file" onChange={handleChange} key={file.toString()} />
            </div>
          </div>

          <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              disabled={loading}
              className={`${
                loading ? "bg-gray-400" : "bg-primary"
              } inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm`}
            >
              <BounceLoader loading={posting} size={20} color="#ffffff" />
              <span className={posting ? "ml-2" : ""}>Upload</span>
            </button>
          </div>
        </form>
      </div>

      <div className="relative mt-10 h-20">
        <div className="ml-2 pt-8">
          <h3 className="inline w-56 text-lg font-medium leading-6 text-gray-900">
            File List
          </h3>
        </div>
      </div>
      <div className="clear-right overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                View
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {filesList && filesList.pdfs &&
              filesList.pdfs.map((file: any, i) => {
                return (
                  <tr key={i}>
                    <td className="whitespace-nowrap px-6 py-4">{file.name}</td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      <a href={file.path} target="_blank" className="cursor-pointer text-green" >
                        View
                      </a>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      <label
                        onClick={() => deleteFile(file)}
                        className="cursor-pointer text-green"
                      >
                        Delete
                      </label>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
