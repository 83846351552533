import { Link, useNavigate } from "react-router-dom";
import { parseJSON, format } from "date-fns";
import parse from "html-react-parser";
import ReactTooltip from "react-tooltip";

import { checkConditional } from "./conditional";
import { cleanNumber, getAge } from "./business";
import TextField from "../fields/TextField";
import TextArea from "../fields/TextArea";
import DateField from "../fields/DateField";
import PhoneField from "../fields/PhoneField";
import SelectField from "../fields/SelectField";
import MultiSelectField from "../fields/MultiSelectField";
import ChildrenForm from "../forms/ChildrenForm";
import ScrollableFeed from "react-scrollable-feed";
import React from "react";
import useFormData from "../../lib/use-form-data";

type TooltipType = { name: string; label: string };

type ConditionalType = {
  message: string;
};

type MediaType = {
  video?: string;
  icon: string;
  length: string;
  description: any;
  pdf?: string;
  title: string;
  image?: string;
  height?: string;
  width?: string;
};

type FormFieldsType = {
  label: string;
  name: string;
  format: string;
};

type FormType = {
  data: any;
  type: any;
  name: string;
  fields: FormFieldsType[];
};

type FieldsType = {
  type: string;
  isAge: boolean;
  name: string;
  format: string;
  label: string;
  width: string;
  caption: string;
  dontFocus: boolean | undefined;
  options: { name: string; label: string }[];
  step: any;
  margin: string;
};

type ButtonsFieldType = {
  name: string;
  format: string;
};

type ButtonsType = {
  modal?: string;
  form: string;
  message: string;
  name: string;
  label: string;
  link?: string;
  step?: string;
  section?: string;
  tip?: string;
  fields: ButtonsFieldType[];
};

type LogMetaType = {
  heading: any;
  message: string;
  tooltip: TooltipType;
  messageBackground: string;
  conditionals: ConditionalType[];
  fields: FieldsType[];
  media: MediaType[];
  forms: FormType[];
  buttons: ButtonsType[];
  buttonMessage?: string;
};

type ChatLogProps = {
  currentChatLog: any[];
  formValues: any;
  setFormValues: any;
  step: any;
  onEnter: any;
  currentlyEditing: { name: string } | null;
  editField: any;
  errors: any;
  meta: any;
  showModal: any;
  currentMessageRef: any;
};

function ChatLog({
  currentChatLog,
  formValues,
  setFormValues,
  step,
  onEnter,
  currentlyEditing,
  editField,
  errors,
  meta,
  showModal,
  currentMessageRef,
}: ChatLogProps): JSX.Element {
  const navigate = useNavigate();
  const { mutateFormData } = useFormData();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  async function setValue(name: any, value: any) {
    await mutateFormData(
      { ...formValues, [name]: value },
      { revalidate: false, optimisticData: { ...formValues, [name]: value } },
    );
  }

  async function handleChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    await mutateFormData(
      { ...formValues, [name]: value },
      { revalidate: false, optimisticData: { ...formValues, [name]: value } },
    );
  }

  function isCurrentButton(buttonName: any) {
    return getCurrentButton().includes(buttonName);
  }

  function getCurrentButton() {
    if (!meta?.buttons) {
      return [];
    }

    return meta.buttons.map((button: any) => button.name);
  }

  function isCurrentField(fieldName: string) {
    return (
      getCurrentFieldNames().includes(fieldName) ||
      currentlyEditing?.name == fieldName
    );
  }

  function getCurrentFieldNames() {
    if (!meta?.fields) {
      return [];
    }

    return meta.fields.map((field: any) => field.name);
  }

  function isCurrentForm(formName: string) {
    return getCurrentFormNames().includes(formName);
  }

  function getCurrentFormNames() {
    if (!meta.forms) {
      return [];
    }

    return meta.forms.map((form: { name: string }) => form.name);
  }

  function getCurrentMessage() {
    return meta?.message;
  }

  // replace [fieldName] with value of fieldName from formValues
  function parseMessage(message: string) {
    let parsedMessage = message;

    if (message.includes("[")) {
      const dynamicFields = [...message.matchAll(/\[([A-Za-z0-9|]+)\]/g)];

      dynamicFields.forEach((dynamicField) => {
        let fieldName = dynamicField[1] || "";
        let value = "";

        if (fieldName.includes("|")) {
          let fieldNames = fieldName.split("|");
          fieldNames.forEach((field: string) => {
            if (!value && formValues[field]) {
              value = formValues[field];
            }
          });
        } else {
          value = formValues[fieldName];
        }

        if (value) {
          parsedMessage = parsedMessage.replace(dynamicField[0], value);
        }
      });
    }

    return parse(parsedMessage);
  }

  function printTooltip(tooltip: TooltipType) {
    if (tooltip) {
      return (
        <div className="absolute -right-7 top-0">
          <button data-tip data-for={tooltip.name}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 text-primary-light`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </button>

          <ReactTooltip
            id={tooltip.name}
            place="top"
            effect="solid"
            multiline={true}
            html={true}
          >
            {tooltip.label}
          </ReactTooltip>
        </div>
      );
    }
  }

  function rowHasFieldData(row: any, fields: any) {
    if (!row || !fields) {
      return false;
    }

    let rowHasData = true;

    fields.forEach((field: any) => {
      if (!row[field.name]) {
        rowHasData = false;
      }
    });

    return rowHasData;
  }

  function formatField(value: string, requestedFormat: string) {
    if (requestedFormat === "dollars") {
      // @ts-ignore
      return formatter.format(cleanNumber(value));
    } else if (requestedFormat === "decimal") {
      return parseFloat(cleanNumber(value)).toFixed(2);
    } else if (requestedFormat === "percentage") {
      return parseFloat(cleanNumber(value)).toFixed(2) + "%";
    } else if (requestedFormat === "years") {
      return `${value} years`;
    } else if (requestedFormat === "date") {
      return value ? format(parseJSON(value), "PPP") : "";
    } else {
      return value;
    }
  }

  function renderMessages(
    logMeta: LogMetaType | null,
    i: number,
  ): JSX.Element[] {
    if (logMeta?.heading && logMeta?.message) {
      return [
        <div key={i} className="max-w-bubble">
          <div>
            <div
              className={"bubble white relative bg-primary text-sm font-bold"}
            >
              {parseMessage(logMeta.heading)}
              {printTooltip(logMeta.tooltip)}
            </div>
          </div>
          <div>
            <div
              className={`bubble relative ${
                logMeta.messageBackground
                  ? logMeta.messageBackground
                  : "bg-primary"
              } white text-sm`}
            >
              {parseMessage(logMeta.message)}
            </div>
          </div>
        </div>,
      ];
    } else if (logMeta?.heading) {
      return [
        <div key={i} className="max-w-bubble">
          <div className={"bubble white relative bg-primary text-sm font-bold"}>
            {parseMessage(logMeta.heading)}
            {printTooltip(logMeta.tooltip)}
          </div>
        </div>,
      ];
    } else if (logMeta?.message) {
      return [
        <div
          key={i}
          className="max-w-bubble"
          ref={
            getCurrentMessage() == logMeta.message ? currentMessageRef : null
          }
        >
          <div
            className={`bubble relative ${
              logMeta.messageBackground
                ? logMeta.messageBackground
                : "bg-primary"
            } white text-sm`}
          >
            {parseMessage(logMeta.message)}
            {printTooltip(logMeta.tooltip)}
          </div>
        </div>,
      ];
    }
    return [];
  }

  function renderButtons(logMeta: LogMetaType, i: number): JSX.Element[] {
    if (!logMeta?.buttons) {
      return [];
    }

    return [
      <div key={i + "_buttons"}>
        {logMeta.buttonMessage && (
          <div className={"bubble white bg-primary text-sm"}>
            {logMeta.buttonMessage}
          </div>
        )}

        {logMeta.buttons.map((button: ButtonsType, j: number) => {
          if (button.modal && button.form && !isCurrentButton(button.name)) {
            let subFormValues = formValues[button.form];

            return (
              <div
                key={i + "_" + j + "_completedForm"}
                className="my-4 max-w-lg sm:max-w-full"
              >
                <div className="float-right">
                  <div
                    onClick={showModal}
                    // @ts-ignore
                    name={button.name}
                    modal={button.modal}
                    className={
                      "bubble white cursor-pointer bg-gray-400 text-sm hover:bg-primary-light"
                    }
                    style={{ padding: "10px 15px" }}
                  >
                    {/* // TODO: move to it's own method renderButtonForm? */}
                    <table className="table-auto border-separate">
                      <thead className="text-left">
                        <tr>
                          {button.fields &&
                            button.fields.map((field: any, k: number) => {
                              return (
                                <th
                                  key={
                                    i +
                                    "_" +
                                    j +
                                    "_" +
                                    k +
                                    "_completedFormHeading"
                                  }
                                  className="pr-4"
                                  // @ts-ignore
                                  modal={button.modal}
                                >
                                  {field.label}
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {subFormValues &&
                          subFormValues.map((row: any, l: number) => {
                            if (!rowHasFieldData(row, button.fields)) {
                              return null;
                            }

                            return (
                              <tr
                                key={
                                  i + "_" + j + "_" + l + "_completedFormRow"
                                }
                              >
                                {button.fields &&
                                  button.fields.map(
                                    (field: ButtonsFieldType, k: number) => {
                                      return (
                                        <td
                                          key={
                                            i +
                                            "_" +
                                            j +
                                            "__" +
                                            k +
                                            "_completedFormRow"
                                          }
                                          className="pr-4"
                                          // @ts-ignore

                                          modal={button.modal}
                                        >
                                          {formatField(
                                            row[field.name],
                                            field.format,
                                          )}
                                        </td>
                                      );
                                    },
                                  )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="clear-right"></div>
              </div>
            );
          } else if (
            (formValues[button.name] === button.label ||
              button.modal ||
              button.link) &&
            !isCurrentButton(button.name)
          ) {
            return (
              <div
                key={i + "_" + j + "_selectedButton"}
                className="my-4 max-w-lg sm:max-w-full"
              >
                <div className="float-right">
                  <div
                    className="bubble white bg-gray-400 text-sm"
                    style={{ padding: "10px 15px" }}
                  >
                    {button.label}
                  </div>
                </div>
                <div className="clear-right"></div>
              </div>
            );
          } else if (
            formValues[button.name] !== button.label &&
            !isCurrentButton(button.name)
          ) {
            return <div key={i + "_" + j + "_selectedButton"}></div>;
          }

          return (
            <div key={i + "_" + j + "_button"}>
              <div className="float-right">
                {button.step && (
                  <button
                    onClick={step}
                    name={button.name}
                    // @ts-ignore
                    nextstep={button.step}
                    className={`mx-1 my-4 rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white hover:bg-primary`}
                  >
                    {button.label}
                  </button>
                )}
                {button.modal && (
                  <button
                    type="button"
                    onClick={showModal}
                    name={button.name}
                    // @ts-ignore

                    modal={button.modal}
                    className={`mx-1 my-4 rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white hover:bg-primary`}
                  >
                    {button.label}
                  </button>
                )}
                {button.section && (
                  <button
                    type="button"
                    onClick={() => navigate(`/interview/${button.section}`, {})}
                    className={`mx-1 my-4 rounded-md border-2 border-primary-light bg-primary px-3 py-2 text-sm font-medium text-white`}
                  >
                    {button.label}
                  </button>
                )}
                {button.link && (
                  <div>
                    <Link
                      to={button.link}
                      className={`mx-1 my-4 inline-block rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white hover:bg-primary`}
                    >
                      {button.label}
                    </Link>
                    {button.tip && (
                      <button onClick={(event) => showModal(event, button.tip)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-6 w-6 text-primary-light`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          ></path>
                        </svg>
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div className="clear-right"></div>
      </div>,
    ];
  }

  function renderFields(logMeta: LogMetaType, i: number): JSX.Element[] {
    if (!logMeta?.fields) {
      return [];
    }

    return [
      <div key={i + "_fields"} className="max-w-lg sm:max-w-full">
        <div className="float-right">
          {logMeta.fields.map((field: FieldsType, j: number) => {
            if (!isCurrentField(field.name)) {
              let value = formValues[field.name];

              if (!value) {
                return (
                  <div
                    key={i + "_" + j + "_response"}
                    className="inline-block"
                  ></div>
                );
              }

              // handle special field types
              if (field.type === "date") {
                const dt = parseJSON(value);

                value = field.isAge
                  ? `${format(dt, "PPP")} (${getAge(value)} years old)`
                  : format(dt, "PPP");
              }

              if (field.type === "multiselect") {
                if (value.length < 1) {
                  value = "[None]";
                } else {
                  value = value
                    .map((selected: { name: string }) => selected.name)
                    .join(", ");
                }
              }

              // format field values
              // TODO: value = formatField(value, field.format)
              if (field.format === "dollars") {
                // @ts-ignore
                value = formatter.format(cleanNumber(value));
              } else if (field.format === "decimal") {
                value = parseFloat(cleanNumber(value)).toFixed(2);
              } else if (field.format === "percentage") {
                value = parseFloat(cleanNumber(value)).toFixed(2) + "%";
              } else if (field.format === "years") {
                value = value + " years";
              }

              return (
                <div
                  key={i + "_" + j + "_response"}
                  onClick={() => editField(field)}
                  className={`bubble white my-2 ml-2 inline-block cursor-pointer bg-gray-400 text-sm hover:bg-primary-light`}
                  style={{ padding: "10px 15px" }}
                >
                  {value}
                </div>
              );
            }

            return (
              <div key={i + "_" + j + "_field"} className="inline-block">
                {
                  {
                    input: (
                      <TextField
                        name={field.label}
                        slug={field.name}
                        value={formValues}
                        setValue={setValue}
                        onEnter={onEnter}
                        errors={errors}
                        width={field.width}
                        caption={field.caption}
                        format={field.format}
                        margin="ml-4"
                        autoFocus={!(field.dontFocus || true)}
                      />
                    ),
                    textarea: (
                      <TextArea
                        name={field.label}
                        slug={field.name}
                        value={formValues}
                        handleChange={handleChange}
                        errors={errors}
                        width={field.width}
                        caption={field.caption}
                        autoFocus={!field.dontFocus}
                      />
                    ),
                    date: (
                      <DateField
                        name={field.label}
                        slug={field.name}
                        value={formValues[field.name]}
                        setValue={setValue}
                        onEnter={onEnter}
                        errors={errors}
                      />
                    ),
                    phone: (
                      <PhoneField
                        name={field.label}
                        slug={field.name}
                        setValue={setValue}
                        errors={errors}
                      />
                    ),
                    select: (
                      <SelectField
                        name={field.label}
                        slug={field.name}
                        value={formValues}
                        handleChange={handleChange}
                        onEnter={onEnter}
                        errors={errors}
                        options={field.options}
                        step={field.step}
                        width={field.width}
                      />
                    ),
                    multiselect: (
                      <MultiSelectField
                        name={field.label}
                        slug={field.name}
                        value={formValues}
                        selectedValues={formValues[field.name]}
                        setValue={setValue}
                        errors={errors}
                        options={field.options}
                        width={field.width}
                        margin={field.margin}
                        autoFocus={!field.dontFocus}
                      />
                    ),
                  }[field.type]
                }
              </div>
            );
          })}
        </div>
        <div className="clear-right mb-2"></div>
      </div>,
    ];
  }

  function renderForms(logMeta: LogMetaType, i: number): JSX.Element[] {
    if (!logMeta?.forms) {
      return [];
    }

    return [
      <div key={i + "_forms"}>
        {logMeta.forms.map((form: FormType, j: number) => {
          if (!isCurrentForm(form.name)) {
            let subFormValues = formValues[form.name];

            return (
              <div
                key={i + "_" + j + "_form_response"}
                className="float-right my-4"
              >
                <div
                  className="bubble white ml-2 bg-gray-400 text-sm"
                  style={{ padding: "10px 15px" }}
                >
                  <table className="table-auto border-separate">
                    <thead className="text-left">
                      <tr>
                        {form.fields &&
                          form.fields.map(
                            (field: FormFieldsType, k: number) => {
                              return (
                                <th
                                  key={
                                    i + "_" + j + "_" + k + "_completedHeading"
                                  }
                                  className="pr-4"
                                >
                                  {field.label}
                                </th>
                              );
                            },
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {subFormValues &&
                        Object.keys(subFormValues).map((index, l) => {
                          return (
                            <tr key={i + "_" + j + "_" + l + "_completedRow"}>
                              {form.fields &&
                                form.fields.map(
                                  (field: FormFieldsType, k: number) => {
                                    return (
                                      <td
                                        key={
                                          i +
                                          "_" +
                                          j +
                                          "__" +
                                          k +
                                          "_completedRow"
                                        }
                                        className="pr-4"
                                      >
                                        {formatField(
                                          subFormValues[index][field.name],
                                          field.format,
                                        )}
                                      </td>
                                    );
                                  },
                                )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          }

          return (
            <div key={`${i}_${j}_form`}>
              {
                {
                  ChildrenForm: (
                    <ChildrenForm
                      value={formValues}
                      setFormValues={setFormValues}
                      data={form.data}
                      errors={errors}
                    />
                  ),
                }[form.type as string]
              }
            </div>
          );
        })}
        <div className="clear-right mb-2"></div>
      </div>,
    ];
  }

  function renderMedia(logMeta: LogMetaType, i: number): JSX.Element[] {
    if (!logMeta?.media || !Array.isArray(logMeta?.media)) {
      return [];
    }

    return logMeta.media.map((media: MediaType, j: number) => {
      return (
        <div key={i + "_" + j + "_media"}>
          {media.video && (
            <div className="videocard relative my-4 mb-8 w-80 overflow-hidden border-gray-800 bg-gray-200 shadow-lg">
              <div
                onClick={showModal}
                // @ts-ignore
                name={media.name}
                video={media.video}
                modal="mediaModal"
                className="videoicon cursor-pointer overflow-hidden"
              >
                <img
                  className="w-full"
                  src={media.icon}
                  // @ts-ignore
                  video={media.video}
                  modal="mediaModal"
                  alt="Video Icon"
                />
              </div>
              <div
                className={`badge absolute right-0 top-0 m-2 rounded bg-primary-light p-1 px-2 text-xs font-bold text-gray-200`}
              >
                {media.length}
              </div>
              <div className="desc p-4 text-gray-800">
                <span className="title block font-bold">{media.title}</span>
                <span className="description mb-2 block border-gray-400 py-2 text-sm">
                  {media.description}
                </span>
              </div>
            </div>
          )}

          {media.pdf && (
            <div className="relative my-4 mb-8 w-56 overflow-hidden border-gray-800 bg-gray-200 text-center shadow-lg">
              <div
                onClick={showModal}
                // @ts-ignore
                name={media.name}
                pdf={media.pdf}
                modal="pdfModal"
                className="inline-block cursor-pointer overflow-hidden pt-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // @ts-ignore
                  pdf={media.pdf}
                  modal="pdfModal"
                  className="h-16 w-16"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div className="desc p-4 text-gray-800">
                <span className="description block border-gray-400 text-sm">
                  {media.description}
                </span>
              </div>
            </div>
          )}

          {media.image && (
            <div
              className={`w-${
                media.width || "80"
              } relative my-2 mb-8 overflow-hidden border-gray-800 bg-gray-200 shadow-lg`}
            >
              <div className="overflow-hidden p-1">
                <img
                  className={`w-auto h-${media.height || "20"} mx-auto`}
                  src={media.image}
                  alt={media.title}
                />
              </div>
              <div className="desc p-4 text-gray-800">
                <span className="description block border-gray-400 text-sm">
                  {media.description}
                </span>
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  function renderConditionals(logMeta: LogMetaType, i: number): JSX.Element[] {
    if (!logMeta?.conditionals) {
      return [];
    }

    return logMeta.conditionals
      .filter(
        (conditional: ConditionalType) =>
          conditional.message && checkConditional(conditional, formValues),
      )
      .map((conditional: ConditionalType) => {
        return (
          <div key={`${i}_conditional`}>
            <div>
              <div className={`bubble white bg-primary text-sm font-bold`}>
                {conditional.message}
              </div>
            </div>
          </div>
        );
      });
  }

  return (
    /* @ts-ignore */
    <ScrollableFeed className="p-6">
      {currentChatLog
        .map((logMeta: LogMetaType, i: number) => [
          ...renderMessages(logMeta, i),
          ...renderConditionals(logMeta, i),
          ...renderMedia(logMeta, i),
          ...renderButtons(logMeta, i),
          ...renderFields(logMeta, i),
          ...renderForms(logMeta, i),
        ])
        .flat(1)}
    </ScrollableFeed>
  );
}

export default ChatLog;
