import { useContext } from "react";
import { ThemeContext } from "styled-components";
import TenantContext from "../../context/tenant";

function Contact() {
  const themeContext = useContext(ThemeContext);
  const { id, telephoneNumber } = useContext(TenantContext);

  return (
    <div className="mx-auto w-3/4 rounded bg-white px-4 pb-12 pt-6 shadow-lg ">
      <div className="mx-4 pt-1 text-gray-900 sm:mx-16">
        <h3 className="text-lg font-medium leading-6">
          Contact Us or Schedule Meeting
        </h3>
        <p className="mt-1 pb-4  text-sm font-semibold text-gray-700">
          We're here to help! If you have any questions or concerns you can find
          how to contact us below.
        </p>
        <div className="border-t border-gray-200"></div>
      </div>

      <div className="my-12 text-center ">
        <div className="mx-0 inline-block sm:mx-20">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto h-16 w-16"
            viewBox="0 0 20 20"
            fill={themeContext?.primary}
          >
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
          <h3 className="text-3xl font-bold uppercase text-gray-800">Email</h3>
          <div className="mt-4 w-56">
            You can reach us by email at
            <a
              className="text-gray-600"
              href="mailto:qolplanning@dorvalchorne.com"
            >
              {" "}
              qolplanning@dorvalchorne.com
            </a>
          </div>
        </div>

        <div className="mx-0 mt-12 inline-block sm:mx-20 sm:mt-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto h-16 w-16"
            viewBox="0 0 20 20"
            fill={themeContext?.primary}
          >
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
          <h3 className="text-3xl font-bold uppercase text-gray-800">Call</h3>
          <div className="mt-4 w-56">
            You can reach us by telephone at {telephoneNumber}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
