import { differenceInCalendarYears, parseJSON } from "date-fns";

export function checkConditional(conditional, formValues) {
  if (!conditional.condition) {
    return false;
  }

  const checks = conditional.condition.match(
    /\[([A-Za-z0-9\.]+)\] ([!=<>a-zA-Z]+) ([A-Za-z0-9\s\-',\[\]]+)/,
  );

  let literal = checks[3].replace(/['"\[\]]/g, "");
  let operator = checks[2];
  let fieldName = checks[1];
  let formName = "";
  let value = "";
  let result = false;

  if (fieldName.includes(".")) {
    // period in fieldName indicates subform value conditional
    [formName, fieldName] = fieldName.split(".");
    let subFormValues = formValues[formName];

    subFormValues.forEach((line) => {
      if (!result) {
        value = line[fieldName];
        result = compare(value, operator, literal);
      }
    });
  } else {
    // default conditional check
    value = formValues[fieldName];
    result = compare(value, operator, literal);
  }

  return result;
}

export function compare(value, operator, literal) {
  if (operator == "==") {
    return value == literal;
  } else if (operator == "!=") {
    return value != literal;
  } else if (operator == "<") {
    return parseFloat(value) < parseFloat(literal);
  } else if (operator == "<=") {
    return parseFloat(value) <= parseFloat(literal);
  } else if (operator == ">") {
    return parseFloat(value) > parseFloat(literal);
  } else if (operator == ">=") {
    return parseFloat(value) >= parseFloat(literal);
  } else if (operator == "is") {
    if (literal == "empty") {
      if (!value) {
        return true;
      }

      return value.length < 1;
    }
    if (literal == "true") {
      return !!value;
    }
  } else if (operator == "isNot") {
    if (literal == "empty") {
      if (!value) {
        return false;
      }

      return value.length > 0;
    }
    if (literal == "true") {
      return !value;
    }
  } else if (operator == "in") {
    // requires no spaces between comma seperated list in condition text
    //   e.g. ["No","spaces","after","commas"]
    let literalArray = literal.split(",");
    return literalArray.includes(value);
  } else if (operator == "olderThan") {
    let date = parseJSON(value);
    return differenceInCalendarYears(new Date(), date) > parseInt(literal);
  }

  return false;
}
