function GenericErrorPage(): JSX.Element {
  return (
    <div className="flex h-screen items-center justify-center">
      <div id="error-page">
        <h1 className="text-2xl font-bold text-black lg:text-6xl">
          Oops, an unrecoverable error was encountered.
        </h1>
        <p className="text-xl text-black">
          This incident has been logged for our development team to review.
        </p>
        <p className="text-xl text-black">
          Please click the button below to return home.
        </p>
        <div className="mt-4">
          <a
            className="inline-flex justify-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm"
            href={"/"}
          >
            Home
          </a>
        </div>
      </div>
    </div>
  );
}

export default GenericErrorPage;
