import Modal from "./widgets/Modal";
import React from "react";

function ApiErrorModal({ showErrorModal, setShowErrorModal, message }: any) {
  const defaultErrorMessage = "A service error was encountered.";

  return (
    <Modal show={showErrorModal} handleClose={() => setShowErrorModal(false)}>
      <div className="bg-white p-6 pb-12">
        <p className="px-12 pt-4 font-medium text-gray-700">
          {message || defaultErrorMessage}
        </p>
        <p className="px-12 pt-4 font-medium text-gray-700">
          This incident has been logged for our development team to review.
          Please let us know if the issue continues to occur.
        </p>
      </div>
    </Modal>
  );
}

export default ApiErrorModal;
