import { useContext } from "react";
import { getSectionName } from "../modules/business";
import { ThemeContext } from "styled-components";

export default function FormsNav({
  currentSection,
  updateCurrentSection,
}: any) {
  const themeContext = useContext(ThemeContext);

  const sections = ["profile", "finances", "housing", "accounts", "debts"];

  return (
    <div className="nav-div pb-8">
      <h3 className="mt-6 text-lg font-medium leading-6 text-gray-900">
        Forms
      </h3>
      <ul className="pt-2">
        {sections.map((section) => {
          return (
            <li className="inline" key={`${section}_nav`}>
              <div
                onClick={() => updateCurrentSection(section)}
                style={{ height: "44px" }}
                className="flex transform cursor-pointer flex-row items-center text-gray-500 transition-transform duration-200 ease-in hover:translate-x-2 hover:text-gray-800"
              >
                <span className="inline-flex h-8 w-8 items-center justify-center text-lg text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                    viewBox="0 0 20 20"
                    fill={
                      currentSection == section
                        ? themeContext?.primary
                        : "currentColor"
                    }
                  >
                    <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
                  </svg>
                </span>
                <span className="ml-2 font-medium text-gray-900">
                  {getSectionName(section)}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
