import { FormDataType } from "../lib/types";
import {
  cleanNumber,
  getListFieldTotal,
  listHasRecords,
} from "./modules/business";
import { useMemo } from "react";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

function DiagramStudentLoanSection({
  formValues,
}: {
  formValues: FormDataType;
}): JSX.Element {
  const completeLoanTotal = useMemo<number | undefined>(() => {
    return (
      (formValues.debtsStudentLoan?.reduce((total, debt) => {
        return total + parseFloat(cleanNumber(debt.studentLoanBalance || 0));
      }, 0) || 0) +
      (formValues.debtsFederalStudentLoan?.reduce((total, debt) => {
        return total + parseFloat(cleanNumber(debt.studentLoanBalance || 0));
      }, 0) || 0) +
      (formValues.debtsPrivateStudentLoan?.reduce((total, debt) => {
        return total + parseFloat(cleanNumber(debt.studentLoanBalance || 0));
      }, 0) || 0)
    );
  }, [formValues]);

  return (
    <>
      {completeLoanTotal !== undefined && completeLoanTotal !== 0 && (
        <p className="mt-1 text-sm">
          <data>
            Student Loans - {formatter.format(Math.round(completeLoanTotal))}
          </data>
        </p>
      )}
    </>
  );
}

export default DiagramStudentLoanSection;
