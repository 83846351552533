import useQueryData from "../lib/use-query-data";
import { CashFlowType } from "../lib/types";

function ContributionRecommendations() {
  const {
    queryData: { results: cashFlow },
  } = useQueryData<CashFlowType>("dc_hierarchy_cash_flow");
  return (
    <div className="mb-16 mt-24">
      <div className="pt-1 text-gray-900">
        <h3 className="text-lg font-medium leading-6">
          Contribution Observations
        </h3>
        <p className="mt-1 pb-4  text-sm font-semibold text-gray-700">
          {cashFlow?.contributionText}
        </p>
        <div className="border-t border-gray-200"></div>
      </div>

      {!!cashFlow?.contributionActionItems && (
        <div className="ml-4 mt-4 pt-1 text-gray-900">
          <h3 className="text-md font-medium leading-6">We Recommend</h3>
          <p className="mt-2 list-disc pb-4 text-sm font-semibold text-gray-700">
            {cashFlow?.contributionActionItems}
          </p>
          {cashFlow?.keepInMind && <p className="mt-2 list-disc pb-4 text-sm font-semibold text-gray-700">
            {cashFlow?.keepInMind}
          </p>}
        </div>
      )}
    </div>
  );
}

export default ContributionRecommendations;
