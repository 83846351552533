import React from "react";
import Multiselect from "multiselect-react-dropdown";

const MultiSelectField = React.forwardRef(
  (props: any, ref: React.ForwardedRef<Multiselect>) => {
    let width = props.width || "full";
    let margin = props.margin || "";
    // let borderColor = "border-gray-300";
    // let errorMessage = "";

    // if (props.errors) {
    //   let error = props.errors[props.slug];
    //
    //   // only apply errors to field from current index
    //   if (error && (!props.index || error.index == props.index)) {
    //     errorMessage = error.message;
    //     borderColor = "border-red-500";
    //   }
    // }

    function onSelect(selected: any) {
      props.setValue(props.slug, selected);
    }

    function onRemove(removed: any) {
      props.setValue(props.slug, removed);
    }

    return (
      <div className={`${margin} multiselect`}>
        <label
          htmlFor={props.slug}
          className="block text-sm font-medium text-gray-700"
        >
          {props.name}
        </label>
        <Multiselect
          className={`w-${width} mt-1 block h-10 shadow-sm sm:text-sm ${
            props.errors && props.errors[props.slug] ? "border-red-500" : ""
          } rounded-md`}
          options={props.options}
          onSelect={onSelect}
          onRemove={onRemove}
          displayValue="label"
          id="name"
          avoidHighlightFirstOption={true}
          selectedValues={props.selectedValues}
          style={{
            chips: { background: "primary" },
            inputField: { margin: "0" },
          }}
          ref={ref}
        />
        {props.errors && props.errors[props.slug] && (
          <p className="text-xs text-red-500">
            {props.errors[props.slug]?.message}
          </p>
        )}
        {props.caption && <p className="ml-1 mt-1 text-xs">{props.caption}</p>}
      </div>
    );
  },
);

export default MultiSelectField;
