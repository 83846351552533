import TargetDateRecommendations from "./TargetDateRecommendations";
import FundRecommendations from "./FundRecommendations";
import React from "react";
import StyledBounceLoader from "./StyledBounceLoader";
import useQueryData from "../lib/use-query-data";
import { FundMessagesType } from "../lib/types";

function DashboardRecommendation() {
  const {
    queryData: { messages: fundMessages },
  } = useQueryData<any, FundMessagesType>("dc_fund_recommendations");

  if (!fundMessages) {
    return (
      <div className="mx-auto my-16 max-w-5xl">
        <div className="flex items-center justify-center">
          <StyledBounceLoader />
        </div>
      </div>
    );
  }

  return (
    fundMessages &&
    (fundMessages.useTargetDate == "true" ? (
      <TargetDateRecommendations />
    ) : (
      <FundRecommendations />
    ))
  );
}

export default DashboardRecommendation;
