import PhoneInput from "react-phone-number-input";

import "react-phone-number-input/style.css";

type PhoneFieldProps = {
  errors: any;
  index?: number;
  slug: string;
  name: string;
  setValue: any;
};

function PhoneField({ errors, index, slug, name, setValue }: PhoneFieldProps) {
  function onChange(value: any) {
    setValue(slug, value, index);
  }

  return (
    <>
      <label htmlFor={slug} className="block text-sm font-medium text-gray-700">
        {name}
      </label>
      <PhoneInput
        id={slug}
        name={slug}
        onChange={onChange}
        className={`mt-1 block w-full shadow-sm focus:border-green focus:ring-1 focus:ring-green sm:text-sm ${
          errors && errors[slug] ? "border-red-500" : ""
        } rounded-md`}
      />
      {errors && errors[slug] && (
        <p className="text-xs text-red-500">{errors[slug]?.message}</p>
      )}
    </>
  );
}

export default PhoneField;
