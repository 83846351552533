import TextField from "../fields/TextField";
import SelectField from "../fields/SelectField";

function PensionPlanForm({ value, setFormValues, errors }) {
  // initialize pensionPlans object if it doesn't exist
  if (!value.pensionPlans || Object.keys(value.pensionPlans).length < 1) {
    value.pensionPlans = [];

    value.pensionPlans[0] = {
      pensionPlanName: "",
      pensionPlanOwner: "",
      pensionPlanDistrubutionType: "",
      pensionPlanDistributionAmount: "",
      pensionPlanDistributionYear: "",
    };
  }

  function handleChange(event) {
    const target = event.target;
    const index = target.getAttribute("index");

    const pensionPlans = value.pensionPlans;
    pensionPlans[index] = {
      ...value.pensionPlans[index],
      [target.name]: target.value,
    };
    setFormValues({ ...value, pensionPlans: [...pensionPlans] });
  }

  function isLastRow(i) {
    return i == Object.keys(value.pensionPlans).length - 1;
  }

  function isFirstRow(i) {
    return i == 0;
  }

  function addRow() {
    value.pensionPlans[Object.keys(value.pensionPlans).length] = {
      pensionPlanName: "",
      pensionPlanOwner: "",
      pensionPlanDistrubutionType: "",
      pensionPlanDistributionAmount: "",
      pensionPlanDistributionYear: "",
    };

    value.pensionPlans = value.pensionPlans.filter((val) => val);

    setFormValues({ ...value });
  }

  function removeRow(i) {
    delete value.pensionPlans[i];

    // reindex array
    value.pensionPlans = value.pensionPlans.filter((val) => val);

    setFormValues({ ...value });
  }

  function clearRow() {
    // create shallow copy so react knows state has changed needs rerender
    let pensionPlans = [...value.pensionPlans];

    pensionPlans[0] = {
      pensionPlanName: "",
      pensionPlanOwner: "",
      pensionPlanDistrubutionType: "",
      pensionPlanDistributionAmount: "",
      pensionPlanDistributionYear: "",
    };
    value.pensionPlans = pensionPlans;

    setFormValues({ ...value });
  }

  return (
    <div>
      {value.pensionPlans.map((plan, i) => {
        return (
          <div key={i} className="mr-6 mt-2 grid grid-cols-10 gap-2 lg:gap-4">
            <div className="col-span-10 lg:col-span-3">
              <TextField
                name="Plan Name"
                slug="pensionPlanName"
                index={i}
                value={plan}
                handleChange={handleChange}
                errors={errors}
              />
            </div>

            <div className="col-span-10 lg:col-span-1">
              <SelectField
                name="Plan Owner"
                slug="pensionPlanOwner"
                index={i}
                value={plan}
                handleChange={handleChange}
                errors={errors}
                options={[
                  { name: "Me", label: "Me" },
                  { name: "Spouse", label: "Spouse" },
                ]}
              />
            </div>

            <div className="col-span-10 lg:col-span-2">
              <SelectField
                name="Distribution Type"
                slug="pensionPlanDistrubutionType"
                index={i}
                value={plan}
                handleChange={handleChange}
                errors={errors}
                options={[
                  { name: "Monthly Payments", label: "Monthly Payments" },
                  { name: "Lump Sum", label: "Lump Sum" },
                ]}
              />
            </div>

            <div className="col-span-10 lg:col-span-2">
              <TextField
                name="Distribution Amount"
                slug="pensionPlanDistributionAmount"
                index={i}
                value={plan}
                handleChange={handleChange}
                errors={errors}
              />
            </div>

            <div className="relative col-span-10 h-12 lg:col-span-2">
              <TextField
                name="Distribution Year"
                slug="pensionPlanDistributionYear"
                index={i}
                value={plan}
                handleChange={handleChange}
                errors={errors}
              />

              <button
                type="button"
                onClick={() => (isFirstRow(i) ? clearRow() : removeRow(i))}
                className={`absolute inset-y-6 right-0 -mr-9 mt-1 h-8 w-7 justify-center rounded-md bg-primary-light pl-1 text-white`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2.5}
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>
            </div>

            {isLastRow(i) && (
              <div className="col-span-12 lg:col-span-3">
                <button
                  type="button"
                  onClick={addRow}
                  className={`inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="ml-2">Add another plan</span>
                </button>
              </div>
            )}

            {!isLastRow(i) && (
              <div className="col-span-12 mb-6 mt-12 block border-t border-gray-200 lg:hidden"></div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default PensionPlanForm;
