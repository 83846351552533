import { differenceInCalendarYears, format, parseJSON } from "date-fns";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export function listHasRecords(list, fields) {
  if (!list || (Array.isArray(list) && list.length === 0)) {
    return false;
  }

  if (!fields || (Array.isArray(fields) && fields.length === 0)) {
    return false;
  }

  return !fields.some((field) => !list[0][field]);
}

export function getListFieldTotal(list, field) {
  if (!list || !Array.isArray(list)) {
    return 0;
  }

  let listTotal = list.reduce((total, debt) => {
    return parseFloat(total) + parseFloat(cleanNumber(debt[field] || 0));
  }, 0);

  return formatter.format(Math.round(listTotal));
}

export function isReadyForAnalysis(formValues, fieldsRequiredForAnalysis) {
  if (!formValues || Object.keys(formValues).length == 0) {
    return false;
  }

  let isReadyForAnalysis = true;

  fieldsRequiredForAnalysis.forEach((field) => {
    if (Array.isArray(field)) {
      let hasOneSubfield = false;

      field.forEach((subfield) => {
        if (subfield in formValues) {
          hasOneSubfield = true;
        }
      });

      if (!hasOneSubfield) {
        isReadyForAnalysis = false;
      }
    } else if (!(field in formValues)) {
      isReadyForAnalysis = false;
    }
  });

  return isReadyForAnalysis;
}

export function printBirthday(birthdateString) {
  if (!birthdateString) {
    return "";
  }

  const birthdate = parseJSON(birthdateString);
  return `${format(birthdate, "P")} (${getAge(birthdateString)} years)`;
}

export function getAge(birthdateString) {
  const birthDate = parseJSON(birthdateString);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }

  return age;
}

export function getEmergencyReserves(
  checkingBalance,
  savingsBalance,
  otherAccounts,
) {
  return formatter.format(
    Math.round(
      parseFloat(cleanNumber(checkingBalance || 0)) +
      parseFloat(cleanNumber(savingsBalance || 0)) +
      parseFloat(cleanNumber(otherAccounts || 0)),
    ),
  );
}

export function getRetirementAccountType(employment) {
  if (["Allina", "NorthMemorial"].includes(employment)) {
    return "401k";
  }

  if (
    [
      "Childrens Hospital",
      "Essentia Health",
      "Fairview",
      "Health Partners Park Nicollet",
      "HCMC",
    ].includes(employment)
  ) {
    return "403b";
  }

  return "401k/403b";
}

export function getRetirementContribution(preTax, postTax, contribution = "0") {
  return Math.round(
    parseFloat(preTax || 0) +
    parseFloat(postTax || 0) +
    parseFloat(contribution || 0),
  );
}

export function getTopInvestmentAccount(investmentAccounts, owner) {
  let topAccount = null;

  if (investmentAccounts && investmentAccounts.length > 0) {
    investmentAccounts.forEach((account) => {
      if (!topAccount && account.investmentAccountOwner == owner) {
        topAccount = account;
      }
    });
  }

  return topAccount;
}

export function getInvestmentAssets(investmentAccounts, owner) {
  let totalAssets = 0;

  if (investmentAccounts && investmentAccounts.length > 0) {
    investmentAccounts.forEach((account) => {
      if (
        ["Taxable Investment", "Other"].includes(
          account.investmentAccountType,
        ) &&
        account.investmentAccountOwner == owner
      ) {
        totalAssets += cleanNumber(account.investmentBalance);
      }
    });
  }

  return Math.round(totalAssets);
}

export function getSectionName(section) {
  const sectionNames = {
    introduction: "Introduction",
    "mna-pension": "Pension",
    "student-loans": "Student Loans",
    "social-security": "Social Security",
    investments: "Investments",
    "emergency-reserves": "Emergency Reserves",
    debts: "Debts",
    housing: "Housing",
    goals: "Goals",
    advice: "Advice",
    profile: "Profile",
    finances: "Finances",
    "cash-flow": "Cash Flow",
    accounts: "Investments",
  };

  return sectionNames[section] || "Unknown";
}

export function cleanNumber(value) {
  return value ? String(value)?.replaceAll(",", "") || "0" : "0";
}

export function formatDollars(value) {
  if (!value) {
    return "$0";
  }

  let cleanedValue = value;

  if (typeof value === "string") {
    cleanedValue = cleanedValue.replace(/\,/g, "");
  }

  return formatter.format(parseFloat(cleanedValue));
}

export function roundDollars(value) {
  if (!value) {
    return "$0";
  }

  let cleanedValue = value;

  if (typeof value === "string") {
    cleanedValue = cleanedValue.replace(/\,/g, "");
  }

  return formatter.format(Math.round(parseFloat(cleanedValue)));
}
