import React, { useEffect, useState, useRef, useContext } from "react";
import ReactTooltip from "react-tooltip";

import Modal from "./widgets/Modal";
import "./CashFlow.css";
import StyledBounceLoader from "./StyledBounceLoader";
import useQueryData from "../lib/use-query-data";
import TenantContext from "../context/tenant";

export default function CashFlow() {
  const { scheduleMeetingLink } = useContext(TenantContext);

  const [currentMedia, setCurrentMedia] = useState("");
  const [showMediaModal, setShowMediaModal] = useState(false);

  const {
    queryData: { results: cashFlow },
  } = useQueryData("dc_hierarchy_cash_flow");

  const sectionVideos: any = {
    increaseRetirementContribution: "https://youtube.com/embed/AlhnlW55Vjw",
    establishSlushFund: "https://www.youtube.com/embed/-yS27XqhzLs",
    maxIRARothContribution: "https://www.youtube.com/embed/-yS27XqhzLs",
    accumulateEmergencyReserves: "https://www.youtube.com/embed/-dixCR1_icQ",
    payoffHighInterestDebt: "https://www.youtube.com/embed/PlFOkAlPLHg",
    contributeEmployerMatch: "https://www.youtube.com/embed/_ybb941ZX5I",
  };

  // TODO Unravel this whole thing
  function getSectionColor(name: string) {
    if (cashFlow[name] === "Future") {
      return "bg-blue-light";
    }

    if (cashFlow[name] === "Completed") {
      return "bg-green";
    }

    return "bg-blue";
  }

  function handleVideoClick(section: string) {
    let video = sectionVideos[section];
    setCurrentMedia(video);
    setShowMediaModal(true);
  }

  function closeModal() {
    setShowMediaModal(false);
    setCurrentMedia("");
  }

  const [style, setStyle] = useState({});

  const triangleRef = useRef({});

  useEffect(() => {
    // @ts-ignore
    const leftMargin = triangleRef.current.offsetLeft;
    const windowWidth = window.innerWidth;
    // @ts-ignore
    const completeWidth = triangleRef.current.offsetWidth;

    const marginLeft = (completeWidth - windowWidth) / 2 + leftMargin;

    if (completeWidth > windowWidth) {
      setStyle({ marginLeft: -marginLeft });
    }
  }, []);

  if (!cashFlow) {
    return (
      <div className="mx-auto my-16 max-w-5xl">
        <div className="flex items-center justify-center">
          <StyledBounceLoader />
        </div>
      </div>
    );
  }

  return (
    cashFlow && (
      <div>
        <div className="modals">
          <Modal show={showMediaModal} handleClose={closeModal}>
            <div className="bg-white">
              {currentMedia && (
                <iframe
                  width={Math.min(window.innerWidth - 60, 854)}
                  height={Math.min((window.innerWidth - 60) * 0.54, 480)}
                  src={`${currentMedia}?autoplay=1`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </div>
          </Modal>
        </div>

        <div className="mx-4 mb-4 sm:mx-16">
          <div className="pt-1 text-gray-900">
            <h3 className="text-lg font-medium leading-6">
              Hierarchy of Cash Flow
            </h3>
            <p className="mt-1 pb-4  text-sm font-semibold text-gray-700">
              Time to move to fill in some gaps on the hierarchy of cash flow.
            </p>
            <div className="border-t border-gray-200"></div>
          </div>

          <div className="flex flex-wrap">
            <div className="mb-4 mt-8 w-full lg:mb-16 lg:w-2/3">
              <div
                // @ts-ignore
                ref={triangleRef}
                style={style}
                className="triangle font-semibold"
              >
                <div
                  // @ts-ignore
                  slug="increaseRetirementContribution"
                  className={
                    getSectionColor("increaseRetirementContribution") + " top"
                  }
                  data-tip
                  data-for="increaseRetirementContributionTooltip"
                >
                  <span
                    // @ts-ignore
                    slug="increaseRetirementContribution"
                    className="center mx-auto w-24 uppercase"
                  >
                    Increase 401K/403B contribution
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() =>
                        handleVideoClick("increaseRetirementContribution")
                      }
                      className="text-grey absolute -right-1 top-4 h-4 w-4 cursor-pointer"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div
                  // @ts-ignore
                  slug="establishSlushFund"
                  className={
                    getSectionColor("establishSlushFund") + " cursor-pointer"
                  }
                  data-tip
                  data-for="establishSlushFundTooltip"
                >
                  <span
                    // @ts-ignore
                    slug="establishSlushFund"
                    className="center mx-auto uppercase"
                  >
                    Establish slush fund
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleVideoClick("establishSlushFund")}
                      className="text-grey absolute -right-5 top-0 h-4 w-4 cursor-pointer"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </span>
                </div>

                <div
                  // @ts-ignore
                  slug="maxIRARothContribution"
                  className={getSectionColor("maxIRARothContribution")}
                  data-tip
                  data-for="maxIRARothContributionTooltip"
                >
                  <span
                    // @ts-ignore
                    slug="maxIRARothContribution"
                    className="center mx-auto uppercase"
                  >
                    Contribute to a Roth IRA
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleVideoClick("maxIRARothContribution")}
                      className="text-grey absolute -right-5 top-0 h-4 w-4 cursor-pointer"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </span>
                </div>

                <div
                  // @ts-ignore
                  slug="accumulateEmergencyReserves"
                  className={getSectionColor("accumulateEmergencyReserves")}
                  data-tip
                  data-for="accumulateEmergencyReservesTooltip"
                >
                  <span
                    // @ts-ignore
                    slug="accumulateEmergencyReserves"
                    className="center mx-auto uppercase"
                  >
                    Accumulate emergency reserves
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() =>
                        handleVideoClick("accumulateEmergencyReserves")
                      }
                      className="text-grey absolute -right-5 top-0 h-4 w-4 cursor-pointer"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </span>
                </div>

                <div
                  // @ts-ignore
                  slug="payoffHighInterestDebt"
                  className={getSectionColor("payoffHighInterestDebt")}
                  data-tip
                  data-for="payoffHighInterestDebtTooltip"
                >
                  <span
                    // @ts-ignore
                    slug="payoffHighInterestDebt"
                    className="center mx-auto uppercase"
                  >
                    Payoff high interest debt (e.g. credit cards)
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleVideoClick("payoffHighInterestDebt")}
                      className="text-grey absolute -right-5 top-0 h-4 w-4 cursor-pointer"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </span>
                </div>

                <div
                  // @ts-ignore
                  slug="contributeEmployerMatch"
                  className={getSectionColor("contributeEmployerMatch")}
                  data-tip
                  data-for="contributeEmployerMatchTooltip"
                >
                  <span
                    // @ts-ignore
                    slug="contributeEmployerMatch"
                    className="center mx-auto uppercase"
                  >
                    Contribute enough to get employer match (if available)
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() =>
                        handleVideoClick("contributeEmployerMatch")
                      }
                      className="text-grey absolute right-2 top-0 h-4 w-4 cursor-pointer"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>

              <ReactTooltip
                id="increaseRetirementContributionTooltip"
                place="top"
                effect="solid"
                multiline={true}
              >
                Congratulations! You have reached the cash flow summit! <br />
                If all your levels are green and you still have available <br />
                cash flow to save and invest, then we return back to your <br />
                employer sponsored retirement plan and increase contributions.{" "}
                <br />
                Many people skip levels to reach the peak…don't take short{" "}
                <br />
                cuts in balancing your available resources! Check off all the{" "}
                <br />
                levels before increasing 401k or 403b contributions.
              </ReactTooltip>
              <ReactTooltip
                id="establishSlushFundTooltip"
                place="top"
                effect="solid"
              >
                We have all kinds of goals beyond just retirement. How do you{" "}
                <br />
                save for something like buying a property, starting a business,{" "}
                <br />
                or other intermediate term goals when you aren't sure how much{" "}
                <br />
                it will cost or when you will need it? We recommend setting{" "}
                <br />
                up a non-qualified investment account that we call a <br />
                “slush fund.” This type of account can serve as an extension{" "}
                <br />
                of your emergency reserves while being available to help <br />
                accomplish a variety of important life goals!
              </ReactTooltip>
              <ReactTooltip
                id="maxIRARothContributionTooltip"
                place="top"
                effect="solid"
              >
                Now we get serious about investing for the future! A Roth IRA{" "}
                <br />
                is the Swiss army knife of investment vehicles because it <br />
                offers a lot of potential flexibility and tax diversification.{" "}
                <br />
                A Roth IRA offers potentially tax-free growth and the <br />
                contributions are available at any time, for any reason, <br />
                without tax or penalty. Contribution limits change annually,{" "}
                <br />
                so double check before you contribute. Also, make sure your{" "}
                <br />
                adjusted gross income falls within the limits to be able to{" "}
                <br />
                contribute to a Roth IRA. If you make too much, check to see{" "}
                <br />
                if your employer sponsored plan offers a Roth component…the{" "}
                <br />
                employer Roth option is less flexible, but has no income <br />
                restrictions.
              </ReactTooltip>
              <ReactTooltip
                id="accumulateEmergencyReservesTooltip"
                place="top"
                effect="solid"
              >
                Now that you aren't paying on high interest debt, let's keep it{" "}
                <br />
                that way! One of the best ways to stay out of debt is to be{" "}
                <br />
                your own bank. That's right, make a deposit to the bank of YOU{" "}
                <br />
                every month! Accumulating emergency reserves promotes a sense{" "}
                <br />
                of security because you will be prepared for unforeseen things{" "}
                <br />
                such as car troubles, home repairs, or even a strike. Turn to{" "}
                <br />
                your reserves, not your credit card.
              </ReactTooltip>
              <ReactTooltip
                id="payoffHighInterestDebtTooltip"
                place="top"
                effect="solid"
              >
                This is most commonly consumer debt, such as credit cards, or
                car <br />
                loans, but can sometimes be student loans as well. Paying off
                debt <br />
                is a great goal because there is a defined finish line. Paying
                off <br />
                a debt is empowering! It immediately frees up cash flow that can
                be <br />
                used for other priorities and helps create a sense of freedom.
              </ReactTooltip>
              <ReactTooltip
                id="contributeEmployerMatchTooltip"
                place="top"
                effect="solid"
              >
                We always want you to get every free dollar you can from your
                employer! <br />
                Contributing enough to get your full matching funds is always
                your <br />
                first priority. If your employer doesn't offer a match, you may
                want <br />
                to focus on the next step up the hierarchy pyramid.
              </ReactTooltip>

              <div className="mt-12">
                <p className="px-1 text-sm">
                  If you need further advice beyond what the app provides, we
                  encourage you to
                  <a
                    href={scheduleMeetingLink}
                    target="_blank"
                    className="mx-1 underline hover:no-underline"
                  >
                    click here
                  </a>
                  to setup a meeting with one of our CFP® Professionals. Or if
                  you have questions,
                  <a
                    href="mailto:QoLPlanning@dorvalchorne.com"
                    className="mx-1 underline hover:no-underline"
                  >
                    click here
                  </a>
                  to send us an email.
                </p>
              </div>
            </div>

            <div className="legend ml-16 w-72">
              <div className="mt-6 pt-1 text-gray-900">
                <div className="mt-2 flex items-center">
                  <div className={`h-8 w-8 bg-green`}></div>
                  <div className="ml-2 text-sm font-medium leading-6">
                    Completed Item
                  </div>
                </div>
                <div className="mt-2 flex items-center">
                  <div className={`h-8 w-8 bg-blue`}></div>
                  <div className="ml-2 text-sm font-medium leading-6">
                    Current Action Item
                  </div>
                </div>
                <div className="mt-2 flex items-center">
                  <div className={`h-8 w-8 bg-blue-light`}></div>
                  <div className="ml-2 text-sm font-medium leading-6">
                    Future Item
                  </div>
                </div>
              </div>

              <div className="mt-12">
                <div className="text-md mb-1 font-medium leading-6">
                  Your next steps
                </div>
                <div className="border-t border-gray-200 pb-4"></div>
                <p className="px-1 text-sm">{cashFlow.paragraphText}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
