import { useState, useContext } from "react";
import { BounceLoader } from "react-spinners";

import { validate } from "../modules/validate";
import formValidations from "../validations/formvalidations";
import Modal from "../widgets/Modal";
import FormsNav from "../navs/FormsNav.tsx";
import ProfileForm from "../forms/ProfileForm";
import FinanceForm from "../forms/FinanceForm";
import HousingForm from "../forms/HousingForm";
import AdditionalHousingForm from "../forms/AdditionalHousingForm";
import InvestmentAccountForm from "../forms/InvestmentAccountForm";
import PensionPlanForm from "../forms/PensionPlanForm";
import DebtsCreditCardForm from "../forms/DebtsCreditCardForm";
import DebtsAutoLoanForm from "../forms/DebtsAutoLoanForm";
import DebtsStudentLoanForm from "../forms/DebtsStudentLoanForm";
import DebtsFederalStudentLoanForm from "../forms/DebtsFederalStudentLoanForm";
import DebtsPrivateStudentLoanForm from "../forms/DebtsPrivateStudentLoanForm";
import DebtsOtherLoanForm from "../forms/DebtsOtherLoanForm";
import TenantContext from "../../context/tenant";
import useFormData from "../../lib/use-form-data";
import StyledBounceLoader from "../StyledBounceLoader";

function Forms() {
  const { id } = useContext(TenantContext);
  const [currentSection, setCurrentSection] = useState("profile");
  const [errors, setErrors] = useState({});
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const {
    formData: formValues,
    mutateFormData: setFormValues,
    trigger,
    isMutating,
  } = useFormData();

  function setValue(name, value) {
    setFormValues(
      {
        ...formValues,
        [name]: value,
      },
      { revalidate: false },
    );
  }

  function handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setValue(name, value);
  }

  async function handleSubmit(
    fieldValidations,
    subformValidations = {},
    values = null,
    sections = null,
  ) {
    if (!validate(fieldValidations, formValues, setErrors)) {
      return false;
    }

    if (!validateSubforms(subformValidations)) {
      return false;
    }

    await trigger(formValues);
    setShowSaveModal(true);
  }

  function handleSubmitEvent(event) {
    event.preventDefault();
    let form = event.target.name;

    let fieldValidations = formValidations[form];
    let currentFormValues = formValues[form];

    let isValid = true;

    // apply validations to all rows of chat subforms
    currentFormValues.forEach((formRowValues, index) => {
      if (
        isValid &&
        !validate(fieldValidations, formRowValues, setErrors, index)
      ) {
        isValid = false;
      }

      // apply form specific validations
      // TODO: move to it's own method
      if (isValid && form == "investmentAccounts") {
        let bondAllocation = parseFloat(
          formRowValues["investmentBondAllocation"],
        );
        let stockAllocation = parseFloat(
          formRowValues["investmentStockAllocation"],
        );

        if (
          bondAllocation &&
          stockAllocation &&
          bondAllocation + stockAllocation != 100
        ) {
          setErrors({
            investmentBondAllocation: {
              message: "Allocation should total 100%",
              index: index,
            },
            investmentStockAllocation: {
              message: "Allocation should total 100%",
              index: index,
            },
          });
          isValid = false;
        }
      }
    });

    if (!isValid) {
      return;
    }

    handleSubmit({});
  }

  function validateSubforms(subformValidations) {
    let isValid = true;

    Object.keys(subformValidations).forEach((subform) => {
      let subformValues = formValues[subform];
      let subformFieldValidations = subformValidations[subform] || {};

      if (Array.isArray(subformValues)) {
        subformValues.forEach((values, index) => {
          if (
            isValid &&
            !validate(subformFieldValidations, values, setErrors, index)
          ) {
            isValid = false;
          }
        });
      } else {
        if (
          isValid &&
          subformValues &&
          !validate(subformFieldValidations, subformValues, setErrors, 0)
        ) {
          isValid = false;
        }
      }
    });

    return isValid;
  }

  function hideSaveModal() {
    setShowSaveModal(false);
  }

  function hideErrorModal() {
    setShowErrorModal(false);
  }

  function updateCurrentSection(section) {
    setCurrentSection(section);
  }

  if (!formValues) {
    return (
      <div className="mt-24 flex items-center justify-center">
        <StyledBounceLoader />
      </div>
    );
  }

  return (
    <div className="mx-auto block max-w-7xl sm:flex">
      <Modal show={showSaveModal} handleClose={hideSaveModal}>
        <div className="bg-white p-6 pb-12">
          <p className="px-12 pt-4 font-medium text-gray-700">
            Form data successfully saved
          </p>
        </div>
      </Modal>

      <Modal show={showErrorModal} handleClose={hideErrorModal}>
        <div className="bg-white p-6 pb-12">
          <p className="px-12 pt-4 font-medium text-gray-700">
            Error saving form data
          </p>
        </div>
      </Modal>

      <div className="px-8">
        <FormsNav
          currentSection={currentSection}
          updateCurrentSection={updateCurrentSection}
        />
      </div>

      {
        {
          profile: (
            <ProfileForm
              values={formValues}
              setFormValues={(values) => {
                setFormValues(values, { revalidate: false });
              }}
              setValue={setValue}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              errors={errors}
              loading={isMutating}
            />
          ),
          finances: (
            <FinanceForm
              values={formValues}
              setFormValues={(values) => {
                setFormValues(values, { revalidate: false });
              }}
              setValue={setValue}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              errors={errors}
              loading={isMutating}
            />
          ),
          housing: (
            <div>
              <HousingForm
                values={formValues}
                setFormValues={(values) => {
                  setFormValues(values, { revalidate: false });
                }}
                setValue={setValue}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                loading={isMutating}
              />

              <form
                name="pensionPlans"
                onSubmit={handleSubmitEvent}
                className="mt-12 overflow-hidden shadow sm:rounded-md"
              >
                <div className="bg-white p-6 pb-12">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Additional Properties
                    </h3>
                    <div className="mt-2 border-t border-gray-200"></div>
                  </div>
                  <AdditionalHousingForm
                    value={formValues}
                    setFormValues={(values) => {
                      setFormValues(values, { revalidate: false });
                    }}
                    setValue={setValue}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    loading={isMutating}
                  />
                </div>

                <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    disabled={isMutating}
                    className={
                      (isMutating ? "bg-gray-400" : "bg-primary") +
                      " inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm"
                    }
                  >
                    <BounceLoader
                      loading={isMutating}
                      size={20}
                      color="#ffffff"
                    />
                    <span className={isMutating ? "ml-2" : ""}>Save</span>
                  </button>
                </div>
              </form>
            </div>
          ),
          accounts: (
            <div>
              <form
                name="investmentAccounts"
                onSubmit={handleSubmitEvent}
                className="mb-12 overflow-hidden shadow sm:rounded-md"
              >
                <div className="bg-white p-6 pb-12">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Investments
                    </h3>
                    <div className="mt-2 border-t border-gray-200"></div>
                  </div>
                  <InvestmentAccountForm
                    value={formValues}
                    setFormValues={(values) => {
                      setFormValues(values, { revalidate: false });
                    }}
                    setValue={setValue}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    loading={isMutating}
                  />
                </div>

                <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    disabled={isMutating}
                    className={
                      (isMutating ? "bg-gray-400" : "bg-primary") +
                      " inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm"
                    }
                  >
                    <BounceLoader
                      loading={isMutating}
                      size={20}
                      color="#ffffff"
                    />
                    <span className={isMutating ? "ml-2" : ""}>Save</span>
                  </button>
                </div>
              </form>

              <form
                name="pensionPlans"
                onSubmit={handleSubmitEvent}
                className="mb-12 overflow-hidden shadow sm:rounded-md"
              >
                <div className="bg-white p-6 pb-12">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Other Pensions
                    </h3>
                    <div className="mt-2 border-t border-gray-200"></div>
                  </div>
                  <PensionPlanForm
                    value={formValues}
                    setFormValues={(values) => {
                      setFormValues(values, { revalidate: false });
                    }}
                    setValue={setValue}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    loading={isMutating}
                  />
                </div>

                <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    disabled={isMutating}
                    className={
                      (isMutating ? "bg-gray-400" : "bg-primary") +
                      " inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm"
                    }
                  >
                    <BounceLoader
                      loading={isMutating}
                      size={20}
                      color="#ffffff"
                    />
                    <span className={isMutating ? "ml-2" : ""}>Save</span>
                  </button>
                </div>
              </form>
            </div>
          ),
          debts: (
            <div>
              <form
                name="debtsCreditCard"
                onSubmit={handleSubmitEvent}
                className="mb-12 overflow-hidden shadow sm:rounded-md"
              >
                <div className="bg-white p-6 pb-12">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Credit Card Debt
                    </h3>
                    <div className="mt-2 border-t border-gray-200"></div>
                  </div>
                  <DebtsCreditCardForm
                    value={formValues}
                    setFormValues={(values) => {
                      setFormValues(values, { revalidate: false });
                    }}
                    setValue={setValue}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    loading={isMutating}
                  />
                </div>

                <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    disabled={isMutating}
                    className={
                      (isMutating ? "bg-gray-400" : "bg-primary") +
                      " inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm"
                    }
                  >
                    <BounceLoader
                      loading={isMutating}
                      size={20}
                      color="#ffffff"
                    />
                    <span className={isMutating ? "ml-2" : ""}>Save</span>
                  </button>
                </div>
              </form>
              <form
                name="debtsAutoLoans"
                onSubmit={handleSubmitEvent}
                className="mb-12 overflow-hidden shadow sm:rounded-md"
              >
                <div className="bg-white p-6 pb-12">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Auto Loans
                    </h3>
                    <div className="mt-2 border-t border-gray-200"></div>
                  </div>
                  <DebtsAutoLoanForm
                    value={formValues}
                    setFormValues={(values) => {
                      setFormValues(values, { revalidate: false });
                    }}
                    setValue={setValue}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    loading={isMutating}
                  />
                </div>

                <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    disabled={isMutating}
                    className={
                      (isMutating ? "bg-gray-400" : "bg-primary") +
                      " inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm"
                    }
                  >
                    <BounceLoader
                      loading={isMutating}
                      size={20}
                      color="#ffffff"
                    />
                    <span className={isMutating ? "ml-2" : ""}>Save</span>
                  </button>
                </div>
              </form>
              {id === "1" && (
                <form
                  name="debtsStudentLoan"
                  onSubmit={handleSubmitEvent}
                  className="mb-12 overflow-hidden shadow sm:rounded-md"
                >
                  <div className="bg-white p-6 pb-12">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Student Loans
                      </h3>
                      <div className="mt-2 border-t border-gray-200"></div>
                    </div>
                    <DebtsStudentLoanForm
                      value={formValues}
                      setFormValues={(values) => {
                        setFormValues(values, { revalidate: false });
                      }}
                      setValue={setValue}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      loading={isMutating}
                    />
                  </div>

                  <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      disabled={isMutating}
                      className={
                        (isMutating ? "bg-gray-400" : "bg-primary") +
                        " inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm"
                      }
                    >
                      <BounceLoader
                        loading={isMutating}
                        size={20}
                        color="#ffffff"
                      />
                      <span className={isMutating ? "ml-2" : ""}>Save</span>
                    </button>
                  </div>
                </form>
              )}
              {id === "2" && (
                <form
                  name="debtsFederalStudentLoan"
                  onSubmit={handleSubmitEvent}
                  className="mb-12 overflow-hidden shadow sm:rounded-md"
                >
                  <div className="bg-white p-6 pb-12">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Federal Student Loans
                      </h3>
                      <div className="mt-2 border-t border-gray-200"></div>
                    </div>
                    <DebtsFederalStudentLoanForm
                      value={formValues}
                      setFormValues={(values) => {
                        setFormValues(values, { revalidate: false });
                      }}
                      setValue={setValue}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      loading={isMutating}
                    />
                  </div>

                  <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      disabled={isMutating}
                      className={
                        (isMutating ? "bg-gray-400" : "bg-primary") +
                        " inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm"
                      }
                    >
                      <BounceLoader
                        loading={isMutating}
                        size={20}
                        color="#ffffff"
                      />
                      <span className={isMutating ? "ml-2" : ""}>Save</span>
                    </button>
                  </div>
                </form>
              )}
              {id === "2" && (
                <form
                  name="debtsPrivateStudentLoan"
                  onSubmit={handleSubmitEvent}
                  className="mb-12 overflow-hidden shadow sm:rounded-md"
                >
                  <div className="bg-white p-6 pb-12">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Private Student Loans
                      </h3>
                      <div className="mt-2 border-t border-gray-200"></div>
                    </div>
                    <DebtsPrivateStudentLoanForm
                      value={formValues}
                      setFormValues={(values) => {
                        setFormValues(values, { revalidate: false });
                      }}
                      setValue={setValue}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      loading={isMutating}
                    />
                  </div>

                  <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      disabled={isMutating}
                      className={
                        (isMutating ? "bg-gray-400" : "bg-primary") +
                        " inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm"
                      }
                    >
                      <BounceLoader
                        loading={isMutating}
                        size={20}
                        color="#ffffff"
                      />
                      <span className={isMutating ? "ml-2" : ""}>Save</span>
                    </button>
                  </div>
                </form>
              )}
              <form
                name="debtsOtherLoan"
                onSubmit={handleSubmitEvent}
                className="mb-12 overflow-hidden shadow sm:rounded-md"
              >
                <div className="bg-white p-6 pb-12">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Other Debt
                    </h3>
                    <div className="mt-2 border-t border-gray-200"></div>
                  </div>
                  <DebtsOtherLoanForm
                    value={formValues}
                    setFormValues={(values) => {
                      setFormValues(values, { revalidate: false });
                    }}
                    setValue={setValue}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    loading={isMutating}
                  />
                </div>

                <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    disabled={isMutating}
                    className={
                      (isMutating ? "bg-gray-400" : "bg-primary") +
                      " inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm"
                    }
                  >
                    <BounceLoader
                      loading={isMutating}
                      size={20}
                      color="#ffffff"
                    />
                    <span className={isMutating ? "ml-2" : ""}>Save</span>
                  </button>
                </div>
              </form>
            </div>
          ),
        }[currentSection]
      }
    </div>
  );
}

export default Forms;
