const formRequirements: { [key: string]: any } = {
  debtsCreditCard: {
    creditCardName: "",
    creditCardBalance: "",
  },
  debtsAutoLoans: {
    autoLoanName: "",
    autoLoanBalance: "",
  },
  debtsStudentLoan: {
    studentLoanName: "",
    studentLoanBalance: "",
  },
  debtsOtherLoan: {
    otherLoanName: "",
    otherLoanBalance: "",
  },
  investmentAccounts: {
    investmentAccountName: "",
    investmentBalance: "",
  },
  majorExpenses: {
    majorExpenseDescription: "",
    majorExpenseAmount: "",
  },
  pensionPlans: {
    pensionPlanName: "",
    pensionPlanDistributionAmount: "",
  },
};

export default formRequirements;
