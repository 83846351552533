import { memo, useContext } from "react";
import { Link, Navigate } from "react-router-dom";

import { getSectionName } from "../modules/business";
import { ThemeContext } from "styled-components";
import TenantContext from "../../context/tenant";
import AdminContext from "../../context/admin";

function SectionNav({ currentSection, restartSection }: any): JSX.Element {
  const themeContext = useContext(ThemeContext);
  const { sections } = useContext(TenantContext);
  const { emulatingUser, setEmulatingUser } = useContext(AdminContext)

  return (
    <div className="mt-8 sm:mt-0">
      <div className="order-2">
        <h3 className="my-4 text-lg font-medium leading-6 text-gray-900">
          Control Panel
        </h3>
        <div className="text-center">
          <Link
            to="/puzzle"
            className={`mx-2 my-1 block w-44 rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white hover:bg-primary`}
          >
            Save and Exit
          </Link>
        </div>
        <div className="">
          <button
            onClick={restartSection}
            className={`mx-2 my-1 w-44 rounded-md bg-primary-light px-3 py-2 text-sm font-medium text-white hover:bg-primary`}
          >
            Restart Section
          </button>
        </div>
        {emulatingUser && JSON.stringify(emulatingUser) !== '{}' && <div className="text-center">
            <button onClick={() => { if (setEmulatingUser) setEmulatingUser(null); location.reload(); }}
                className={`bg-primary-light hover:bg-primary text-white w-44 px-3 py-2 my-1 mx-2 rounded-md text-sm font-medium`}>
                Stop Emulation
            </button>
        </div>}
      </div>

      <div className="order-1 mb-6">
        <h3 className="mt-8 text-lg font-medium leading-6 text-gray-900 sm:mt-4">
          Other Sections
        </h3>
        <ul className="pt-2">
          {sections &&
            sections?.map((section) => {
              if (section === "advice") {
                return null;
              }
              
              return (
                <li className="inline" key={`${section}_nav`}>
                  <Link to={`/interview/${section}`}>
                    <div
                      style={{ height: "44px" }}
                      className="flex transform cursor-pointer flex-row items-center text-gray-500 transition-transform duration-200 ease-in hover:translate-x-2 hover:text-gray-800"
                    >
                      <span className="inline-flex h-8 w-8 items-center justify-center text-lg text-gray-400">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill={
                            currentSection === section
                              ? themeContext?.primary
                              : "#9ca3af"
                          }
                        >
                          <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
                        </svg>
                      </span>
                      <span className="ml-2 font-medium text-gray-900">
                        {getSectionName(section)}
                      </span>
                    </div>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}

export default memo(SectionNav);
