import { useEffect, useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "../../widgets/Modal";
import StyledBounceLoader from "../../StyledBounceLoader";
import TextField from "../../fields/TextField";
import AdminContext from "../../../context/admin";
import TenantContext from "../../../context/tenant";
import { isAdmin } from "../../../lib/helpers";
import { BounceLoader } from "react-spinners";
import SelectField from "../../fields/SelectField";

const BASE_URL = import.meta.env.VITE_API_DOMAIN;

// TODO: Move types to types file
type CompanyData = {
  "companyName": string;
  "custodian": string;
  "employeeContribution": string;
  "employerMatch": boolean;
  "employerMatchPercent": string;
  "roth": boolean;
  "targetDateManager": string;
  "type": string;
  "fundRecommendations": Object[];
};

type CompanyDataList = {
  [key: string]: CompanyData;
};

export default function Companies() {
  const { id } = useContext(TenantContext) as { id: string };

  const { user, isLoading, getAccessTokenSilently } =
    useAuth0();

  const [loading, setLoading] = useState(true);
  const [posting, setPosting] = useState(false);
  const [errors, setErrors] = useState({});
  const [companyData, setCompanyData] = useState({} as CompanyDataList);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const companyList: {[key: string]: (string)[]} = {
    "1": [
      "Allina",
      "HCMC",
      "Fairview",
      "Childrens Hospital",
      "Essentia Health",
      "Health Partners Park Nicollet",
      "North Memorial",
    ],
    "2": ["HBFF"],
    "3": ["GRE HERC"],
  };

  async function fetchCompanyData(companyName: string) {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(
      BASE_URL + "/api/v2/companies?company=" + companyName,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (response.status != 200) {
      return;
    }

    const data = await response.json();

    setCompanyData((prevCompanyData) => ({
      ...prevCompanyData,
      [companyName]: data,
    }));
    setLoading(false);
  }

  async function postCompanyData(companyName: string) {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(
      BASE_URL + "/api/v2/companies?company=" + companyName,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(companyData[companyName]),
      },
    );

    if (response.status != 200) {
      return;
    }

    setPosting(false);
  }

  function handleChange(event: any, companyName: string) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setCompanyData((prevCompanyData) => ({
      ...prevCompanyData,
      [companyName]: { ...prevCompanyData[companyName], [name]: value },
    }));
  }

  function handleFundChange(event: any, companyName: string) {
    const target = event.target;
    const index = target.getAttribute("index");

    const fundRecommendations: any = companyData[companyName]["fundRecommendations"];
    fundRecommendations[index] = {
      ...fundRecommendations[index],
      [target.name]: target.value,
    };
    setCompanyData((prevCompanyData) => ({
      ...prevCompanyData,
      [companyName]: { ...prevCompanyData[companyName], "fundRecommendations": fundRecommendations },
    }));
  }

  function handleSubmit(event: any, companyName: string) {
    event.preventDefault();
    setPosting(true);
    postCompanyData(companyName);
  }

  useEffect(() => {
    if (!isLoading) {
      companyList[id].forEach((companyName: string) => {
        fetchCompanyData(companyName);
      });
    }
  }, [isLoading]);

  if (!isLoading && !isAdmin(user)) {
    location.href = "/";
  }

  if (loading) {
    return (
      <div className="mt-24 flex items-center justify-center">
        <StyledBounceLoader />
      </div>
    );
  }

  return (
    <div className="-my-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <Modal show={showErrorModal} handleClose={() => setShowErrorModal(false)}>
        <div className="bg-white p-6 pb-12">
          <p className="px-12 pt-4 font-medium text-gray-700">
            Error deleting user
          </p>
        </div>
      </Modal>

      {companyData &&
        Object.keys(companyData).map((companyName: string, i) => {
          return (
            <div
              key={i}
              className="my-4 inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <div className="clear-right overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                <form onSubmit={(e: any) => handleSubmit(e, companyName)}>
                  <div className="grid grid-cols-8 gap-6 bg-white p-6 pb-12">
                    <div className="col-span-8">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Edit {companyName} data
                      </h3>
                      <div className="mt-2 border-t border-gray-200"></div>
                    </div>

                    <div className="col-span-3">
                      <TextField
                        name="Fund Custodian"
                        slug="custodian"
                        value={companyData[companyName]}
                        handleChange={(e: any) => handleChange(e, companyName)}
                        errors={errors}
                      />
                    </div>

                    <div className="col-span-2">
                      <TextField
                        name="Fund Type"
                        slug="type"
                        value={companyData[companyName]}
                        handleChange={(e: any) => handleChange(e, companyName)}
                        errors={errors}
                      />
                    </div>

                    <div className="col-span-2">
                      <TextField
                        name="Target Date Manager"
                        slug="targetDateManager"
                        value={companyData[companyName]}
                        handleChange={(e: any) => handleChange(e, companyName)}
                        errors={errors}
                      />
                    </div>

                    <div className="col-span-8 sm:col-span-1">
                      <SelectField
                        name="Employer Match"
                        slug="employerMatch"
                        value={companyData[companyName]}
                        handleChange={(e: any) => handleChange(e, companyName)}
                        errors={errors}
                        options={[
                          { name: "true", label: "Yes" },
                          { name: "false", label: "No" },
                        ]}
                      />
                    </div>

                    <div className="col-span-2">
                      <TextField
                        name="Employer Match Percent"
                        slug="employerMatchPercent"
                        value={companyData[companyName]}
                        handleChange={(e: any) => handleChange(e, companyName)}
                        errors={errors}
                      />
                    </div>

                    <div className="col-span-2">
                      <TextField
                        name="Employee Contribution"
                        slug="employeeContribution"
                        value={companyData[companyName]}
                        handleChange={(e: any) => handleChange(e, companyName)}
                        errors={errors}
                      />
                    </div>

                    {companyData[companyName] &&
                      "employerMatchPercentUnion" in
                        companyData[companyName] && (
                        <div className="col-span-2">
                          <TextField
                            name="Employer Match Percent Union"
                            slug="employerMatchPercentUnion"
                            value={companyData[companyName]}
                            handleChange={(e: any) =>
                              handleChange(e, companyName)
                            }
                            errors={errors}
                          />
                        </div>
                      )}

                    {companyData[companyName] &&
                      "employeeContributionUnion" in
                        companyData[companyName] && (
                        <div className="col-span-2">
                          <TextField
                            name="Employee Contribution Union"
                            slug="employeeContributionUnion"
                            value={companyData[companyName]}
                            handleChange={(e: any) =>
                              handleChange(e, companyName)
                            }
                            errors={errors}
                          />
                        </div>
                      )}

                    {companyData[companyName] &&
                      "offersNonelectiveContributionNonUnion" in
                        companyData[companyName] && (
                        <div className="col-span-2">
                          <TextField
                            name="Offers Nonelective Contribution Non Union"
                            slug="offersNonelectiveContributionNonUnion"
                            value={companyData[companyName]}
                            handleChange={(e: any) =>
                              handleChange(e, companyName)
                            }
                            errors={errors}
                          />
                        </div>
                      )}

                    {companyData[companyName] &&
                      "offersNonelectiveContributionUnion" in
                        companyData[companyName] && (
                        <div className="col-span-2">
                          <TextField
                            name="Offers Nonelective Contribution Union"
                            slug="offersNonelectiveContributionUnion"
                            value={companyData[companyName]}
                            handleChange={(e: any) =>
                              handleChange(e, companyName)
                            }
                            errors={errors}
                          />
                        </div>
                      )}

                    <div className="col-span-8 pt-4">
                      <h3 className="text-md font-medium leading-6 text-gray-900">
                        Edit Funds
                      </h3>
                      <div className="mt-2 border-t border-gray-200"></div>
                    </div>

                    {companyData[companyName] &&
                      "fundRecommendations" in companyData[companyName] &&
                      Object.values(companyData[companyName]["fundRecommendations"]).map(
                        (fund: any, i) => {
                          return (
                            <div
                              key={`child_${i}`}
                              className="col-span-8 grid grid-cols-8 gap-6"
                            >

                              <div className="col-span-3">
                                <TextField
                                  name="Name"
                                  slug="fundName"
                                  index={i}
                                  value={fund}
                                  handleChange={(e: any) => handleFundChange(e, companyName) }
                                  errors={errors}
                                />
                              </div>
                              <div className="col-span-1">
                                <TextField
                                  name="Ticker"
                                  slug="tickerSymbol"
                                  index={i}
                                  value={fund}
                                  handleChange={(e: any) => handleFundChange(e, companyName) }
                                  errors={errors}
                                />
                              </div>
                              <div className="col-span-1">
                                <TextField
                                  name="Market Cap"
                                  slug="marketCapitalization"
                                  index={i}
                                  value={fund}
                                  handleChange={(e: any) => handleFundChange(e, companyName) }
                                  errors={errors}
                                />
                              </div>
                              <div className="col-span-1">
                                <TextField
                                  name="Category Allocation"
                                  slug="categoryAllocation"
                                  index={i}
                                  value={fund}
                                  handleChange={(e: any) => handleFundChange(e, companyName) }
                                  errors={errors}
                                />
                              </div>
                              <div className="col-span-1">
                                <TextField
                                  name="Income Min"
                                  slug="fixedIncomeMin"
                                  index={i}
                                  value={fund}
                                  handleChange={(e: any) => handleFundChange(e, companyName) }
                                  errors={errors}
                                />
                              </div>
                              <div className="col-span-1">
                                <TextField
                                  name="Income Max"
                                  slug="fixedIncomeMax"
                                  index={i}
                                  value={fund}
                                  handleChange={(e: any) => handleFundChange(e, companyName) }
                                  errors={errors}
                                />
                              </div>

                            </div>
                          );
                        },
                      )}
                  </div>

                  <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      disabled={loading}
                      className={`${
                        loading ? "bg-gray-400" : "bg-primary"
                      } inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm`}
                    >
                      <BounceLoader
                        loading={posting}
                        size={20}
                        color="#ffffff"
                      />
                      <span className={posting ? "ml-2" : ""}>Save</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          );
        })}
    </div>
  );
}
