export function defaultOnErrorRetry(
  error: any,
  key: any,
  config: any,
  revalidate: any,
  { retryCount }: { retryCount: number },
) {
  if (error.status === 500) return;

  // Don't retry until the error modal is better
  if (retryCount > 0) return;

  // Retry after 5 seconds.
  setTimeout(() => revalidate({ retryCount }), 5000);
}
