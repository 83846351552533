import { useEffect, useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import StyledBounceLoader from "../../StyledBounceLoader";
import TextField from "../../fields/TextField";
import { isAdmin } from "../../../lib/helpers";
import { BounceLoader } from "react-spinners";
import SelectField from "../../fields/SelectField";
import TenantContext  from "../../../context/tenant";

const BASE_URL = import.meta.env.VITE_API_DOMAIN;

// TODO: Move types to types file
type LearningVideo = {
  [key: string]: string | any
}

type LearningPdf = {
  [key: string]: string | any;
}

type LearningList = {
  [key: string]: (LearningVideo | LearningPdf)[];
};

export default function Learning() {
  const { user, isLoading, getAccessTokenSilently } = useAuth0();
  const { id, sections } = useContext(TenantContext);

  const [loading, setLoading] = useState(true);
  const [posting, setPosting] = useState(false);
  const [errors, setErrors] = useState({});
  const [learningData, setLearningData] = useState({} as LearningList);
  const [options, setOptions] = useState([] as any);

  async function fetchFilesList() {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(BASE_URL + "/api/v2/files", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Tenant-Id": id || "1",
      },
    });

    if (response.status != 200) {
      return;
    }

    const data: LearningList = await response.json();

    let optionsList: any[] = [];
    if (data["pdfs"]) {
      data["pdfs"].forEach(pdf => {
        optionsList.push({
          name: pdf.path,
          label: pdf.name,
        })
      });
    }
    setOptions(optionsList)

    setLoading(false);
  }

  async function fetchLearningData() {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(BASE_URL + "/api/v2/learning", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Tenant-Id": id || "1",
      },
    });

    if (response.status != 200) {
      return;
    }

    const data = await response.json();

    setLearningData(data);
    setLoading(false);
  }

  async function postLearningData() {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(BASE_URL + "/api/v2/learning", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Tenant-Id": id || "1",
      },
      body: JSON.stringify(learningData),
    });

    setPosting(false);
  }

  function handleChange(event: any, section: string, i: number, type: string = "") {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let updatedSection = learningData[section];

    if (type) {
      // handle types that need to update sub-object values
      let originalSection = learningData[section][i] as any
      updatedSection[i][type] = {...originalSection[type], [name]: value};
    } else {
      updatedSection[i] = {...learningData[section][i], [name]: value}
    }

    setLearningData((prevLearningData) => ({
        ...prevLearningData,
        [section]: updatedSection,
    }));
  }

  function addPdf(section: string) {
    let updatedSection = learningData[section];
    updatedSection.push({
      "name": "",
      "pdf": {
        "file": ""
      }
    });

    setLearningData((prevLearningData) => ({
        ...prevLearningData,
        [section]: updatedSection,
    }));
  }

  function addVideo(section: string) {
    let updatedSection = learningData[section];
    updatedSection.push({
      "name": "",
      "video": ""
    });

    setLearningData((prevLearningData) => ({
        ...prevLearningData,
        [section]: updatedSection,
    }));
  }

  function addLink(section: string) {
    let updatedSection = learningData[section];
    updatedSection.push({
      "name": "",
      "link": ""
    });

    setLearningData((prevLearningData) => ({
        ...prevLearningData,
        [section]: updatedSection,
    }));
  }

  function removeRow(section: string, i: number) {
    let updatedSection = learningData[section];
    updatedSection.splice(i, 1);

    setLearningData((prevLearningData) => ({
        ...prevLearningData,
        [section]: updatedSection,
    }));
  }

  function handleSubmit(event: any, section: string) {
    event.preventDefault();
    setPosting(true);
    postLearningData();
  }

  useEffect(() => {
    if (!isLoading) {
      fetchLearningData();
      fetchFilesList();
    }
  }, [isLoading]);

  if (!isLoading && !isAdmin(user)) {
    location.href = "/";
  }

  if (loading) {
    return (
      <div className="mt-24 flex items-center justify-center">
        <StyledBounceLoader />
      </div>
    );
  }

  function getSectionJsx(section: string) {
    return (<div className="clear-right overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
    <form onSubmit={(e) => handleSubmit(e, section)}>
      <div className="grid grid-cols-8 gap-6 bg-white p-6 pb-12">
        <div className="col-span-8">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Edit {section} items
          </h3>
          <div className="mt-2 border-t border-gray-200"></div>
        </div>

        {learningData[section] &&
          learningData[section].map((item: any, i: number) => {
            return (
              <div
                key={`child_${i}`}
                className={section === "library" ? "col-span-10 grid grid-cols-10 gap-6" : "col-span-9 grid grid-cols-9 gap-6"}
              >
                <div className="col-span-3">
                  <TextField name="Name" slug="name" index={i} value={item} handleChange={(e: any) => handleChange(e, section, i) } errors={errors} />
                </div>
                {section === "library" && (
                  <div className="col-span-2">
                    <SelectField name="Subsection" slug="subsection" value={item} handleChange={(e: any) => handleChange(e, section, i) } errors={errors}
                      options={[
                        { name: "Cash Flow Planning", label: "Cash Flow Planning" },
                        { name: "Educational Planning", label: "Educational Planning" },
                        { name: "Tax Planning", label: "Tax Planning" },
                        { name: "Retirement Planning", label: "Retirement Planning" },
                        { name: "Estate Planning", label: "Estate Planning" },
                      ]} />
                  </div>
                )}
                {section !== "library" && (
                  <div className="col-span-1">
                    <SelectField name="Show in Chat" slug="showInChat" value={item} handleChange={(e: any) => handleChange(e, section, i) } errors={errors}
                      options={[
                        { name: "true", label: "Yes" },
                        { name: "false", label: "No" },
                      ]} />
                  </div>
                )}
                {"link" in item && (
                  <div className="col-span-4">
                    <TextField name="Link" slug="link" index={i} value={item} handleChange={(e: any) => handleChange(e, section, i) } errors={errors} />
                  </div>
                )}
                {"video" in item && (
                  <div className="col-span-4">
                    <TextField name="Video" slug="video" index={i} value={item} handleChange={(e: any) => handleChange(e, section, i) } errors={errors} />
                  </div>
                )}
                {item["pdf"] && (
                  <div className="col-span-3">
                    <SelectField name="PDF" slug="file" index={i} value={item["pdf"]} handleChange={(e: any) => handleChange(e, section, i, "pdf") } options={options} errors={errors} />
                  </div>
                )}
                {item["pdf"] && (
                  <div className="col-span-1">
                    <TextField name="Page" slug="page" index={i} value={item["pdf"]} handleChange={(e: any) => handleChange(e, section, i, "pdf") } errors={errors} />
                  </div>
                )}
                <div className="col-span-1 relative">
                  <button type="button" onClick={() => (removeRow(section, i))}
                    className={`absolute inset-y-6 -mr-9 h-9 w-7 justify-center rounded-md bg-primary-light pl-1 text-white`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="h-5 w-5" >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            );
          })}

        <div className="col-span-12 lg:col-span-3">
          <button type="button" onClick={(e) => addVideo(section)}
            className={`inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5" >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="ml-2">Add video</span>
          </button>
          <button
            type="button"
            onClick={(e) => addPdf(section)}
            className={`inline-flex justify-center rounded-md border border-transparent bg-primary ml-4 px-4 py-2 text-sm font-medium text-white shadow-sm`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5" >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="ml-2">Add PDF</span>
          </button>
          <button
            type="button"
            onClick={(e) => addLink(section)}
            className={`inline-flex justify-center rounded-md border border-transparent bg-primary ml-4 px-4 py-2 text-sm font-medium text-white shadow-sm`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5" >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="ml-2">Add Link</span>
          </button>
        </div>
      </div>

      <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
        <button
          type="submit"
          disabled={loading}
          className={`${
            loading ? "bg-gray-400" : "bg-primary"
          } inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm`}
        >
          <BounceLoader
            loading={posting}
            size={20}
            color="#ffffff"
          />
          <span className={posting ? "ml-2" : ""}>Save</span>
        </button>
      </div>
    </form>
  </div>
  )
  }

  return (
    <div className="-my-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div
        key="library"
        className="my-4 inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
      >
        {getSectionJsx("library")}
      </div>
      {learningData && sections &&
        sections.map((section: string, j) => {
          return (
            <div
              key={j}
              className="my-4 inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              {getSectionJsx(section)}
            </div>
          );
        })}
    </div>
  );
}
