import TextField from "../fields/TextField";
import SelectField from "../fields/SelectField";

function SpouseInvestmentAccountForm({ value, setFormValues, errors }) {
  let rowCount = 10;

  // initialize spouseInvestmentAccounts object if it doesn't exist
  if (!value.spouseInvestmentAccounts) {
    value.spouseInvestmentAccounts = [];

    for (let i = 0; i < rowCount; i++) {
      value.spouseInvestmentAccounts[i] = {
        investmentAccountName: "",
        investmentAccountType: "",
        investmentHeldAt: "",
        investmentBalance: "",
        investmentStockAllocation: "",
        investmentBondAllocation: "",
        investmentContribution: "",
      };
    }
  }

  function handleChange(event) {
    const target = event.target;
    const index = target.getAttribute("index");

    const spouseInvestmentAccounts = value.spouseInvestmentAccounts;
    spouseInvestmentAccounts[index] = {
      ...value.spouseInvestmentAccounts[index],
      [target.name]: target.value,
    };
    setFormValues({
      ...value,
      spouseInvestmentAccounts: [...spouseInvestmentAccounts],
    });
  }

  return (
    <div>
      {[...Array(parseInt(rowCount))].map((x, i) => {
        return (
          <div key={i} className="mt-2 grid grid-cols-11 gap-6">
            <div className="col-span-11 lg:col-span-2">
              <TextField
                name="Account Name"
                slug="investmentAccountName"
                index={i}
                value={value.spouseInvestmentAccounts[i]}
                handleChange={handleChange}
                errors={errors}
              />
            </div>

            <div className="col-span-11 lg:col-span-2">
              <SelectField
                name="Account Type"
                slug="investmentAccountType"
                index={i}
                value={value.spouseInvestmentAccounts[i]}
                handleChange={handleChange}
                errors={errors}
                options={[
                  { name: "Traditional IRA", label: "Traditional IRA" },
                  { name: "Rollover IRA", label: "Rollover IRA" },
                  { name: "Inherited IRA", label: "Inherited IRA" },
                  { name: "Roth IRA", label: "Roth IRA" },
                  {
                    name: "Individual Brokerage",
                    label: "Individual Brokerage",
                  },
                  { name: "Annuity", label: "Annuity" },
                  { name: "Other", label: "Other" },
                ]}
              />
            </div>

            <div className="col-span-11 lg:col-span-2">
              <TextField
                name="Held at"
                slug="investmentHeldAt"
                index={i}
                value={value.spouseInvestmentAccounts[i]}
                handleChange={handleChange}
                errors={errors}
              />
            </div>

            <div className="col-span-11 lg:col-span-1">
              <TextField
                name="Balance"
                slug="investmentBalance"
                index={i}
                value={value.spouseInvestmentAccounts[i]}
                handleChange={handleChange}
                errors={errors}
              />
            </div>

            <div className="col-span-11 lg:col-span-1">
              <TextField
                name="% Stocks"
                slug="investmentStockAllocation"
                index={i}
                value={value.spouseInvestmentAccounts[i]}
                handleChange={handleChange}
                errors={errors}
              />
            </div>

            <div className="col-span-11 lg:col-span-1">
              <TextField
                name="% Bonds"
                slug="investmentBondAllocation"
                index={i}
                value={value.spouseInvestmentAccounts[i]}
                handleChange={handleChange}
                errors={errors}
              />
            </div>

            <div className="col-span-11 lg:col-span-2">
              <TextField
                name="Monthly Contribution"
                slug="investmentContribution"
                index={i}
                value={value.spouseInvestmentAccounts[i]}
                handleChange={handleChange}
                errors={errors}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SpouseInvestmentAccountForm;
