import { useContext, useMemo } from "react";
import TenantContext from "../../context/tenant";
import { useNavigate } from "react-router-dom";
import useChatData from "../../lib/use-chat-data";

export default function Welcome() {
  const { id, groupName, welcomeVideoUrl, sections } =
    useContext(TenantContext);
  const navigate = useNavigate();
  const { chatData } = useChatData();
  const currentSection = useMemo<string>(() => {
    if (!chatData || !sections) {
      return "introduction";
    }

    let current = "";
    sections.forEach(section => {
      if (!current && !chatData[section]?.state?.historyValue?.current.toLowerCase().includes("complete")) {
        current = section;
      }
    })
    return current || "goals";
  }, [chatData, sections]);

  function getWidth() {
    return window.innerWidth > 800 ? 800 : window.innerWidth;
  }

  function getHeight() {
    return window.innerWidth > 800 ? 450 : window.innerWidth * 0.54;
  }

  function getWelcomeDetails() {
    return (
      <div className="w-full text-xl text-gray-500 lg:mr-12">
        <p
          className="ml-6 mt-6 font-semibold uppercase leading-8 tracking-tight text-gray-900"
          style={{ fontSize: "2rem" }}
        >
          Dorval & Chorne Quality of Life Planner
        </p>
        <p className="mx-6 mt-4 max-w-4xl lg:mr-12">
          This application will help you create a virtual planning environment
          designed to help improve your quality of life. We will start with an
          interview process that looks and feels like we are collecting your
          financial puzzle pieces. Once you have completed the interview, our AI
          engine will use your interview answers to generate a customized
          personal dashboard including some priority action items you can take
          today!
        </p>
        <p className="mx-6 mt-4 max-w-4xl lg:mr-12">
          The application is intended to be used in collaboration with a CFP®
          Professional. If you have questions, you can click on the "Ask a
          Question" link and you will be able to email one of our advisors for a
          prompt response. If you need further advice beyond what the app
          provides, we encourage you to use the "Schedule a Meeting" link to set
          up a meeting with one of our CFP® Professionals.
        </p>
        <p className="ml-6 mt-4 max-w-4xl">
          Here is a list of items you will need in order to complete the
          interview:
        </p>
        <ul className="ml-12 list-disc pb-4 text-lg">
          <li className="mt-2">Pay stub(s)</li>
          <li className="mt-2">
            Employer sponsored plan (401k or 403b statement, or be able to login
            online)
          </li>
          {id === "1" && (
            <li className="mt-2">Pension information (MNA “Green sheet”)</li>
          )}
          <li className="mt-2">
            Any debt information including: amounts, interest rates, payments,
            and remaining balances
          </li>
          <li className="mt-2">Any investment statements</li>
          <li className="mt-2">Social Security statement</li>
          <li className="mt-2">Approximate cash flow/ monthly expenses</li>
        </ul>
        <button
          onClick={() => navigate(`/interview/${currentSection}`)}
          className={
            "text-md ml-6 mt-6 inline-flex justify-center rounded-md border border-transparent bg-primary px-8 py-3 font-medium text-white shadow-sm"
          }
        >
          {sections && currentSection !== sections[0]
            ? "Continue Interview"
            : "Start Interview"}
        </button>
      </div>
    );
  }

  return (
    <div className="mb-16">
      <div className="mt-8 sm:flex">
        <div className="hidden lg:block">{getWelcomeDetails()}</div>

        <div>
          <div className="welcome-video overflow-hidden bg-white shadow lg:mt-0 lg:mt-16 lg:rounded-md">
            <iframe
              width={getWidth()}
              height={getHeight()}
              src={welcomeVideoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div>
              <p className="my-8 ml-6 max-w-4xl text-lg text-gray-500">
                We have created this virtual planning environment to help
                improve quality of life specifically for {groupName}!
              </p>
            </div>
          </div>
          <div className="block lg:hidden">{getWelcomeDetails()}</div>
        </div>
      </div>
    </div>
  );
}
