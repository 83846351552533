import { instance } from "./data";
import { useContext } from "react";
import TenantContext from "../context/tenant";
import { useAuth0 } from "@auth0/auth0-react";
import useSWRImmutable from "swr/immutable";
import AdminContext from "../context/admin";

type QueryDataType<T, U> = {
  queryData: { results?: T; messages?: U };
};
export default function useQueryData<T = any, U = any>(
  operationName: string,
  operationCondition: boolean = true,
): QueryDataType<T, U> {
  const { id } = useContext(TenantContext);
  const { emulatingUser } = useContext(AdminContext)
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const fetcher = async ({ url, tenantId, accessTokenFunc }: any) => {
    const accessToken = await accessTokenFunc({
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      scope: "write:profile-data",
    });
    const { data } = await instance.get(url, {
      headers: {
        "X-Tenant-Id": tenantId,
        "X-Emulating-User-Id": emulatingUser && "user_id" in emulatingUser ? emulatingUser.user_id : "",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  };

  const { data } = useSWRImmutable<T>(
    id && isAuthenticated && operationCondition
      ? {
          url: `/api/v2/query?operation=${operationName}`,
          tenantId: id,
          accessTokenFunc: getAccessTokenSilently,
        }
      : null,
    fetcher,
    { revalidateOnFocus: true }
  );

  return {
    queryData: data ?? {},
  };
}
