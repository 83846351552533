import StyledBounceLoader from "./StyledBounceLoader";
import ContributionRecommendations from "./ContributionRecommendations";
import useQueryData from "../lib/use-query-data";
import { useContext } from "react";
import TenantContext from "../context/tenant";

export default function TargetDateRecommendations() {
  const { scheduleMeetingLink } = useContext(TenantContext);
  const {
    queryData: { results: cashFlow },
  } = useQueryData("dc_hierarchy_cash_flow");
  const {
    queryData: { results: fundRecommendations, messages: fundMessages },
  } = useQueryData("dc_fund_recommendations");

  if (!cashFlow || !fundMessages || !fundRecommendations) {
    return (
      <div className="mx-auto my-16 max-w-5xl">
        <div className="flex items-center justify-center">
          <StyledBounceLoader />
        </div>
      </div>
    );
  }

  if (!fundMessages.targetDate) {
    return (
      <div className="mx-auto my-16 max-w-5xl">
        <div className="flex items-center justify-center">
          ERROR: no fund recommendations found
        </div>
      </div>
    );
  }

  return (
    <div className="mx-4 mb-16 sm:mx-16">
      <div className="pt-1 text-gray-900">
        <h3 className="text-lg font-medium leading-6">Fund Recommendations</h3>
        <p className="mt-1 pb-4  text-sm font-semibold text-gray-700">
          Based on your interview responses, we recommend the
          "set-it-and-forget-it" approach listed below
        </p>
        <div className="border-t border-gray-200"></div>
      </div>

      <div className="hover:shodow-lg mt-8 flex items-center justify-between rounded-2xl bg-gray-100 px-8 py-4 shadow-md">
        <div className="flex items-center">
          <div className="w-16">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 rounded-2xl border border-blue-100 bg-blue-50 p-3 text-blue-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <div className="ml-4 flex flex-col">
            <div className="font-medium leading-none">
              {fundMessages.targetDate}
            </div>
            <p className="mt-2 text-sm leading-none text-gray-600">
              The simplified, set-it-and-forget-it target date portfolio is the
              most appropriate for your situation.
            </p>
          </div>
        </div>
      </div>

      <div className="ml-4 mt-12 pt-1 text-gray-900">
        <h3 className="text-lg font-medium leading-6">
          Our AI powered portfolio recommendation engine has determined a target
          date fund is right for you.
        </h3>
        <p className="mt-1 pb-4  text-sm font-semibold text-gray-700">
          Our recommendation process suggests the {fundMessages.targetDate}{" "}
          fund.
        </p>
      </div>

      <div className="ml-4 mt-4">
        <table className="w-1/2">
          <thead>
            <tr className="border-b border-gray-600 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wide text-gray-900">
              <th className="px-4 py-3">Target Date Fund</th>
              <th className="px-4 py-3">Investment Phase</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            <tr className="text-gray-700">
              <td className="text-ms border px-4 py-3 font-semibold">
                {fundMessages.targetDate}
              </td>
              <td className="border px-4 py-3 text-sm">
                {fundMessages.investingPhase}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ContributionRecommendations />

      <div className="hover:shodow-lg mb-12 mt-16 flex items-center justify-between rounded-2xl bg-gray-100 px-8 py-4 shadow-md">
        <div className="flex items-center">
          <div className="w-16">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 rounded-2xl border border-blue-100 bg-blue-50 p-3 text-blue-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="ml-4 flex flex-col">
            <div className="font-medium leading-none">Schedule a Meeting</div>
            <p className="mt-2 text-sm leading-none text-gray-600">
              You can{" "}
              <a
                href={scheduleMeetingLink}
                target="_blank"
                className="underline hover:no-underline"
              >
                click here
              </a>{" "}
              to schedule a meeting to speak with one of our Certified Financial
              Planners™.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
