const formValidations: { [key: string]: { [key: string]: any } } = {
  debtsCreditCard: {
    creditCardName: "optional|text",
    creditCardBalance: "optional|numeric|between:0-1000000000",
    creditCardInterestRate: "optional|numeric|between:0-100",
    creditCardPayment: "optional|numeric|between:0-1000000000",
  },
  debtsAutoLoans: {
    autoLoanName: "optional|text",
    autoLoanBalance: "optional|numeric|between:0-1000000000",
    autoLoanInterestRate: "optional|numeric|between:0-100",
    autoLoanPayment: "optional|numeric|between:0-1000000000",
  },
  debtsStudentLoan: {
    studentLoanName: "optional|text",
    studentLoanBalance: "optional|numeric|between:0-1000000000",
    studentLoanInterestRate: "optional|numeric|between:0-100",
    studentLoanPayment: "optional|numeric|between:0-1000000000",
  },
  debtsOtherLoan: {
    otherLoanName: "optional|text",
    otherLoanBalance: "optional|numeric|between:0-1000000000",
    otherLoanInterestRate: "optional|numeric|between:0-100",
    otherLoanPayment: "optional|numeric|between:0-1000000000",
  },
  investmentAccounts: {
    investmentAccountName: "optional|text",
    investmentHeldAt: "optional|text",
    investmentBalance: "optional|numeric|between:0-1000000000",
    investmentStockAllocation: "optional|numeric|between:0-100",
    investmentBondAllocation: "optional|numeric|between:0-100",
    investmentContribution: "optional|numeric|between:0-1000000000",
  },
  majorExpenses: {
    majorExpenseDescription: "optional|text",
    majorExpenseAmount: "optional|numeric|between:0-1000000000",
  },
  pensionPlans: {
    pensionPlanName: "optional|text",
    pensionPlanDistributionAmount: "optional|numeric|between:0-1000000000",
    pensionPlanDistributionYear: "optional|numeric|between:1990-2100",
  },
};

export default formValidations;
