import { useContext, useState } from "react";
import { WebAuth } from "auth0-js";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Form,
  Field,
  Formik,
  ErrorMessage,
  FormikConsumer,
  useFormikContext,
} from "formik";
import * as Yup from "yup";

import TextField from "../fields/TextField";
import TenantContext from "../../context/tenant";
import { ObjectSchema, ref } from "yup";
import { BounceLoader } from "react-spinners";

function Main() {
  const { id, name, groupName, employeeName, logo } = useContext(TenantContext);
  const [state, setState] = useState<{
    registrationCode: string;
  }>({ registrationCode: "" });
  const [isRegistered, setIsRegistered] = useState(false);
  const { loginWithRedirect } = useAuth0();

  let webAuth = new WebAuth({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  });

  function handleRegister(event: any) {
    event.preventDefault();

    //TODO: Use API to confirm registration code, make it tennant specific
    if (state.registrationCode) {
      if (
        (id === "1" && state.registrationCode.toLowerCase() === "$mnarn$") ||
        (id === "2" && state.registrationCode.toLowerCase() === "$hbff$") ||
        (id === "3" && state.registrationCode.toLowerCase() === "$greherc$")
      ) {
        setIsRegistered(true);
      }
    } else {
      alert("Invalid registration code");
    }
  }

  function handleSubmit(values: SignupFormValues) {
    webAuth.signup(
      {
        connection: "Username-Password-Authentication",
        email: values.emailAddress,
        password: values.password,
        username: values.emailAddress,
      },
      function (err: any) {
        //TODO: make error message a modal
        if (err) return alert("ERROR: " + err.description);

        loginWithRedirect();
      },
    );
  }

  function setValue(name: string, value: string) {
    setState({
      ...state,
      [name]: value,
    });
  }

  function handleChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setValue(name, value);
  }

  if (!isRegistered) {
    return (
      <div>
        <div className="mx-auto max-w-7xl">
          <img
            className="mx-auto"
            src="/images/dorval-chrone.png"
            alt="Dorval Chrone"
          ></img>

          <form onSubmit={handleRegister}>
            <div className="mx-4 mt-8 overflow-hidden shadow sm:mx-auto sm:w-1/2 sm:rounded-md">
              <div className="bg-white p-6">
                <h3 className="mb-4 text-center text-lg font-medium uppercase leading-6 text-gray-900">
                  Hello {groupName}
                </h3>
                <img
                  className="mx-auto px-20"
                  src={logo?.url}
                  alt={logo?.altText}
                />

                <h3 className="mt-8 text-lg font-medium leading-6 text-gray-900">
                  Enter your registration code
                </h3>
                <p className="mt-1 pb-4 text-sm text-gray-600">
                  This system is intended for {groupName}, and requires a
                  registration code to sign up.
                </p>
                <div className="mb-2 border-t border-gray-200"></div>
                <TextField
                  name="Registration Code"
                  slug="registrationCode"
                  caption={`*acquired from ${name}`}
                  value={state}
                  handleChange={handleChange}
                />
              </div>

              <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className={`inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm`}
                >
                  Register
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="mx-auto max-w-7xl">
        <img
          className="mx-auto"
          src="/images/dorval-chrone.png"
          alt="Dorval Chrone"
        ></img>

        <div className="mx-4 mt-8 overflow-hidden shadow sm:mx-auto sm:w-1/2 sm:rounded-md">
          <div className="bg-white p-6">
            <h3 className="mb-4 text-center text-lg font-medium uppercase leading-6 text-gray-900">
              In service of
            </h3>
            <img
              className="mx-auto px-20"
              src={logo?.url}
              alt={logo?.altText}
            />
          </div>
        </div>

        <div className="hover:shodow-lg mx-auto mt-12 block flex max-w-5xl items-center justify-between rounded-2xl bg-gray-100 px-8 py-4 shadow-md sm:hidden">
          <div className="flex max-w-4xl items-center">
            <div className="w-16">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-16 w-16 rounded-2xl border border-blue-100 bg-blue-50 p-3 text-blue-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </div>
            <div className="ml-4 flex flex-col">
              <div className="font-medium leading-none">
                Optimal Device Warning
              </div>
              <p className="mt-2 text-sm leading-none text-gray-600">
                We look forward to serving you through the Quality of Life
                Planning Application. While the app can be used on a mobile
                device, we strongly recommend using a personal/laptop computer
                or tablet device for best user experience.
              </p>
            </div>
          </div>
        </div>

        <div className="mx-4 sm:mx-0 sm:text-center">
          <p className="mt-20 text-xl font-bold uppercase leading-8 tracking-tight text-gray-900 sm:text-4xl">
            A better way to plan your retirement
          </p>
          <p className="mt-4 max-w-4xl text-xl text-gray-500 lg:mx-auto">
            We have created this virtual planning environment to help improve
            quality of life for {groupName}. Our AI driven approach means your
            experience is customized for you! The first step is registering as
            an {employeeName} to use the site. Let's get started!
          </p>
        </div>

        <div className="px-8 py-20 sm:mt-0 sm:px-40">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Get Started Now
                </h3>
                <p className="mt-2 text-sm text-gray-600">
                  Create a User ID now to get started customizing your
                  personalized retirement plan. You'll be able to return any
                  time and pickup where you left off.
                </p>
                <p className="mt-2 text-sm text-gray-600">
                  This system is intended for {groupName} and requires a
                  registration code to create an account.
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="overflow-hidden shadow sm:rounded-md">
                <SignupForm handleSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type SignupFormValues = {
  emailAddress: string;
  password: string;
  confirmPassword: string;
};

const SignupForm = ({ handleSubmit }: any) => {
  const initialValues: SignupFormValues = {
    emailAddress: "",
    password: "",
    confirmPassword: "",
  };
  const validationSchema: ObjectSchema<SignupFormValues> = Yup.object().shape({
    emailAddress: Yup.string().email().required(),
    password: Yup.string()
      .required()
      .min(8)
      .matches(/[0-9]/, "password must contain a number")
      .matches(/[a-z]/, "password must contain a lower case character")
      .matches(/[A-Z]/, "password must contain an uppercase character"),
    confirmPassword: Yup.string()
      .required()
      .test(
        "passwords match",
        "confirmPassword does not match password",
        (value, context) => {
          return context.parent.password === value;
        },
      ),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      <Form>
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className="bg-white px-4 py-5 sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Create Your Account
                </h3>
                <p className="mt-1 pb-4 text-sm text-gray-600">
                  If you already have an account please login using the button
                  on the right side of the top nav.
                </p>
                <div className="border-t border-gray-200"></div>
              </div>
              <Field
                label="Email Address"
                id="emailAddress"
                name="emailAddress"
                component={FormTextInput}
              />
              <Field
                label="Password"
                id="password"
                name="password"
                type="password"
                component={FormTextInput}
              />
              <Field
                label="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                component={FormTextInput}
              />
            </div>
          </div>
          <SubmittableButton text={"Register"} />
        </div>
      </Form>
    </Formik>
  );
};

const FormTextInput = ({
  label,
  field, // { name, value, onChange, onBlur }
  ...props
}: any) => {
  return (
    <div className="col-span-6 sm:col-span-4">
      <label
        htmlFor={field.name}
        className={"block text-sm font-medium leading-6 text-gray-900"}
      >
        {label}
      </label>
      <div className={"group relative mt-2"}>
        <input
          {...field}
          type={props.type || "text"}
          className={
            "block w-full rounded-md border-0 px-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          }
        />
      </div>

      <ErrorMessage
        name={field.name}
        component="div"
        className="text-xs text-red-500"
      />
    </div>
  );
};

const SubmittableButton = ({ text }: { text: string }) => {
  const { isSubmitting, isValid } = useFormikContext();
  return (
    <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
      <button
        type="submit"
        disabled={!isValid}
        className={`${
          isSubmitting || !isValid ? "bg-gray-400" : "bg-primary"
        } inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm`}
      >
        <BounceLoader loading={isSubmitting} size={20} color="#ffffff" />
        <span className={isSubmitting ? "ml-2" : ""}>{text}</span>
      </button>
    </div>
  );
};

export const Debug = () => (
  <div
    style={{
      margin: "3rem 1rem",
      borderRadius: 4,
      background: "#f6f8fa",
      boxShadow: "0 0 1px  #eee inset",
    }}
  >
    <div
      style={{
        textTransform: "uppercase",
        fontSize: 11,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        fontWeight: 500,
        padding: ".5rem",
        background: "#555",
        color: "#fff",
        letterSpacing: "1px",
      }}
    >
      Formik State
    </div>
    <FormikConsumer>
      {({ validationSchema, validate, ...rest }) => (
        <pre
          style={{
            fontSize: ".85rem",
            padding: ".25rem .5rem",
            overflowX: "scroll",
          }}
        >
          {JSON.stringify(rest, null, 2)}
        </pre>
      )}
    </FormikConsumer>
  </div>
);

export default Main;
