import React, { memo, useState } from "react";
import { useParams } from "react-router-dom";

import Chat from "../Chat";
import SectionNav from "../navs/SectionNav";
import ChatModals from "../ChatModals";
import ChatMediaList from "../ChatMediaList";
import StyledBounceLoader from "../StyledBounceLoader";
import useChatData from "../../lib/use-chat-data";

function Interview() {
  const { section } = useParams();
  const [errors, setErrors] = useState({});
  const [currentVideo, setCurrentVideo] = useState("");
  const [currentPdf, setCurrentPdf] = useState({});
  const { chatData, triggerUpdateChatData } = useChatData();

  //TODO: rename to showFormModals
  const [showChatModals, setShowChatModals] = useState<{ [key: string]: any }>({
    debtsCreditCard: false,
    debtsAutoLoans: false,
    debtsStudentLoan: false,
    debtsOtherLoan: false,
    investmentAccounts: false,
    spouseInvestmentAccounts: false,
    pensionPlans: false,
    majorExpenses: false,
    retirementGoals: false,
    helpModal: false,
    mediaModal: false,
    pdfModal: false,
  });

  async function restartSections(sections: string[], withReload = true) {
    sections.forEach((section: string) => {
      delete chatData[section];
    });

    await triggerUpdateChatData(chatData, { revalidate: false });

    if (withReload) {
      location.reload();
    }
  }

  function showModal(event: any, media: any | null = null) {
    let target = event.target;
    let modal = target.getAttribute("modal");
    let video = target.getAttribute("video");
    let pdf = { file: target.getAttribute("pdf"), page: 1 };

    if (media && media.video) {
      video = media.video;
      modal = "mediaModal";
    } else if (media && media.pdf) {
      pdf = media.pdf;
      modal = "pdfModal";
    } else if (media && media.link) {
      // TODO: should we make opening in a new tab determined by a setting in the media item?
      // window.location.href = media.link;
      window.open(media.link, '_blank')?.focus();
    }

    if (video) {
      setCurrentVideo(video);
    } else if (pdf) {
      setCurrentPdf(pdf);
    }

    setShowChatModals({ ...showChatModals, [modal]: true });
  }

  function hideModals() {
    Object.keys(showChatModals).forEach((modal) => {
      showChatModals[modal] = false;
    });
    setShowChatModals({ ...showChatModals });

    setCurrentVideo("");
  }

  return (
    <div className="block w-full sm:flex">
      <ChatModals
        showChatModals={showChatModals}
        hideModals={hideModals}
        currentVideo={currentVideo}
        currentPdf={currentPdf}
        errors={errors}
        setErrors={setErrors}
      />

      {section && chatData ? (
        <Chat
          chatData={chatData}
          section={section || "introduction"}
          errors={errors}
          setErrors={setErrors}
          showModal={showModal}
          restartSections={restartSections}
        />
      ) : (
        <div className="block sm:order-2 sm:w-2/3">
          <div className="mt-24 flex items-center justify-center">
            <StyledBounceLoader />
          </div>
        </div>
      )}

      <div className="px-8 sm:order-3 sm:w-64">
        {section && (
          <ChatMediaList
            sectionName={section || "introduction"}
            showModal={showModal}
          />
        )}
      </div>

      <div className="px-8 sm:order-1">
        {section && (
          <SectionNav
            currentSection={section || "introduction"}
            restartSection={async () =>
              section ? await restartSections([section]) : null
            }
          />
        )}
      </div>
    </div>
  );
}

export default memo(Interview);
